export default {
    name: 'qrVideo',
    title: '小视频',
    iconSize:43,
    qrList: [{
        icon: 'video-o',
        filePath:'',
        text: '第一单元',
        label: '视频教材，难点解说',
        qrTitle: '',
        qrScene: ''
    }]
}