<template>
  <div>
  
    <el-form
      label-position="right"
      label-width="80px"
    >


      <el-form-item label="名称">
        <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
      </el-form-item>
      <el-form-item label="提示">
        <el-input v-model="currentPlugin.desc" placeholder="请输入组件提示"  clearable/>
      </el-form-item>


      <el-divider>可选状态设置</el-divider>
   
      <el-form-item label="切换选项">
        <div class="set-options">
          
          <el-row style="background-color: #f5f5f5;padding:0 5px;">
            <el-col :span="2">-</el-col>
            <el-col :span="8">状态</el-col>
            <el-col :span="11">描述</el-col>
            <el-col :span="3"></el-col>
          </el-row>

          <el-row :gutter="10" v-for="(item, idx) in currentPlugin.groups" :key="idx">
            <el-col :span="2"><el-radio v-model="currentPlugin.statusIndex" :label="idx" title="默认状态" @change="onChangeStatus()"><span></span></el-radio></el-col>
            <el-col :span="8">
              <el-select v-model="item.status" placeholder="选择状态" size="small"  @change="onChangeStatus()">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="11"><el-input v-model="item.label" size="small" placeholder="请输入选项" :disabled="item.label=='其他'" @blur="(e)=>{onBlurCheck(e.target.value,idx)}"/></el-col>
            <el-col :span="3">
              <el-popconfirm title="确认删除选项吗？"
                confirm-button-text="确认"
                cancel-button-text="取消"
                @confirm="onRemoveItem(idx)"
              >
                <template #reference>
                  <el-button type="default" icon="el-icon-close" size="small" circle />
                </template>
              </el-popconfirm>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="mt-10">
            <el-col :span="12"><el-button icon="el-icon-plus" @click="onAddItem">添加选项</el-button></el-col>
            <el-col :span="12">
              <el-tooltip
                class="box-item"
                effect="dark"
                content="由填表人填写"
              >
                <el-button icon="el-icon-plus" @click="onAddItemOther">添加其他项</el-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </div>
        
      </el-form-item>
 
    
      <el-divider>其他</el-divider>

      <el-form-item label="提示大小">
        <el-input-number size="small" v-model="currentPlugin.tips.fontSize" :min="12" :max="60"></el-input-number> PX
      </el-form-item>

      <template v-if="$store.state.formSettings.multipleForm">
        <el-form-item label="所属表单">
          <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
        </el-form-item>
      </template>


       <!-- <el-divider>操作</el-divider>

       <div class="text-center">
          <el-button type="primary" size="small" icon="el-icon-document-copy">复制组件</el-button>
          <el-button type="danger" size="small" icon="el-icon-delete">删除</el-button>
       </div> -->

    </el-form>

    <!-- <el-divider>提示</el-divider>

    <div class="help mt-10">
      号码隐私保护：暂不支持，原因：以前防抄号有一定作用，现手机号码泄露途径太多，号码保护反而让一部分车主更加恶意停车
    </div> -->


  </div>

</template>
<script>
import { mapGetters } from 'vuex'
// import draggable from 'vuedraggable';
// import ImageUploadDialog from '../../ImageUploadDialog'
// import pluginSchema from '../../../pluginSchema/index'
import _ from 'lodash'


export default {
  name: 'formStatus',
  props: {},
  components: {
    // draggable,
    // ImageUploadDialog
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
      statusOptions: [{
          label:'正常',
          value:'normal',
        }, {
          label:'异常',
          value:'abnormal',
        }, {
          value: 'none',
          label: '无'
        }],
    }
  },
  methods:{
    onChangeStatus(){
      // console.log('item',item)
      let item = this.currentPlugin.groups[this.currentPlugin.statusIndex]
      this.currentPlugin.working.status = item.status
      this.currentPlugin.working.label = item.label
      if(item.label!='其他'){
        this.currentPlugin.working.other = ''
      }
      
    },
    onAddItem(){
      let groups = this.currentPlugin.groups
      let length = this.currentPlugin.groups.length
      // console.log('length',length)
      groups.push({
          label:'选项' + (length +1),
          status:'normal',
          // checked:length ==0
      })
      groups = this.uniqByFun(groups); // 去重
      this.currentPlugin.groups = groups
    },
    onAddItemOther(){
      let groups = this.currentPlugin.groups
      groups.push({
          label:'其他',
          status:'none',
          // checked:false
      })
      groups = this.uniqByFun(groups); // 去重
      this.currentPlugin.groups = groups
    },
    uniqByFun(groups){
      return _.uniqBy(groups, function(item){ return item.label + item.status}); // 去重
    },
    // 选中值必须在可选项目中
    checkRemoveValue(){
         let _items = this.currentPlugin.groups.map((item)=>item.label)
        let _value = this.currentPlugin.value
        // 找出已经删除的
        if(!_.includes(_items, _value)){
          // 移除不存在的项目
          this.currentPlugin.value = ""
        }
    },
    onRemoveItem(index){
      const _label = this.currentPlugin.groups[index].label
      this.currentPlugin.groups.splice(index,1)

      let length = this.currentPlugin.groups.length
      if(length==0){
        this.onAddItem()
      }
      // 删除检测
      this.checkRemoveValue()
      this.onChangeStatus();
      // console.log('this.currentPlugin.values',this.currentPlugin.values)
    },
    // 检查空输入和重复
    onBlurCheck(value,index){
      // 删除空项目
      if(value==""){
        this.currentPlugin.groups.splice(index,1)
        // 删除检测
        this.checkRemoveValue()
      }else{
        // 去重
        let items = this.currentPlugin.groups
        items = this.uniqByFun(items); // 去重
        this.currentPlugin.groups = items
      }

      this.onChangeStatus();
    }
   
  }
}

</script>


<style >
.pop-province .el-button{
  margin:2px 0;
}
</style>
