export default {
    name: 'kefu',
    title: '客服',
    isWechat: false,
    isTel: true,
    wechat: [{
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/kefu.png',
        alt: '',
        filePath:'',
    }],
    cells: [{
        text: '售前电话',
        tel: '15807630051',
        label: ''
    },
    {
        text: '售后电话',
        tel: '15807630051',
        label: '使用、安装、配置等问题'
    }]
}