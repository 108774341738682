<template>
  <div>
    <div style="display:flex;" v-if="schema.type == 'sidebar'">
      <div style="border-right: 1px solid #fecaca;">
        <van-sidebar v-model="activeKey" style="width: 90px;">
          <van-sidebar-item v-for="(item, groupIdx) in schema.groups" :key="groupIdx" :title="item.text" />
        </van-sidebar>
      </div>
      <div style="width: 100%;">
        <van-cell-group>
          <van-cell v-for="(item, idx) in grids" :key="idx" :title="item.text" :label="item.label" is-link>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div v-if="schema.type == 'accordion'">
  
      <van-collapse v-model="schema.accordionActive" name="0" accordion>
        <van-collapse-item v-for="(item, groupIdx) in schema.groups" :key="groupIdx" :title="item.text" :name="groupIdx + ''">
          <van-cell-group>
            <van-cell v-for="(item, idx) in item?.grids" :key="idx" :title="item.text" :label="item.label" is-link>
            </van-cell>
          </van-cell-group>

        </van-collapse-item>
      </van-collapse>
    </div>

  </div>
</template>

<script>
export default {
  name: 'collapse',
  props: {
    schema: {
      'type': Object,
      default: {},
      required: true
    },
    pluginIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeKey: 0,
      // accordionActive: '0'
    };
  },
  computed: {
    grids: function () {
      return this.schema.groups[this.activeKey].grids;
    }
  },
  methods: {

  }
}
</script>

<style></style>