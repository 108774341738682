export default{
    name: 'field',
    title: '文本框',
    formName:'default',
    uniqid:'',
    label:'姓名',
    text: '',
    type:'text',
    readonly: false,
    disabled: false,
    required: false,
    isScan:false,
    isWxPhone:false,
    scanColor:'#1989fa',
    isSubstr:false,
    subStart:1,
    subEnd:0
}