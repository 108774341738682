<template>
  <van-cell-group v-if="schema.type=='textarea'">
    <van-field v-model="schema.text" rows="3" autosize show-word-limit maxlength="255" :label="schema.label" :placeholder="'请输入'+schema.label" :type="schema.type"  :required="schema.required" :readonly="schema.readonly" :disabled="schema.disabled"/>
  </van-cell-group>
  <van-cell-group v-else>
    <van-field v-model="schema.text" :label="schema.label" :placeholder="'请输入'+schema.label" :type="schema.type"  :required="schema.required" :readonly="schema.readonly" :disabled="schema.disabled" @click-right-icon="onScan">
      <template v-if="schema.type=='tel' && schema.isWxPhone" #button>
          <van-button size="small" type="primary" @click="onGetWxPhone">微信手机号</van-button>
      </template>
      <template v-else-if="schema.isScan" #right-icon>
        <van-icon name="scan" :color="schema.scanColor" size="22"/>
      </template>

    </van-field>
  </van-cell-group>
</template>

<script>
import { Notify } from 'vant';

export default {
  name: 'field',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    onScan(){
      Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    },
    onGetWxPhone(){
      Notify({ type: 'warning', message: '点击获取微信手机号',duration: 800 });
    }
  }
}
</script>

<style>

</style>