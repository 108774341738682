export default {
    name: 'button',
    title: '跳转按钮',
    text: '跳转按钮',
    textAlign: 'center',
    color: '#1989fa',
    bgColor: '#D6E8F9',
    paddingTB: 10,
    paddingLR: 10,
    plain:true,
    square:'round',// square or round
    icon:'home-o',
    size:'large',
    handle: 'none',
    message: '',
    link: '',
    path: '',//小程序页面
    appId: '' //小程序appId
}