<template>
<div :style="'padding:'+schema.paddingTB+'px '+schema.paddingLR+'px;'" class="rich-text">
  <div v-html="schema.text"></div>
</div>
</template>

<script>
export default {
  name: 'richText',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>
.rich-text p{
  margin:0;
}
.rich-text ol,.rich-text ul {
  padding: revert;
}
</style>