<template>
  <el-dialog title="选择图标" :visible="visible" width="95%" top="20%" @close="onCancel">
    <div class="icon-list">
      <van-icon v-for="(icon,index) in vantIcons" :key="'icon-'+index" :name="icon" size="25" :class="'left-home-white'+ (icon==selectIcon?' active':'')" @click="onSelect(icon)"/>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </span>
    
  </el-dialog>
</template>
<script>
import vantIcons from "../assets/vant-icons"
export default {
  name: 'IconsDialog',
  model: {
    prop: 'iconName',
    event: 'change'
  },
  props: {
    visible: {
      type:Boolean,
      default:false
    },
    iconName:{
      type:String,
      default:"wap-home-o"
    }
  },
  data:function(){
    return {
      vantIcons,
      selectIcon: this.iconName
    }
  },
  methods:{
    onCancel(){
      // this.visible = false
      this.$emit('update:visible', false)
    },
    onConfirm(){
      // this.visible = false
      this.$emit('update:visible', false)
      this.$emit('change', this.selectIcon)
    },
    onSelect(icon){
      this.selectIcon = icon
      
    }
  }
}
</script>

<style>
.icon-list{
  max-width: 400px;
  margin: 0 auto;
}
.icon-list .van-icon{
  margin:5px;
}
  .left-home-white{background-color:#ffffff95; color:#000000 !important; padding:5px;border-radius:50px;border:1px solid #acabab;}
  .icon-list .active{
    border:1px solid #409EFF;
  }
</style>
