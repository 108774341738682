<template>
  <div>
    <el-form label-position="right" label-width="90px">
      <el-form-item label="标题">
        <el-input v-model="currentPlugin.text" placeholder="请输入NavBar标题" clearable />
      </el-form-item>

      <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.fixed" label="固定顶部" v-if="currentPluginIndex == 0" />
        <el-checkbox v-model="currentPlugin.transparent" label="半透明" />
        <el-checkbox v-model="currentPlugin.showLeft" label="左边按钮" />
      </el-form-item>

    
      <el-form-item v-if="currentPlugin.fixed && currentPluginIndex == 0" label="垫高顶部">
        <el-checkbox v-model="currentPlugin.placeholder" label="垫片占位" />
      </el-form-item>

      <el-form-item label="字体色">
        <el-color-picker v-model="currentPlugin.color" color-format="hex" size="small"></el-color-picker>
      </el-form-item>
      <el-form-item label="背景色">
        <el-color-picker v-model="currentPlugin.bgColor" color-format="hex" size="small"></el-color-picker>
      </el-form-item>


      <div v-if="currentPlugin.showLeft">

        <el-divider>按钮</el-divider>

        <el-form-item label="按钮图标">
          <el-button size="mini" style="margin-left:10px;" @click="visible = true"> <van-icon
              :name="currentPlugin.leftIcon || 'wap-home-o'" size="20" /> 选择</el-button>
        </el-form-item>
        <el-form-item label="点击图标">

          <el-button size="mini" style="margin-left:10px;" @click="onHandleSetting"
            icon="el-icon-s-tools">设置动作</el-button>

          <!-- <el-radio-group v-model="currentPlugin.handle" size="small">
            <el-radio-button label="none">默认返回</el-radio-button>
            <el-radio-button label="link">跳转</el-radio-button>
            <el-radio-button label="message">消息</el-radio-button>
          </el-radio-group> -->
        </el-form-item>

        <!-- <div class="set-options" v-if="currentPlugin.handle == 'link' || currentPlugin.handle == 'message'">
          
          <el-form-item label="弹出消息" v-if="currentPlugin.handle == 'message'">
            <el-input v-model="currentPlugin.message" placeholder="如：请联系客服制作同款页面" />
          </el-form-item>
       
          <template v-if="currentPlugin.handle == 'link'">
            <p class="help">H5打开：跳转网址</p>
            <el-form-item label="网址">
              <el-input v-model="currentPlugin.link" placeholder="如：https://www.2weima.com">
                <el-button slot="append" @click="onActionLink">选择</el-button>
              </el-input>
            </el-form-item>
            <p class="help">小程序打开：跳转页面，支持第三方小程序</p>
            <el-form-item label="小程序页面">
              <el-input v-model="currentPlugin.path" placeholder="如：pages/index/index?foo=bar">
                <el-button slot="append" @click="onActionLink">选择</el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="小程序appId">
              <el-input v-model="currentPlugin.appId" placeholder="如：wx81bdf0b17a44370d" />
            </el-form-item>
          </template>

</div> -->

      </div>

      <el-divider>提示</el-divider>

      <div class="help mt-10">
        透明顶部：标题栏全透视效果更好<br />
        固定顶部：不跟随屏幕滚动，会保持在顶部<br />
        参考 <el-link type="primary" href="https://www.2weima.com/article/read.html?scene=R6666L-46666L-59AB1F"
          target="_blank">效果图</el-link> ，看看固顶+透明效果如何
      </div>




    </el-form>

    <icons-dialog v-model="currentPlugin.leftIcon" :visible.sync="visible" />
    <!-- 动作设置 -->
    <handle-setting-dialog v-if="visibleHandle" :visible.sync="visibleHandle" :schema="currentPlugin" source="diy"
      :showAll="true" @confirm="onConfirmHandle" />
    <!-- 链接选择 -->
    <!-- <action-link-dialog :visible.sync="visibleLink" source="diy" :showAll="true" @confirm="onConfirmLink" /> -->
  </div>

</template>
<script>
import { mapGetters, mapState } from 'vuex'
import IconsDialog from '../../IconsDialog'
// import ActionLinkDialog from '../../ActionLinkDialog'
import HandleSettingDialog from '../../HandleSettingDialog'
import _ from 'lodash'

export default {
  name: 'navBar',
  props: {},
  components: {
    // ActionLinkDialog,
    HandleSettingDialog,
    IconsDialog
  },
  computed: {
    ...mapState(['currentPluginIndex']),
    ...mapGetters(['currentPlugin'])
  },
  data: function () {
    return {
      visibleHandle: false,
      // visibleLink: false,
      visible: false
    }
  },
  methods: {
    onHandleSetting() {
      // console.log('onUploadImage')
      console.log('currentPlugin', this.currentPlugin)
      this.visibleHandle = true;
    },
    onConfirmHandle(payload) {
      console.log('onConfirmHandle payload,', payload)
      // this.currentPlugin = { ...payload }

      this.currentPlugin.handle = payload.handle;
      this.currentPlugin.link = payload.link;
      this.currentPlugin.path = payload.path;
      this.currentPlugin.appId = payload.appId;
      this.currentPlugin.jumpType = payload.jumpType;
      this.currentPlugin.shortLink = payload.shortLink;

      if (!_.isEmpty(payload.message)) {
        this.currentPlugin.message = payload.message;
      }
      if (!_.isEmpty(payload.handle_call)) {
        this.currentPlugin.handle_call = payload.handle_call;
      }
      if (!_.isEmpty(payload.handle_map)) {
        this.currentPlugin.handle_map = payload.handle_map;
      }
      if (!_.isEmpty(payload.handle_copy)) {
        this.currentPlugin.handle_copy = payload.handle_copy;
      }
      if (!_.isEmpty(payload.handle_wifi)) {
        this.currentPlugin.handle_wifi = payload.handle_wifi;
      }
    },
    // onActionLink() {
    //   // console.log('onUploadImage')
    //   this.visibleLink = true;
    // },
    // onConfirmLink(linkData) {
    //   // console.log('linkData',linkData)
    //   this.currentPlugin.link = linkData.qrview;
    //   this.currentPlugin.path = linkData.weapp_path;
    //   this.currentPlugin.appId = linkData.weapp_id;
    // },

  }
}

</script>


<style scoped></style>
