export default {
    name: 'rate',
    title: '评分',
    formName:'default',
    uniqid:'',
    label:'满意度',
    icon:'star',//like
    disabled:false,
    readonly:false,
    required: false,
    allowHalf:true,
    count:5,
    color:'#ee0a24',
    voidColor:'#c8c9cc',
    value:4.5
}