<template>
<div>
  <van-cell-group v-if="schema.isWechat && schema.wechat.length>0">
    <van-cell title="客服微信" label="扫码联系我" icon="wechat" is-link/>
  </van-cell-group>

  <van-cell-group v-if="schema.isTel">
    <van-cell 
    v-for="(item, idx) in schema.cells" :key="idx" 
    :title="item.text" :label="item.tel +' '+ item.label" icon="phone" is-link/>
  </van-cell-group>
</div>
</template>

<script>
export default {
  name: 'kefu',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>

</style>