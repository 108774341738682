<template>
<div>
  
  <template v-if="schema.isShowStatis=='tongji'">
    <div class="text-center" style="padding:10px 0">
      <van-circle
        v-model="currentRate"
        :size="60"
        :rate="100"
        :stroke-width="60"
        color="#67C23A"
        text="268"
      />
      <div class="text-center help">扫码次数</div>
    </div>
  </template>

  <template v-if="schema.isShowStatis=='fangwei'">

    <div class="text-center" style="padding:10px 0">
      <van-circle
        v-model="currentRate"
        :size="60"
        :rate="100"
        :stroke-width="60"
        color="#67C23A"
        text="1"
      />
      <div class="text-center table-statis-success">恭喜您是第1位扫码用户</div>
    </div>

    <div style="margin:6px;padding:10px; border:1px solid #DDDDDD;font-size:14px;">
      <div>第1次：2022-05-10 07:22 广州市天河区</div>
    </div>
  </template>

  <div class="help text-center" v-if="schema.fields.length <=0">
    未配置显示字段
  </div>
  <div v-else>

    <div :style="'padding:'+schema.paddingTB+'px '+schema.paddingLR+'px;'">

      <!-- 封面图片先显示 -->
      <div v-for="(item, idx) in schema.fields" :key="'field-img-'+idx" >
          <div v-if="item.type=='image'">
              <img style="width:100%;display:block;" v-lazy="'/static/images/demo-placeholder.jpg'"/>
          </div>
      </div>

      <template v-if="schema.isShowMode=='accordion'">
        <!-- 手风琴 -->
        <van-collapse v-model="schema.accordionActive" accordion>
          <template v-for="(item, idx) in schema.fields" >
            <template v-if="item.type !='map' && item.type !='image' && item.type !='pdf' && item.label!=''" >
              <van-collapse-item :title="item.label" :name="idx" :key="'field-'+idx">{{item.label}}的内容示例</van-collapse-item>
            </template>
          </template>
        </van-collapse>
      </template>
      <template v-else>
        <!-- 显示表 -->
        <section class="diy-editor">
            <table class="table" style="font-size: 14px; table-layout: fixed;  width: 100%;">
                <tbody>
                  <template  v-for="(item, idx) in schema.fields" >
                    <tr v-if="item.type !='map' && item.type !='image' && item.type !='pdf' && item.label!=''" :key="'field-'+idx">
                        <td :style="'width:'+ schema.leftWidth+'%; word-break: break-all;padding: 8px 10px; border: 1px solid '+schema.borderColor+'; color:'+schema.color+';'">
                            {{item.label}}
                        </td>
                        <td :style="'word-break: break-all;padding: 8px 10px; border: 1px solid '+schema.borderColor+'; color:'+schema.color+';'">
                          {{item.label}}的内容
                        </td>
                    </tr>
                  </template>
                  
                </tbody>
            </table>

        </section>
      
      </template>

    </div>

    <!-- pdf -->
    <template v-for="(item, idx) in schema.fields" >
      <van-cell-group v-if="item.type=='pdf'"  :key="'pdf-'+idx">
        <div class="block-title">{{item.label}}</div>
        <van-cell :title="item.label + '.pdf'" value="125 KB" is-link>
          <div slot="icon" class="qr-type-icon">
            <van-icon name="https://v.2weima.com/static/images/icon/pdf.png" :size="23"  />
          </div>
        </van-cell>
      </van-cell-group>
    </template>

     <!-- 地图在后面显示 -->
    <div v-for="(item, idx) in schema.fields" :key="'field-map-'+idx">
        <div v-if="item.type=='map'">
            <img style="width:100%;display:block;" v-lazy="'/static/images/demo-map.png'"/>
        </div>
    </div>


  </div>

</div>
</template>

<script>
export default {
  name: 'diyTable',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  data:function(){
    return {
      currentRate:100
    }
  },
  methods:{

  }
}
</script>

<style>
.title-desc{
    font-size: 12px;
    font-weight: 400;
    margin-top:5px;
}


</style>