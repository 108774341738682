<template>
  <el-dialog title="编辑内容" :visible="visible" width="95%" class="table-dialog" :close-on-click-modal="false" @close="onCancel">

    <div class="overflow-x-auto">
      <table class='table table-xs border table-pin-rows table-pin-cols'>
        <thead>
          <tr class='bg-orange-50'>
            <th class='border w-3 bg-orange-50'></th>

            <th v-for="(_cell, cellIndex) in arrayData[0]" :key="cellIndex" class='border bg-orange-50'>
              <div class=' flex justify-between items-center'>
                <div class=' font-bold text-base'>{{ generateExcelColumnName(cellIndex + 1) }}</div>
                <div class='flex gap-2'>
                  <el-tooltip content='在前面插入行'>
                    <div class='flex items-center' @click="insertColumn(cellIndex)">
                      <i class="el-icon-top-left"></i>
                    </div>
                  </el-tooltip>
                  <el-tooltip content='删除本列'>
                    <div class='flex items-center' @click="deleteColumn(cellIndex)">
                      <i class="el-icon-circle-close" style="color: red ;"></i>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </th>
            <th class='border w-3 bg-orange-50' colspan="2">
              <div class=' flex justify-between items-center'>
                <div class=' font-bold'>操作</div>
                <el-tooltip content='清空数据'>
                  <div class='flex items-center cursor-pointer' @click="clearData">
                    <i class="el-icon-delete"></i>
                    清空
                  </div>
                </el-tooltip>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>

          <tr v-for="(row, rowIndex) in arrayData" :key="rowIndex" class='border'>
            <th class='border bg-orange-50'>{{ rowIndex + 1 }}</th>

            <td v-for="(cell, cellIndex) in row" :key="cellIndex" class='border'>
              <el-tooltip :content="generateExcelColumnName(cellIndex + 1) + (rowIndex + 1)">
                <el-input type="textarea" :rows="2" :maxlength="200" placeholder="请输入"
                  :key="rowIndex + '_' + cellIndex" 
                  v-model="cell.value"></el-input>
              </el-tooltip>
            </td>

            <td v-if="rowIndex == 0"
              class='border w-3 text-gray-500 cursor-pointer hover_bg-gray-100 hover_text-gray-800' @click="addColumn"
              :rowspan="arrayData?.length">
              <div class='flex flex-col text-center'>
                <i class="el-icon-plus"></i> 添加列
              </div>
            </td>

            <td class='w-10'>
              <div class=' flex justify-center items-center gap-1'>
                <el-tooltip content='在上面插入行'>
                  <el-button type="primary" size="mini" icon="el-icon-top-left"
                    @click="insertRow(rowIndex)">插入行</el-button>
                </el-tooltip>
                <el-tooltip content='删除本行'>
                  <el-button type="danger" size="mini" icon="el-icon-circle-close"
                    @click="deleteRow(rowIndex)">删除行</el-button>
                </el-tooltip>
              </div>
            </td>
          </tr>

        </tbody>
        <tfoot>
          <tr>
            <td class='border text-gray-500 cursor-pointer hover_bg-gray-100 hover_text-gray-800' @click="addRow"
              :colspan="((arrayData[0]?.length || 0) + 3)">
              <div class='flex justify-center text-center gap-2 p-1'><i class="el-icon-plus"></i> <span>添加行</span></div>
            </td>
          </tr>
        </tfoot>
      </table>


    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </span>

  </el-dialog>
</template>
<script>
import utils from "../utils/utils"
import _ from 'lodash'

export default {
  name: 'TableEditorDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: []
    },
  },
  data: function () {
    return {
      arrayData: []
    }
  },
  watch: {
    visible: function (val) {
      // onLoad 打开且 tableData 为空
      if (val) {
        this.arrayData = this.tableData.length == 0 ? [[{ value: "" }, { value: "" }], [{ value: "" }, { value: "" }]] : [...this.tableData];
      }
    }
  },
  methods: {
    // 这段代码将生成 Excel 表列的命名，从 A 开始，依次递增，直到 Z，然后从 AA 开始，以此类推。
    // for (let i = 0; i <= 30; i++) {
    //     console.log(generateExcelColumnName(i));
    // }
    generateExcelColumnName: function (index) {
      let columnName = '';
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      while (index > 0) {
        let remainder = (index - 1) % 26;
        columnName = alphabet.charAt(remainder) + columnName;
        index = Math.floor((index - remainder) / 26);
      }
      return columnName;
    },
    onCancel() {
      this.$emit('update:visible', false)
    },
    onConfirm() {
      this.$emit('update:visible', false)
      this.$emit('confirm', _.clone(this.arrayData))
    },
    // 添加行
    addRow() {
      let newRow = Array(this.arrayData[0].length).fill({ value: "" });
      newRow = JSON.parse(JSON.stringify(newRow))
      // setArrayData([...arrayData, newRow]);
      if (_.isEmpty(this.arrayData)) {
        this.arrayData = [];
      }
      const newArray = _.clone(this.arrayData);
      this.arrayData = [...newArray, newRow]
    },
    // 插入行
    insertRow(index) {
      let newRow = Array(this.arrayData[0].length).fill({ value: "" });
      newRow = JSON.parse(JSON.stringify(newRow))
      const newArray = _.clone(this.arrayData);

      newArray.splice(index, 0, newRow);
      // setArrayData(newArray);
      this.arrayData = _.clone([...newArray])
    },

    // 添加列
    addColumn() {
      const newArray = this.arrayData.map(row => [...row, { value: "" }]);
      // setArrayData(newArray);
      this.arrayData = _.clone([...newArray])
    },

    // 插入列
    insertColumn(index) {
      const newArray = this.arrayData.map(row => {
        const newRow = [...row];
        newRow.splice(index, 0, { value: "" });
        return newRow;
      });
      // setArrayData(newArray);
      this.arrayData = _.clone([...newArray])
    },

    // 删除列
    deleteColumn(index) {
      const newArray = this.arrayData.map(row => {
        const newRow = [...row];
        newRow.splice(index, 1);
        return newRow;
      });
      // setArrayData(newArray);
      this.arrayData = _.clone([...newArray])

      if (this.arrayData[0].length <= 0) {
        this.arrayData = [[{ value: "" }, { value: "" }], [{ value: "" }, { value: "" }]]
      }

    },


    // 删除行
    deleteRow(index) {
      const newArray = [...this.arrayData];
      newArray.splice(index, 1);
      // setArrayData(newArray);
      this.arrayData = _.clone([...newArray])

      if (this.arrayData.length <= 0) {
        this.arrayData = [[{ value: "" }, { value: "" }], [{ value: "" }, { value: "" }]]
      }
    },

    // 清空数据
    clearData() {
      // setArrayData([[{ value: "" }, { value: "" }], [{ value: "" }, { value: "" }]]);
      this.arrayData = [[{ value: "" }, { value: "" }], [{ value: "" }, { value: "" }]]
    },



  }
}
</script>

<style>
i {
  font-size: 1.2em;
}
</style>
