export default {
    name: 'qrPdf',
    title: '文件',
    iconSize:43,
    qrList: [{
        icon: 'https://v.2weima.com/static/images/icon/pdf.png',
        filePath:'',
        text: '设备使用手册',
        label: '新入职必读，公司设备使用手册',
        qrTitle: '',
        qrScene: ''
    }]
}