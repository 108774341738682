export default {
    name: 'swipe',
    title: '轮播图',
    images: [{
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/swipe-1.png',
        alt: '',
        handle: 'none',
        message: '',
        link: '',
        path: '',
        //小程序页面
        appId: '' //小程序appId
    },
    {
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/swipe-2.png',
        alt: '',
        handle: 'none',
        message: '',
        link: '',
        path: '',
        //小程序页面
        appId: '' //小程序appId
    }],
    autoplay: 3000,
    loop: true,
    showIndicators: true,
    indicatorColor: "#1989fa"
}