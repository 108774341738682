export default {
    name: 'table',
    title: '数据表',
    label:'资产表',
    isShowMode:'table',//or accordion
    isShowStatis:'none',//none无统计 tongji显示统计 fangwei 防伪统计
    leftWidth:30,//table 左列宽
    borderColor:'#dddddd',//table 边框
    color: '#666666',//table 文字
    paddingTB:5,
    paddingLR:5,
    accordionActive:0,//默认显示
    tableScene:'',//绑定表
    tableFields:[],//表中字段
    fields:[]
    // [//要显示的字段
    //     // {
    //     //     type: 'image', //text
    //     //     label: '图片',
    //     //     column:1 //Excel列
    //     // }
    // ],
}