<template>
<div>
  <el-form
    label-position="right"
    label-width="90px"
  >

    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>

    <el-form-item label="显示方式">
      <el-radio-group v-model="currentPlugin.isShowMode" size="small">
        <el-radio-button label="list">表格</el-radio-button>
        <el-radio-button label="pwd">设置查看密码</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="查看密码" v-if="currentPlugin.isShowMode=='pwd'">
      <el-input v-model="currentPlugin.showModePwd" placeholder="请输入查看密码"  clearable/>
    </el-form-item>
    
    <el-form-item label="属性">
      <el-checkbox v-model="currentPlugin.isShowTime" label="显示提交时间"  />
    </el-form-item>

    <el-form-item label="显示组件">
    
      <el-select v-model="tableSelectColumns" @change="selectColumnsChange" multiple placeholder="请选择要显示字段" clearable>
        <el-option
          v-for="(item,idx) in formPluginList"
          :key="'field-'+idx"
          :label="item.label + '（' + item.title + '）'"
          :value="idx">
        </el-option>
      </el-select>
    </el-form-item>

    <template v-if="$store.state.formSettings.multipleForm">
      <el-divider>其他</el-divider>
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>
  
   

  </el-form>
  <!-- 链接选择 -->
  <action-link-dialog :visible.sync="visibleLink" source="table" @confirm="onConfirmLink"/>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ActionLinkDialog from '../../ActionLinkDialog'
import _ from 'lodash'
import utils from '../../../utils/utils'

export default {
  name: 'formData',
  props: {},
  components: {
    ActionLinkDialog
  },
  computed:{
    ...mapGetters(['currentPlugin']),
    formPluginList:function(){
      let that = this;
      let _pluginList = this.$store.state.pluginList

      //formData field check
      return _.filter(_pluginList, function(item) { return utils.formDataFieldCheck(item.name) });
    }
  },
  data:function(){
    return {
      visibleLink:false,
      tableSelectColumns:[]
    }
  },
  mounted:function(){
    // console.log('mounted-111')
    this.initSelectColumns();
  },
  watch:{
    currentPlugin:{
        handler(new_value,old_value){
          // 相同类型切换不更新编辑器
          if(new_value.uniqid != old_value.uniqid){
            this.initSelectColumns();
          }
        },
        deep: true
    }
  },
  methods:{
    onSelectTable(){
      // console.log('onUploadImage')
      this.visibleLink = true;
    },
    onConfirmLink(linkData){
      // console.log('linkData',linkData)
      this.currentPlugin.label = linkData.title
      this.currentPlugin.tableScene = linkData.ta_scene
      this.currentPlugin.tableFields = linkData.data_json
      this.currentPlugin.fields = []//linkData.data_json
      //  console.log('this.currentPlugin.fields',this.currentPlugin.fields)
    },
    // 检查恢复选中字段信息
    initSelectColumns(){
      let _fieldsUniqidArr = this.currentPlugin.fields.map((item)=>item.uniqid)
      let _tableSelectColumns = [];
      if(!_.isEmpty(this.currentPlugin.fields)){
        this.formPluginList.forEach((item,index)=>{
          if(_.includes(_fieldsUniqidArr,item.uniqid)){
            _tableSelectColumns.push(index)
          }
        })
        this.tableSelectColumns = _tableSelectColumns
      }
    },
    selectColumnsChange(indexES){
      let that = this;

      // console.log('indexES',indexES)
      let _fields = []
      indexES.forEach(index => {
      //  _fields.push(this.formPluginList[index])
        let _plugin = that.formPluginList[index];
         //评价显示时需要样式设置信息，所以要整个 Plugin
        if(_plugin.name=='rate'){
          _fields.push(_plugin);
        }else{
           _fields.push({
            name: _plugin.name,
            title: _plugin.title,
            uniqid: _plugin.uniqid,
            label: _plugin.label
          });
        }

      });

      this.currentPlugin.fields = _fields

    //  console.log('this.currentPlugin.fields',this.currentPlugin.fields)
    }
   
  }
}

</script>


<style scoped>


</style>
