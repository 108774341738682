export default {
    name: 'formStatus',
    title: '状态',
    uniqid:'',
    formName:'default',
    label:'设备状态',
    desc:'如有异常请尽快处理',
    working:{
        status:'normal', //none 无 normal 正常 abnormal 异常
        label:'运行正常',
        other:'',//label==其他 时
    },
    tips:{
        fontSize:30,
    },
    statusIndex:0,
    groups:[
        {
            label:'运行正常',
            status:'normal',
        },
        {
            label:'等待维修',
            status:'abnormal',
        },
        {
            label:'维修中',
            status:'abnormal',
        },
        {
            label:'未激活',
            status:'none',
        },
    ]
}