<template>
  <el-form
    label-position="right"
    label-width="70px"
  >
    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>

    <el-form-item label="默认值">
      <el-input-number size="small" v-model="currentPlugin.value" :min="currentPlugin.min" :max="currentPlugin.max" :step="currentPlugin.step"  ></el-input-number>
    </el-form-item>

    <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.required" label="必填"  />
        <el-checkbox v-model="currentPlugin.disableInput" label="不能输入" />
        <el-checkbox v-model="currentPlugin.disabled" label="禁用" />
    </el-form-item>


    <el-divider>其他</el-divider>

    <el-form-item label="最小值">
      <el-input-number size="small" v-model="currentPlugin.min" ></el-input-number>
    </el-form-item>
    <el-form-item label="最大值">
      <el-input-number size="small" v-model="currentPlugin.max" ></el-input-number>
    </el-form-item>
    <el-form-item label="步长">
      <el-input-number size="small" v-model="currentPlugin.step" ></el-input-number>
    </el-form-item>

    <el-form-item label="输入框宽">
      <el-input-number size="small" v-model="currentPlugin.inputWidth" :min="40" :step="10"></el-input-number>
    </el-form-item>
    
    <el-form-item label="形状">
      <el-radio-group v-model="currentPlugin.theme" size="small">
        <el-radio-button label="square">方形</el-radio-button>
        <el-radio-button label="round">圆形</el-radio-button>
      </el-radio-group>
    </el-form-item>


    <template v-if="$store.state.formSettings.multipleForm">
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>

  </el-form>



</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'stepper',
  props: {},
  components: {
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
}

</script>


<style scoped>


</style>
