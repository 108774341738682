import navBar from './modules/navBar.vue'
import noticeBar from './modules/noticeBar.vue'
import swipe from './modules/swipe.vue'
import navGrid from './modules/navGrid.vue'
import image from './modules/image.vue'
import imageGrid from './modules/imageGrid.vue'
import richText from './modules/richText.vue'
import title from './modules/title.vue'
import button from './modules/button.vue'
import kefu from './modules/kefu.vue'
import kongbai from './modules/kongbai.vue'
import divider from './modules/divider.vue'
import qrPdf from './modules/qrPdf.vue'
import qrPhoto from './modules/qrPhoto.vue'
import qrMap from './modules/qrMap.vue'
import qrVcard from './modules/qrVcard.vue'
import qrWifi from './modules/qrWifi.vue'
import qrMusic from './modules/qrMusic.vue'
import qrVideo from './modules/qrVideo.vue'
import table from './modules/table.vue'
import field from './modules/field.vue'
import checkboxGroup from './modules/checkboxGroup.vue'
import radioGroup from './modules/radioGroup.vue'
import stepper from './modules/stepper.vue'
import switchOff from './modules/switchOff.vue'
import imgUploader from './modules/imgUploader.vue'
import selectPicker from './modules/selectPicker.vue'
import selectJoin from './modules/selectJoin.vue'
import location from './modules/location.vue'
import mapMarked from './modules/mapMarked.vue'
import area from './modules/area.vue'
import rate from './modules/rate.vue'
import datetime from './modules/datetime.vue'
import submit from './modules/submit.vue'
import formData from './modules/formData.vue'
import tableField from './modules/tableField.vue'
import checkList from './modules/checkList.vue'
import nuoChe from './modules/nuoChe.vue'
import formStatus from './modules/formStatus.vue'
import mp3Play from './modules/mp3Play.vue'
import mp4Play from './modules/mp4Play.vue'
import jumpList from './modules/jumpList.vue'
import collapse from './modules/collapse.vue'
import easyTable from "./modules/easyTable"
import buttonGroup from "./modules/buttonGroup"

// import table from './modules/table.vue'
// import address from './modules/address.vue'
// import signature from './modules/signature.vue'
// import datePicker from './modules/datePicker.vue'
// import timePicker from './modules/timePicker.vue'
// import cascader from './modules/cascader.vue'
// import textarea from './modules/textarea.vue'

export default {
    navBar,
    noticeBar,
    swipe,
    navGrid,
    image,
    imageGrid,
    richText,
    title,
    button,
    kefu,
    kongbai,
    divider,
    qrPdf,
    qrPhoto,
    qrMap,
    qrVcard,
    qrWifi,
    qrMusic,
    qrVideo,
    table,
    field,
    checkboxGroup,
    radioGroup,
    stepper,
    switchOff,
    imgUploader,
    selectPicker,
    selectJoin,
    location,
    mapMarked,
    area,
    rate,
    datetime,
    submit,
    formData,
    tableField,
    checkList,
    nuoChe,
    formStatus,
    mp3Play,
    mp4Play,
    jumpList,
    collapse,
    easyTable,
    buttonGroup
    // table,
    // address,
    // signature,
    // datePicker,
    // timePicker,
    // cascader,
    // textarea
}