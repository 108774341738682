<template>
  <el-dialog title="选项设置" :close-on-click-modal="false" :visible="visible" width="95%"  @close="onCancel" >
    
    <el-button icon="el-icon-plus" type="primary" @click="onAddTopItem()" plain >添加一级选项</el-button>
    <div class="help mt-10">拖动更换位置，下面 <el-button icon="el-icon-plus" size="mini" title="添加子项" type="primary" plain circle /> 是添加子项目，目前最多可设置 3级关联</div>
    <el-divider></el-divider>
    <el-tree
      :data="dataSource"
      :expand-on-click-node="false"
      :highlight-current="true"
      node-key="id"
      empty-text="请添加选项"
      class="cascader-tree"
      default-expand-all
      draggable
    >
      <template #default="{ node, data }">
        <span class="custom-tree-node">
          <span><el-input v-model="data.label" size="small" placeholder="请输入选项名称" clearable/></span>
          <span>
            <el-button  v-if="node.level <3" @click="onAddChildren(data)" icon="el-icon-plus" size="mini" title="添加子项" type="primary" plain circle />
            <el-button @click="onRemoveItem(node, data)" icon="el-icon-close" size="mini" title="删除选项" circle /> 
          </span>
        </span>
      </template>
    </el-tree>

    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </span>
    
  </el-dialog>
</template>
<script>
import utils from '../utils/utils'

let tmpId = 0;
export default {
  name: 'CascaderDialog',
  props: {
    visible: {
      type:Boolean,
      default:false
    },
    cascaderData:{
      type:Array,
      default:[]
    }
  },
  data:function(){
    return {
      dataSource:[]
    }
  },
  watch:{
    visible:function(value){
      if(value){
        this.dataSource = utils.cascader2tree(this.cascaderData)
      }
    }
  },
  methods:{
    onCancel(){
      // this.visible = false
      this.$emit('update:visible', false)
    },
    onConfirm(){

     
      // this.visible = false
      this.$emit('update:visible', false)
      this.$emit('confirm',utils.tree2cascader(this.dataSource))
    },
    onAddTopItem(){
      this.dataSource.push({ 
        id: tmpId++, 
        label: '选项' + tmpId, 
        children: []
      })
    },
    onAddChildren (data){
      const newChild = { 
        id: tmpId++, 
        label: '选项' + tmpId, 
        children: [] 
      }
      if (!data.children) {
        data.children = []
      }
      data.children.push(newChild)
      this.dataSource = [...this.dataSource]
    },
    onRemoveItem(node, data) {
    //  console.log('remove',node, data)
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex((d) => d.id === data.id)
      children.splice(index, 1)
      this.dataSource = [...this.dataSource]
    }

   
  }
}
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.cascader-tree .el-tree-node__content{
  height: 32px;
  padding: 8px;
}

.tree-dialog{
  max-width: 520px;
}
.tree-dialog .el-dialog__body{
  padding-top:10px;
}
</style>
