<template>
  <van-swipe :autoplay="schema.autoplay" :loop="schema.loop" :show-indicators="schema.showIndicators" :indicator-color="schema.indicatorColor">
    <van-swipe-item v-for="(item, idx) in schema.images" :key="idx">
        <img
          class="swipe-image"
          v-lazy="item.url"
        />
    </van-swipe-item>
  </van-swipe>
</template>

<script>

export default {
  name: 'swipe',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>
.swipe-image{
  max-width:100%; width:auto;margin:0 auto; max-height:400px; display: block;
}
</style>