<template>
  <div>
  
    <el-form
      label-position="right"
      label-width="90px"
    >
      <el-form-item label="名称">
        <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
      </el-form-item>

      <el-form-item label="描述">
        <el-input v-model="currentPlugin.desc" placeholder="请输入描述"  clearable/>
      </el-form-item>

       <el-form-item label="属性">
          <el-checkbox v-model="currentPlugin.required" label="必填"  />
          <!-- <el-checkbox v-model="currentPlugin.isAutoFill" label="自动填充上次提交内容" /> -->
          <!-- <el-checkbox v-model="currentPlugin.isCanAdd" label="允许填表人增加项目" /> -->
      </el-form-item>
 
      
      <el-divider>检查项</el-divider>
    
      <el-collapse v-model="activeName" accordion >

        <draggable v-model="currentPlugin.items"  handle=".mover">
          <el-collapse-item v-for="(item, idx) in currentPlugin.items" :key="idx" :name="idx">
            <template slot="title">
              <div :style="{flexGrow:1}">
                <span class="mover" title="拖动改变顺序"><i class="header-icon el-icon-rank"></i></span> 项目{{idx+1}}
                <span :style="{float:'right',marginRight:'10px'}">
                  <el-link type="danger" @click.stop="onPluginDelItem(idx)" :underline="false"><i class="el-icon-circle-close" :style="{fontSize:'18px'}"></i></el-link>
                </span>
              </div>
            </template>

            <el-form-item label="项目名称">
              <el-input v-model="item.label" size="small" placeholder="请输入项目" @blur="(e)=>{onBlurCheck(e.target.value,idx)}" />
            </el-form-item>
            <el-form-item label="项目描述">
              <el-input v-model="item.desc" size="small" placeholder="请输入描述" @blur="(e)=>{onBlurCheck(e.target.value,idx)}" />
            </el-form-item>

            <el-form-item label="默认状态">
              <el-radio-group v-model="item.status" size="small">
                <el-radio-button label="none">无状态</el-radio-button>
                <el-radio-button label="no">异常</el-radio-button>
                <el-radio-button label="yes">正常</el-radio-button>
              </el-radio-group>
            </el-form-item>

          </el-collapse-item>
        </draggable>

      </el-collapse>
      <div class=" mt-10"><el-button @click="onPluginAddItem" type="success" size="small" icon="el-icon-plus" round>添加项目</el-button></div>

      <template v-if="$store.state.formSettings.multipleForm">
        <el-divider>其他</el-divider>
        <el-form-item label="所属表单">
          <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
        </el-form-item>
      </template>

    </el-form>
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable';
import pluginSchema from '../../../pluginSchema/index'
import _ from 'lodash'


export default {
  name: 'checkList',
  props: {},
  components: {
    draggable
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
      visibleLink:false,
      setLinkIndex:0,
      activeName:0,
      visible:false,
      setImagesIndex:0
    }
  },
  methods:{
    // 添加项目
    onPluginAddItem(){
      const theSchema = pluginSchema.checkList;
      this.currentPlugin.items.push(_.cloneDeep( theSchema.items[0] ))
    },
    // 删除项目
    onPluginDelItem(index){
      let that = this;
      this.currentPlugin.items.splice(index,1);
      if( this.currentPlugin.items.length<=0){

        that.$message({
          message: '至少设置一项',
          type: 'warning'
        });

        //全部删除了就添加个默认项目
        setTimeout(function(){
          that.onPluginAddItem();
          
        },100);
      }

    }
   
  }
}

</script>


<style >

</style>
