<template>
<van-field 
  center
  name="mapMarked"
  :label="schema.label" 
  :placeholder="schema.placeholder" 
  :value="schema.value"
  :required="schema.required === true" 
>
  <template slot="button">
    <van-button size="small" type="primary" icon="map-marked">位置</van-button>
  </template>
</van-field>
</template>

<script>
// import { Notify } from 'vant';

export default {
  name: 'mapMarked',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    // onScan(){
    //   Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    // }
  }
}
</script>

<style>

</style>