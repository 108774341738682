<template>
  <div>

    <el-form label-position="right" label-width="90px">
      <el-form-item label="上下边距">
        <el-input-number size="small" v-model="currentPlugin.paddingTB" :min="0" :max="300"></el-input-number> PX
      </el-form-item>

      <el-form-item label="左右边距">
        <el-input-number size="small" v-model="currentPlugin.paddingLR" :min="0" :max="300"></el-input-number> PX
      </el-form-item>

      <el-divider>内容设置</el-divider>

      <textarea id="diy-ueditor"></textarea>

      <el-divider>提示</el-divider>
      <div class="help mt-10">表格：点击表格，通过：<strong>添加一项</strong> 来添加行</div>
      <div class="help mt-10">图片：请用图片组件，勿粘贴带图片的内容，暂不支持第三方图片显示</div>
      <div class="help mt-10">预览效果：电脑和手机有差异，编辑好后请使用手机预览确认</div>

    </el-form>
    <!-- 样式选择 -->
    <diy-style-dialog :visible.sync="visible" @confirm="onConfirm" />
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import DiyStyleDialog from '../../DiyStyleDialog'
import _ from 'lodash'

let diyUeditor = null;

export default {
  name: 'navGrid',
  props: {},
  components: {
    DiyStyleDialog
  },
  computed: {
    ...mapGetters(['currentPlugin'])
  },
  data: function () {
    return {
      visible: false,
    }
  },
  mounted: function () {
   
  
    this.initRichText();

  },
  destroyed: function () {
    // console.log('destroyed-111')
    this.hiddenRichText();
  },
  watch: {
    currentPlugin: {
      handler(new_value, old_value) {
        // console.log('new_value, old_value', new_value, old_value)
        // 相同类型切换不更新编辑器
        if (new_value.uniqid != old_value.uniqid) {
          this.initRichText();
        }
      },
      deep: true
    }
  },
  methods: {
    hiddenRichText: function () {
      if (diyUeditor) {
        diyUeditor.destroy();
        diyUeditor = null;
      }
    },
    initRichText: function () {
     
      var that = this;
      this.$nextTick(() => {

        if (diyUeditor) {
          // console.log('diyUeditor', diyUeditor)
          diyUeditor.setContent(that?.currentPlugin?.text || '');
          return;
        }

        // console.log('nextTick')

        diyUeditor = UE.getEditor('diy-ueditor', {
          diyeditor: true,
          toolbars: [['FullScreen', 'diystyle', 'Source', 'Undo', 'Redo', 'Bold', 'underline', 'strikethrough', 'fontborder', "|", 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'inserttable', 'paragraph', 'fontsize', 'fontfamily', 'lineheight', "superscript", "subscript", "pasteplain", "|", "justifyleft", "justifycenter", "justifyright", "justifyjustify", "|", 'removeformat', 'cleardoc']],
          initialFrameHeight: 320,  //初始化编辑器高度,默认320
          wordCount: false,
          elementPathEnabled: false,
          allowDivTransToP: false,// 关闭div转p
          diystyleExec: function (ue) {
            // console.log('diystyleExec');
            that.onDiyStyleOpen();
          }
        });
        diyUeditor.ready(function () {
          diyUeditor.setContent(that?.currentPlugin?.text || '');
          diyUeditor.addListener('contentChange', function () {
            that.currentPlugin.text = diyUeditor.getContent();
          });
        });



      });
    },
    onDiyStyleOpen() {
      this.visible = true;
    },
    onConfirm(styleData) {
      // console.log('styleData',styleData)
      this.currentPlugin.text = styleData.content
      diyUeditor.setContent(this.currentPlugin.text);
    },
    // onUploadImage(index){
    //   // console.log('onUploadImage')
    //   this.visible = true;
    //   this.setImagesIndex = index;
    // },
    // onConfirm(imageData){
    //   // console.log('imageData',imageData)
    //   this.currentPlugin.images[this.setImagesIndex].id = imageData.id
    //   this.currentPlugin.images[this.setImagesIndex].url = imageData.url
    //   this.currentPlugin.images[this.setImagesIndex].alt = imageData.name
    // },
    // // 添加项目
    // onPluginAddItem(){
    //   const theSchema = pluginSchema.image;
    //   this.currentPlugin.images.push(_.cloneDeep( theSchema.images[0] ))
    // },
    // // 删除项目
    // onPluginDelItem(index){
    //   let that = this;
    //   this.currentPlugin.images.splice(index,1);
    //   if( this.currentPlugin.images.length<=0){

    //     that.$message({
    //       message: '至少设置一项',
    //       type: 'warning'
    //     });

    //     //全部删除了就添加个默认项目
    //     setTimeout(function(){
    //       that.onPluginAddItem();

    //     },100);
    //   }

    // }

  }
}

</script>


<style></style>
