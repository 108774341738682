export default {
    name: 'radioGroup',
    title: '单选框组',
    formName:'default',
    uniqid:'',
    label:'性别',
    direction:'horizontal',
    shape:'round',
    disabled:false,
    required: false,
    value:'',
    other:'',//其他值
    checkedColor:'#1989fa',
    groups:[
        {
            label:'男',
            disabled:false,
            checked:true,
            // checkedColor:'#1989fa'
        },
        {
            label:'女',
            disabled:false,
            checked:false,
            // checkedColor:'#1989fa'
        }
    ]
}