export default {
    name: 'switchOff', //switch关键字，旧版要升级到 switchOff
    title: '开关',
    formName:'default',
    uniqid:'',
    label:'开关',
    checked: false,
    required: false,
    activeColor:"#1989fa",
    inactiveColor:"#dcdee0"
}