export default {
    name: 'stepper',
    title: '步进器',
    formName:'default',
    uniqid:'',
    label:'年龄',
    theme:'square',//round
    disabled:false,
    disableInput:false,
    required: false,
    inputWidth:80,
    step:1,
    min:0,
    max:200,
    value:18
}