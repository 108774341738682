<template>
<div>
  <el-form
    label-position="right"
    label-width="70px"
  >
    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>
    <el-form-item label="提示">
      <el-input v-model="currentPlugin.placeholder" placeholder="请输入组件提示"  clearable/>
    </el-form-item>

    <el-form-item label="选项">
      <div class="set-options">
        <el-row>
          <el-col :span="24"><el-radio v-model="currentPlugin.value" :label="''" title="设置默认选中">不选中</el-radio></el-col>
        </el-row>
        <el-row :gutter="10" v-for="(item, idx) in currentPlugin.columns" :key="idx">
          <el-col :span="3"><el-radio v-model="currentPlugin.value" :label="item" title="设置默认选中"><span></span></el-radio></el-col>
          <el-col :span="18"><el-input v-model="currentPlugin.columns[idx]" size="small" placeholder="请输入选项" @blur="(e)=>{onBlurCheck(e.target.value,idx)}"/></el-col>
          <el-col :span="3">
            <el-popconfirm title="确认删除选项吗？"
              confirm-button-text="确认"
              cancel-button-text="取消"
              @confirm="onRemoveItem(idx)"
            >
              <template #reference>
                <el-button type="default" icon="el-icon-close" size="small" circle />
              </template>
            </el-popconfirm>
          </el-col>
        </el-row>
        <el-row class="mt-10">
          <el-col :span="24"><el-button icon="el-icon-plus" @click="onAddItem">添加选项</el-button></el-col>
        </el-row>
      </div>
    </el-form-item>


    <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.required" label="必填"  />
        <!-- <el-checkbox v-model="currentPlugin.isCamera" label="只能拍照" /> -->
        <!-- <el-checkbox v-model="currentPlugin.disabled" label="禁用" /> -->
    </el-form-item>



    <template v-if="$store.state.formSettings.multipleForm">
      <el-divider>其他</el-divider>
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>

  </el-form>

<icons-dialog v-model="currentPlugin.uploadIcon" :visible.sync="visible"/>
</div>

</template>
<script>
import { mapGetters } from 'vuex'
import IconsDialog from '../../IconsDialog'

export default {
  name: 'selectPicker',
  props: {},
  components: {
    IconsDialog
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
      visible:false
    }
  },
  methods:{
    onAddItem(){
      let columns = this.currentPlugin.columns
      let length = this.currentPlugin.columns.length
      // console.log('length',length)
      columns.push('选项' + (length +1))
      
      columns = _.uniq(columns); // 去重
      this.currentPlugin.columns = columns
    },
    // 选中值必须在可选项目中
    checkRemoveValue(){
         let _items = this.currentPlugin.columns
        let _value = this.currentPlugin.value
        // 找出已经删除的
        if(!_.includes(_items, _value)){
          // 移除不存在的项目
          this.currentPlugin.value = ""
        }
    },
    onRemoveItem(index){
      const _label = this.currentPlugin.columns[index].label
      this.currentPlugin.columns.splice(index,1)

      let length = this.currentPlugin.columns.length
      if(length==0){
        this.onAddItem()
      }
      // 删除检测
      this.checkRemoveValue()
      // console.log('this.currentPlugin.values',this.currentPlugin.values)
    },
    // 检查空输入和重复
    onBlurCheck(value,index){
      // 删除空项目
      if(value==""){
        this.currentPlugin.columns.splice(index,1)
        // 删除检测
        this.checkRemoveValue()
      }else{
        // 去重
        let items = this.currentPlugin.columns
        // items = _.uniqBy(items, 'label'); 
        items = _.uniq(items); // 去重
        this.currentPlugin.columns = items
      }
    }

  }


}

</script>


<style scoped>


</style>
