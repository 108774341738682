import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI);

import VueResource from 'vue-resource'
Vue.use(VueResource)

import Vant from 'vant';
import { Lazyload } from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.use(Lazyload);

import '@vant/touch-emulator';

// import { Locale } from 'vant';
// // 引入语言包
// import zhHK from 'vant/es/locale/lang/zh-HK';
// Locale.use('zh-HK', zhHK);


import store from './store';

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')



