<template>
  <el-form
    label-position="right"
    label-width="90px"
  >
    <el-form-item label="高度">
      <el-input-number size="small" v-model="currentPlugin.height" :min="1" :max="150"></el-input-number> PX
    </el-form-item>

    <el-form-item label="背景色">
      <el-color-picker v-model="currentPlugin.bgColor" color-format="hex" size="small"></el-color-picker>
    </el-form-item>

    <el-form-item label="背景透明度">
      <el-input-number size="small" v-model="currentPlugin.transparent" :min="0" :max="100" :step="5"></el-input-number>
    </el-form-item>

  </el-form>

</template>
<script>
import { mapGetters } from 'vuex'


export default {
  name: 'kongbai',
  props: {},
  components: {
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
    }
  },
  methods:{
    // onConfirm(iconName){
    //   console.log('iconName',iconName)
    // }
   
  }
}

</script>


<style scoped>


</style>
