<template>
<div :style="'padding:'+schema.paddingTB+'px '+schema.paddingLR+'px;background:'+schema.bgColor +';text-align:'+schema.textAlign">
  <van-button 
  :color="schema.color"
  :plain="schema.plain"
  :square="schema.square != 'round'"
  :round="schema.square == 'round'"
  :icon="schema.icon"
  :size="schema.size"
  :text="schema.text"
  ></van-button>
</div>
</template>

<script>
export default {
  name: 'diyButton',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>

</style>