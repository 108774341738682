<template>
<van-field name="switch" :label="schema.label" :required="schema.required === true">
  <template slot="input">
    <van-switch v-model="schema.checked" :active-color="schema.activeColor" :inactive-color="schema.inactiveColor" size="20"/>
  </template>
</van-field>
</template>

<script>
// import { Notify } from 'vant';

export default {
  name: 'switchOff',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    // onScan(){
    //   Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    // }
  }
}
</script>

<style>

</style>