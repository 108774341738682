<template>
<div>
  <template v-if="schema.name=='divider' && schema.text!=''">
    <van-divider :content-position="schema.position" :style="{ color: schema.color, borderColor: schema.borderColor}" :dashed="schema.dashed">{{schema.text}}</van-divider>
  </template>
  <template v-if="schema.name=='divider' && schema.text==''">
    <van-divider :content-position="schema.position" :style="{ color: schema.color, borderColor: schema.borderColor}" :dashed="schema.dashed"></van-divider>
  </template>
</div>
</template>

<script>
export default {
  name: 'divider',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    
  }
}
</script>

<style>

</style>