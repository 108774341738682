<template>
<van-field :label="schema.label" :required="schema.required === true" >
  <template slot="input">
    <van-uploader 
    v-model="schema.fileList" 
    :preview-size="schema.previewSize" 
    :upload-icon="schema.uploadIcon" 
    :upload-text="schema.uploadText" 
    disabled/>
  </template>
</van-field>
</template>

<script>
// import { Notify } from 'vant';

export default {
  name: 'imgUploader',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    // onScan(){
    //   Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    // }
  }
}
</script>

<style>

</style>