<template>
  <div>
    <!-- 透明 + 固顶 -->
    <div v-if="schema.name == 'navBar' && pluginIndex == 0">
      <div style="height:46px;" v-if="schema.placeholder && schema.fixed">
        <!-- 透明顶 填充 -->
      </div>
      <div
        :class=" 'navBar-transparent' + (schema.fixed ? '-fixed' : '')">
        <van-nav-bar :style="'background-color: ' + schema.bgColor + (schema.transparent ? '50' : '') + ';'"
          :border="false">
          <div slot="left" v-if="schema.showLeft">
            <van-icon :name="schema.leftIcon || 'wap-home-o'" size="18" class="left-home-white"></van-icon>
          </div>
          <div slot="title" :style="'color: ' + schema.color + ';'">{{ schema.text }}</div>
        </van-nav-bar>
      </div>
    </div>
    <!--  大于0时，没顶部设置 -->
    <div v-else-if="schema.name == 'navBar'">
      <van-nav-bar :style="'background-color: ' + schema.bgColor + (schema.transparent ? '50' : '') + ';'"
        :border="false">
        <div slot="left" v-if="schema.showLeft">
          <van-icon :name="schema.leftIcon || 'wap-home-o'" size="18" class="left-home-white"></van-icon>
        </div>
        <div slot="title" :style="'color: ' + schema.color + ';'">{{ schema.text }}</div>
      </van-nav-bar>
    </div>


    <!-- <div v-if="schema.name == 'navBar' && pluginIndex == 0 && schema.transparent"
      :class="schema.name == 'navBar' && pluginIndex == 0 && schema.transparent ? 'navBar-transparent' + (schema.fixed ? '-fixed' : '') : ''">
      <div class="iphone-liuhai"></div>
      <van-nav-bar :style="'background-color: ' + schema.bgColor + (schema.transparent ? '30' : '') + ';'"
        :border="false">
        <div slot="left" v-if="schema.showLeft">
          <van-icon :name="schema.leftIcon || 'wap-home-o'" size="18" class="left-home-white"></van-icon>
        </div>
        <div slot="title" :style="'color: ' + schema.color + ';'">{{ schema.text }}</div>
      </van-nav-bar>
    </div>
    <div v-else-if="schema.name == 'navBar'">
      <van-nav-bar :style="'background-color: ' + schema.bgColor + (schema.transparent ? '00' : '') + ';'"
        :border="false">
        <div slot="left" v-if="schema.showLeft">
          <van-icon :name="schema.leftIcon || 'wap-home-o'" size="18" class="left-home-white"></van-icon>
        </div>
        <div slot="title" :style="'color: ' + schema.color + ';'">{{ schema.text }}</div>
      </van-nav-bar>
    </div> -->
  </div>

</template>

<script>
// import { Notify } from 'vant';

export default {
  name: 'navBar',
  props: {
    schema: {
      'type': Object,
      default: {},
      required: true
    },
    pluginIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    // onScan(){
    //   Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    // }
  }
}
</script>

<style>
/* navBar */
.navBar-transparent,
.navBar-transparent-fixed {
  /* padding-top: 34px; */
  /* height: 90px; */
  width: 100%;
}

.navBar-transparent-fixed {
  position: absolute !important;
  top: 0;
}

.iphone-liuhai {
  background: #00000080;
  width: 210px;
  height: 30px;
  border: 1px solid #fff;
  position: absolute;
  top: -2px;
  left: 50%;
  margin-left: -105px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 100;
}

.left-home-black {
  background-color: #00000040;
  color: #ffffff !important;
  padding: 5px;
  border-radius: 50px;
  border: 1px solid #e6e6e650;
}

.left-home-white {
  background-color: #ffffff95;
  color: #000000 !important;
  padding: 5px;
  border-radius: 50px;
  border: 1px solid #acabab50;
}
</style>