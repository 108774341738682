export default [
    'wap-home-o',
    'location-o',
    'like-o',
    'star-o',
    'setting-o',
    'cart-o',
    'friends-o',
    'comment-o',
    'smile-comment-o',
    'gift-o',
    'point-gift-o',
    'service-o',
    'bag-o',
    'chat-o',
    'shop-o',
    'volume-o',
    'bullhorn-o',
    'cluster-o',
    'photo-o',
    'photograph',
    'medal-o',
    'good-job-o',
    'manager-o',
    'hot-o',
    'birthday-cake-o',
    'flag-o',
    'bar-chart-o',
    'brush-o',
    'home-o',
    'apps-o',
    'search',
    'qr',
    'revoke',
    'ellipsis',
    'replay',
    'arrow',
    'arrow-left',
    'arrow-up',
    'arrow-down',
    'success',
    'cross',
    'plus',
    'minus',
    'fail',
    'play',
    'weapp-nav',
    'wap-nav',
    'bars',
    'lock',
    'live',
    'gem-o'
]