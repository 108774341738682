<template>
<div :style="'padding-top:'+schema.paddingTB+'px;'">
  <div v-for="(item, idx) in schema.images" :key="idx" :style="'padding-bottom:'+schema.paddingTB+'px;padding-left:'+schema.paddingLR+'px;padding-right:'+schema.paddingLR+'px;'"   >
    <img  
    class="plugin-image"
    v-lazy="item.url"/>
  </div>
</div>
</template>

<script>
export default {
  name: 'diyImage',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>
.plugin-image{
  max-width:100%; width:auto;margin:0 auto; display: block;
}
</style>