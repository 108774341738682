import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import _ from 'lodash'
import utils from '../utils/utils'

const store = new Vuex.Store({
    state: {
        pluginList:[],
        currentPluginIndex:0,
        lastAddPluginCount:0,
        rightTabName:"form",//"plugin",
        formSettings:{
            title:"",
            desc:"",
            opening:"weapp",
            cover:"/static/images/icon/diy.png",
            start_time:"",
            end_time:"",
            max_views:0,
            multipleForm:false,//一页中有多个表单名
        },
        // config
        config:{
            isVip:false,
            user:{"isLogin":0,"nickName":"","isVip":0,"vipLevel":"default"},
            qrScene: '',
            tempScene: '',
            qrTemp:{},
            QrdownUrl: '',
            qrType: 'diy',
        }
    },
    getters: {
        // editablePageData:(state,getters)=>{
        //     return state.pageList.find((item)=> item.page== state.editablePage)
        // },
        // editablePageIndex:(state)=>{
        //     return state.pageList.findIndex((item)=> item.page== state.editablePage)
        // },
        currentPlugin:(state,getters)=>{
            return  state.pluginList[state.currentPluginIndex] || null
        },
        // pageTotal:(state)=>{
        //     return state.pageList.length
        // }
    },
    mutations:{
        setPluginList(state,pluginList){
            // console.log('pluginList',pluginList)
            state.pluginList = pluginList
        },
        setFormSettings(state,formSettings){
            state.formSettings = formSettings
        },
        setConfig(state,config){
            state.config = config
        },
        setRightPlugin(state){
            state.rightTabName = 'plugin'
        },
        setRightForm(state){
            state.rightTabName = 'form'
        },
        setCurrentPluginIndex(state, index) {
            state.currentPluginIndex = index
            // 选中就切换到组件属性
            store.commit('setRightPlugin')
        },
        // 点击左边组件
        addPluginToPage(state,schema){

            const pluginList = state.pluginList

            const insertIndex = pluginList.length <=0 ? 0 : state.currentPluginIndex +1
            
            // 只有第一个navBar透明顶有效果
            if(schema.name=='navBar' && insertIndex>0){
                schema.transparent = false
            }
            pluginList.splice(insertIndex,0,schema)
            // console.log('pluginList',pluginList)
            // 选中
            // state.currentPluginIndex = insertIndex
            // 手机不要自动选中
            if(!utils.isMobile()){
                store.commit('setCurrentPluginIndex',insertIndex)
            }
           
            // 添加次数
            state.lastAddPluginCount ++
        },
        // 复制组件
        copyPlugin(state,index){
            let schema =  _.cloneDeep(state.pluginList[index]);
            // console.log('schema',schema)
            const pluginList = state.pluginList

            const insertIndex = index +1
            
            // 只有第一个navBar透明顶有效果
            if(schema.name=='navBar' && insertIndex>0){
                schema.transparent = false
            }
            schema.uniqid = utils.getUniqid(8);
            pluginList.splice(insertIndex,0,schema)
            // console.log('pluginList',pluginList)
            // 选中
            // state.currentPluginIndex = insertIndex
            store.commit('setCurrentPluginIndex',insertIndex)
            // 添加次数
            state.lastAddPluginCount ++
           
        },
        removePlugin(state,index){
            state.pluginList.splice(index,1);
            // 选中
            state.currentPluginIndex = index>0 ? index-1 : 0;//变更当前选中
           
        },
    }
})

export default store;


// import { createStore } from 'vuex'

// const store = createStore({
//     state: {
//         formSettings:{
//             title:"设备维修表单1",
//             desc:"请仔细填写表单中的内容"
//         },
//         // // count: 1,
//         // formPages: [ //分页信息
//         //     {
//         //         page: 1,
//         //         closable: false
//         //     }
//         // ],
//         // editablePage: 1, //当前编辑页面
//         // pageList: [], //页面数据列表
//         // currentPluginIndex: 0, //当前操作组件
//         // lastAddPluginCount: 0,// 用来watch 处理 scrollToBottom
//         // rightTabName:"form" // plugin or form 右则切换设置
//     },
//     getters: {
//         // editablePageData:(state,getters)=>{
//         //     return state.pageList.find((item)=> item.page== state.editablePage)
//         // },
//         // editablePageIndex:(state)=>{
//         //     return state.pageList.findIndex((item)=> item.page== state.editablePage)
//         // },
//         // currentPlugin:(state,getters)=>{
//         //     return  getters.editablePageData.plugins[state.currentPluginIndex] || null
//         // },
//         // pageTotal:(state)=>{
//         //     return state.pageList.length
//         // }
//     },
//     mutations: {
//         //   increment (state) {
//         //     state.count++
//         //   },
//         // 添加分页
//         // addPage(state) {
//         //     const lastPageData = state.formPages[state.formPages.length-1]
//         //     const newPage = lastPageData.page +1
//         //     // tab
//         //     state.formPages.push({
//         //         page:newPage,
//         //         closable:true
//         //     })
//         //     // pageList 使用 splice 在end页面前插入
//         //     state.pageList.splice(-1,0,{
//         //         page:newPage,
//         //         plugins:[]
//         //     })
//         //     // state.pageList.push({
//         //     //     page:newPage,
//         //     //     plugins:[]
//         //     // })
//         //     state.editablePage = newPage

//         //     // console.log('addPage',newPage,state.pageList)
//         // },
//         // removePage(state,page) {
//         //     // 选中处理
//         //     let activeIndex = store.getters.editablePageIndex// state.editablePage
//         //     activeIndex = activeIndex > 0 ? activeIndex-1 : 0
//         //     state.editablePage = state.pageList[activeIndex].page
//         //     // remove
//         //     state.formPages = state.formPages.filter((item) => item.page !== page)
//         // },
//         // // setEditablePage(state, index) {
//         // //     state.editablePage = index
//         // // },
//         // setPageList(state, pageList) {

//         //     state.pageList = pageList
//         // },
//         // setCurrentPluginIndex(state, index) {
//         //     state.currentPluginIndex = index

//         //     // 选中就切换到组件属性
//         //     store.commit('setRightPlugin')
//         // },
//         // // 点击左边组件
//         // addPluginToPage(state,schema){

//         //     const page = store.getters.editablePageData
           
//         //     const insertIndex = page.plugins.length <=0 ? 0 : state.currentPluginIndex +1
//         //     page.plugins.splice(insertIndex,0,schema)
//         //     // 选中
//         //     // state.currentPluginIndex = insertIndex
//         //     store.commit('setCurrentPluginIndex',insertIndex)
//         //     // 添加次数
//         //     state.lastAddPluginCount ++
//         // },
//         // removePluginInPage(state,index){
//         //     const plugins = store.getters.editablePageData.plugins
//         //     plugins.splice(index,1);
//         //     // 选中
//         //     state.currentPluginIndex = index>0 ? index-1 : 0;//变更当前选中
           
//         // },
//         // setRightPlugin(state){
//         //     state.rightTabName = 'plugin'
//         // },
//         // setRightForm(state){
//         //     state.rightTabName = 'form'
//         // }
//     }
// })

// export default store;