export default {
    name: 'imageGrid',
    title: '图片橱窗',
    columnNum: 2,
    gutter: 0,
    isCollage: false,
    grids: [{
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/grid-1.png',
        alt: '',
        filePath:'',
        // text: '文本1',
        handle: 'none',
        message: '',
        link: '',
        path: '',
        //小程序页面
        appId: '' //小程序appId
    },
    {
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/grid-2.png',
        alt: '',
        filePath:'',
        // text: '文本2',
        handle: 'none',
        message: '',
        link: '',
        path: '',
        //小程序页面
        appId: '' //小程序appId
    },
    {
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/grid-3.png',
        alt: '',
        filePath:'',
        // text: '文本3',
        handle: 'none',
        message: '',
        link: '',
        path: '',
        //小程序页面
        appId: '' //小程序appId
    },
    {
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/grid-4.png',
        alt: '',
        filePath:'',
        // text: '文本4',
        handle: 'none',
        message: '',
        link: '',
        path: '',
        //小程序页面
        appId: '' //小程序appId
    },
    ],
}