<template>
<van-cell-group>
  <van-cell 
  v-for="(item, idx) in schema.fileList" :key="idx" 
  :title="item.text" :value="item.rightText" :label="item.label" clickable>
    <div slot="icon" class="qr-type-icon">
      <!-- <van-icon :name="item.icon" :size="schema.iconSize || 20" /> -->
      <van-icon :name="item.icon" :size="schema.iconSize || 20"  />
    </div>
    <template #right-icon>
      <!-- <van-icon name="stop-circle-o"  color="#ff976a" size="24"/> -->
      <van-icon name="play-circle-o"  color="#07c160" size="24" /> 
      <span style="margin-left:5px;">
      <!-- 12.4 Kb -->
      </span>
    </template>
  </van-cell>
</van-cell-group>
</template>

<script>
export default {
  name: 'mp3Play',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    
  }
}
</script>

<style>
  .qr-type-icon{
    margin-right:8px;
    margin-top:0px;
  }
</style>