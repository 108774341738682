<template>
<van-field :label="schema.label" :required="schema.required === true" >
  <template slot="input">
    <van-stepper v-model="schema.value" :step="schema.step" :min="schema.min" :max="schema.max" :input-width="schema.inputWidth" :theme="schema.theme"  :disabled="schema.disabled" :disable-input="schema.disableInput"/>
  </template>
</van-field>
</template>

<script>
// import { Notify } from 'vant';

export default {
  name: 'stepper',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    // onScan(){
    //   Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    // }
  }
}
</script>

<style>

</style>