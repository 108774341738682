export default {
    name: 'easyTable',
    title: '简易表单',
    tableSize:'table-xs',
    is_hidden:false,
    color: '#000000',
    bgColor: '#f3f4f6',
    paddingTB: 0,
    paddingLR: 0,
    dataSource: []
}