<template>
  <el-form
    label-position="right"
    label-width="70px"
  >
    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>

    <el-form-item label="时间格式">
      <el-radio-group v-model="currentPlugin.type" size="small">
        <div class="datetime-item"><el-radio label="datetime">完整：2020年4月9日 12:30</el-radio></div>
        <div class="datetime-item"><el-radio label="date">日期：2020年4月9日</el-radio></div>
        <div class="datetime-item"><el-radio label="time">时间：12:30</el-radio></div>
        <div class="datetime-item"><el-radio label="year-month">年月：2020年4月</el-radio></div>
        <div class="datetime-item"><el-radio label="month-day">月日：4月9日</el-radio></div>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="属性">
      <el-checkbox v-model="currentPlugin.required" label="必填"  />
    </el-form-item>

    <el-divider>其他</el-divider>




    <template v-if="$store.state.formSettings.multipleForm">
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>


  </el-form>

</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'datetime',
  props: {},
  components: {
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
}

</script>


<style scoped>
.datetime-item{margin:5px 0}

</style>
