export default {
    name: 'checkList',
    title: '检查项目',
    uniqid:'',
    formName:'default',
    label:'消防器检查',
    desc:'如有异常请说明情况尽快处理',
    required: false,
    items:[
        {
            label:'有效期',
            desc:'有效期在3个月以上',
            status:'none' // yes no none
        },
        {
            label:'压力表',
            desc:'指针在绿色区域内',
            status:'none'
        },
        {
            label:'数量',
            desc:'3个以上',
            status:'none'
        }
    ]
}