<template>
<div>

  <el-form
    label-position="right"
    label-width="70px"
  >
    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>
    <el-form-item label="提示">
      <el-input v-model="currentPlugin.placeholder" placeholder="请输入组件提示"  clearable/>
    </el-form-item>

    <el-form-item label="选项">
      <el-button icon="el-icon-setting" type="primary" plain @click="onSetCascader">设置选项</el-button>
    </el-form-item>

    <!-- <el-form-item label="默认值">
      <el-input :value="currentPlugin.values.join(',')" placeholder="请输入默认值" @clear="handleClearClick" clearable >
      </el-input>
    </el-form-item> -->

    <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.required" label="必填" />
    </el-form-item>

    <template v-if="$store.state.formSettings.multipleForm">
      <el-divider>其他</el-divider>
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>

  </el-form>
  

  <cascader-dialog :visible.sync="visible" :cascaderData="currentPlugin.cascader" @confirm="onConfirm"/>
</div>


</template>
<script>
import { mapGetters } from 'vuex'
import CascaderDialog from '../../CascaderDialog'

export default {
  name: 'selectJoin',
  props: {},
  components: {
    CascaderDialog
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
      visible:false
    }
  },
  mounted:function(){
    // console.log('mounted', this.currentPlugin.columns)
    // this.updateCheck()
  },
  // watch:{
  //   currentPlugin:{
  //       handler(new_value,old_value){
  //         // 相同类型切换不更新编辑器
  //         if(new_value.uniqid != old_value.uniqid){
  //         //  console.log('update', this.currentPlugin.columns)
  //          this.updateCheck()
  //         }
  //       },
  //       deep: true
  //   }
  // },
  methods:{
    onSetCascader(){
      this.visible = true
    },
    onConfirm(cascaderData){
      // console.log('data',data)
      this.currentPlugin.cascader = cascaderData

    },
    // 旧数据更新检查 换到 utils 统一处理
    // updateCheck(){
    //   // console.log('updateCheck')
    //   // 把columns格式转为cascader
    //   if(this.currentPlugin.columns){
    //     let _cascader = [];
    //     this.currentPlugin.columns.forEach(element => {

    //       let _children = [];
    //       if(element.children && element.children !=''){
    //         element.children.split(',').forEach(item => {
    //           _children.push({value:item})
    //         });
    //       }
    //       _cascader.push({
    //         value:element.text,
    //         children:_children
    //       })
    //     });
    //     delete this.currentPlugin.columns;
    //     this.currentPlugin.cascader =_cascader
    //     // console.log('this.currentPlugin',this.currentPlugin)

    //   }
    // }
  }
}

</script>


<style scoped>


</style>
