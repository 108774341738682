<template>
  <div class="overflow-x-auto" :style="'padding: ' + schema?.paddingTB + 'px ' + schema?.paddingLR + 'px; background:' + schema?.bgColor + '; color: ' + schema?.color">

    <table :class="'table border table-pin-rows table-pin-cols ' + (schema?.tableSize || 'table-xs')">
      <thead v-if="!schema?.is_hidden">
        <tr>
          <th class='border w-3 bg-orange-50'></th>
          <th v-for="(_cell, cellIndex) in arrayData[0]" :key="cellIndex" class='border bg-orange-50'>
            <div class='font-bold'>{{ generateExcelColumnName(cellIndex + 1) }}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in arrayData" :key="rowIndex">
          <th v-if="!schema?.is_hidden" class='border bg-orange-50'>{{ rowIndex + 1 }}</th>
          <td v-for="(cell, cellIndex) in row" :key="cellIndex" class='border'>
            <div class='nl2br min-w-max'>{{ cell?.value || '' }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'easyTable',
  props: {
    schema: {
      'type': Object,
      default: {},
      required: true
    },
    pluginIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    arrayData: function () {
      const EMPTY_DATA = [[{ value: "" }, { value: "" }], [{ value: "" }, { value: "" }]];

      return this.schema?.dataSource?.length <= 0 ? [...EMPTY_DATA] : this.schema?.dataSource;
    }
  },
  methods: {
    // 这段代码将生成 Excel 表列的命名，从 A 开始，依次递增，直到 Z，然后从 AA 开始，以此类推。
    // for (let i = 0; i <= 30; i++) {
    //     console.log(generateExcelColumnName(i));
    // }
    generateExcelColumnName: function (index) {
      let columnName = '';
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      while (index > 0) {
        let remainder = (index - 1) % 26;
        columnName = alphabet.charAt(remainder) + columnName;
        index = Math.floor((index - remainder) / 26);
      }
      return columnName;
    }

  }
}
</script>

<style></style>