<template>
<!-- <van-field
  readonly
  clickable
  name="area"
  :value="schema.placeholder"
  :label="schema.label"
  placeholder="点击选择地区"
  :required="schema.required === true" 
/> -->
<div>
<van-field
  readonly
  clickable
  :name="schema.name + '_' +schema.uniqid"
  :value="schema.placeholder"
  :label="schema.label"
  placeholder="点击选择地区"
  :required="schema.required === true" 
  :rules="[{ required: schema.required === true ,message:'请选择'+schema.label}]"
  @click="onShowAreaPicker()"
/>


<van-popup v-model="showAreaPicker" position="center">
  <van-area
    :value="areaPickerValue"
    :area-list="areaList"
    @confirm="onAreaConfirm"
    @cancel="showAreaPicker = false"
  />
</van-popup>

</div>
</template>

<script>
import areaList from '../../../assets/areaList.js'
// import { Notify } from 'vant';

export default {
  name: 'diyArea',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  data:function(){
    return {
      areaList:areaList,
      areaPickerValue:'',
      showAreaPicker:false,//地区
    }
  },
  methods:{
    onShowAreaPicker:function(){
      this.showAreaPicker = true;
      this.areaPickerValue= this.schema.value;
    },
    onAreaConfirm:function(values){
      var lastValue = '';
     
      this.schema.placeholder = values
        .filter((item) => !!item)
        .map(function(item){ lastValue = item.code ;return item.name;})
        .join(' / ');
      this.schema.value = lastValue;
      this.showAreaPicker = false;
    },
    // onScan(){
    //   Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    // }
  }
}
</script>

<style>

</style>