export default{
    name: 'navBar',
    title: '顶栏',
    text: '我的主页',
    color: '#000000',
    bgColor: '#EF4444',
    transparent:true,
    fixed:true,
    showLeft:true,
    placeholder:true,
    leftIcon:'wap-home-o',
    handle: 'none',
    // handle_message
    message: '',
    // handle_link
    link: '',
    path: '',
    //小程序页面
    appId: '', //小程序appId
    handle_call:{
        phone_number:''
    },
    handle_map:{
        // location
    },
    handle_copy:{
        message: ''
    },
    handle_wifi:{
        ssid:'',
        password:'',
        bssid:''
    }
}