<template>
<div :style="'padding:'+( schema.paddingTB || 5)+'px '+ ( schema.paddingLR || 5)+'px;background:'+ (schema.bgColor || '#f0f9eb') +';text-align:'+ (schema.textAlign || 'center')">
  <van-button 
    :color="schema.color"
    :plain="schema.plain"
    :square="schema.square != 'round'"
    :round="schema.square == 'round'"
    :icon="schema.icon"
    :size="schema.size"
    :text="schema.label"
  ></van-button>
</div>
</template>

<script>
// import { Notify } from 'vant';

export default {
  name: 'submit',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    // onScan(){
    //   Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    // }
  }
}
</script>

<style>

</style>