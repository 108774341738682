<template>
  <el-form
    label-position="right"
    label-width="70px"
  >
    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>


    <el-form-item label="默认值">
      <el-input-number size="small" v-model="currentPlugin.value" :min="1" :max="currentPlugin.count" :step="1"></el-input-number>
    </el-form-item>

    <el-form-item label="属性">
      <el-checkbox v-model="currentPlugin.required" label="必填"  />
      <el-checkbox v-model="currentPlugin.allowHalf" label="允许半星"  />
      <el-checkbox v-model="currentPlugin.readonly" label="只读"  />
      <el-checkbox v-model="currentPlugin.disabled" label="禁用"  />
    </el-form-item>

    <el-divider>其他</el-divider>

  
    <el-form-item label="图标总数">
      <el-input-number size="small" v-model="currentPlugin.count" :min="1" :max="10" :step="1"></el-input-number>
    </el-form-item>

    <el-form-item label="形状">
      <el-radio-group v-model="currentPlugin.icon" size="small">
        <el-radio-button label="star">星形</el-radio-button>
        <el-radio-button label="like">心形</el-radio-button>
        <el-radio-button label="good-job">拇指</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="激活色">
      <el-color-picker v-model="currentPlugin.color" color-format="hex" size="small"></el-color-picker>
    </el-form-item>
    <el-form-item label="默认色">
      <el-color-picker v-model="currentPlugin.voidColor" color-format="hex" size="small"></el-color-picker>
    </el-form-item>


    <template v-if="$store.state.formSettings.multipleForm">
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>


  </el-form>

</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'rate',
  props: {},
  components: {
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
}

</script>


<style scoped>


</style>
