<template>
  <div>
    <el-form
      label-position="right"
      label-width="90px"
    >
      <el-form-item label="图标大小">
        <el-input-number size="small" v-model="currentPlugin.iconSize" :min="20" :max="64" :step="5" ></el-input-number> PX
      </el-form-item>
      
      <el-divider>内容设置</el-divider>
      
        <el-collapse v-model="activeName" accordion >

          <draggable v-model="currentPlugin.qrList"  handle=".mover">
            <el-collapse-item v-for="(item, idx) in currentPlugin.qrList" :key="idx" :name="idx">
              <template slot="title">
                <div :style="{flexGrow:1}">
                  <span class="mover" title="拖动改变顺序"><i class="header-icon el-icon-rank"></i></span> 项目{{idx+1}}
                  <span :style="{float:'right',marginRight:'10px'}">
                    <el-link type="danger" @click.stop="onPluginDelItem(idx)" :underline="false"><i class="el-icon-circle-close" :style="{fontSize:'18px'}"></i></el-link>
                  </span>
                </div>
              </template>
              
              <el-form-item label="关联二维码">
                <el-input placeholder="请选择" v-model="item.qrTitle" prefix-icon="el-icon-menu" class="input-with-select" readonly>
                  <el-button slot="append" @click="onActionLink(idx)">选择</el-button>
                </el-input>
              </el-form-item>

              <el-form-item label="标题">
                <el-input v-model="item.text" placeholder="标题"  clearable/>
              </el-form-item>

              <el-form-item label="描述">
                <el-input v-model="item.label" placeholder="描述信息"  clearable/>
              </el-form-item>

              <el-form-item label="图标">
                <div class="cover-uploader">
                  <div class="el-upload el-upload--text" @click="onUploadImage(idx)">
                    <el-image v-if="item.icon" :src="item.icon" class="cover" fit="contain"></el-image>
                    <i v-else class="el-icon-plus cover-uploader-icon"></i>
                  </div>
                </div>
              </el-form-item>
             
            </el-collapse-item>
          </draggable>

        </el-collapse>

        <div class=" mt-10"><el-button @click="onPluginAddItem" type="success" size="small" icon="el-icon-plus" round>添加项目</el-button></div>
        <el-divider>提示</el-divider>
        <div class="help mt-10">图标：建议图片尺寸64px以内，以确保打开速度</div>

    </el-form>
    <!-- 图片选择 -->
    <image-upload-dialog :visible.sync="visible" source="photo" @confirm="onConfirm"/>
    <!-- 链接选择 -->
    <action-link-dialog :visible.sync="visibleLink" source="photo" @confirm="onConfirmLink"/>
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable';
import ImageUploadDialog from '../../ImageUploadDialog'
import ActionLinkDialog from '../../ActionLinkDialog'
import pluginSchema from '../../../pluginSchema/index'
import _ from 'lodash'


export default {
  name: 'qrPhoto',
  props: {},
  components: {
    draggable,
    ImageUploadDialog,
    ActionLinkDialog
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
      activeName:0,
      visible:false,
      visibleLink:false,
      setImagesIndex:0
    }
  },
  mounted:function(){
    if(!this.currentPlugin.iconSize){
      this.currentPlugin.iconSize = 43
    }
  },
  methods:{
    onUploadImage(index){
      // console.log('onUploadImage')
      this.visible = true;
      this.setImagesIndex = index;
    },
    onActionLink(index){
      // console.log('onUploadImage')
      this.visibleLink = true;
      this.setImagesIndex = index;
    },
    onConfirm(imageData){
      // console.log('imageData',imageData)
      // this.currentPlugin.qrList[this.setImagesIndex].id = imageData.id
      this.currentPlugin.qrList[this.setImagesIndex].icon = imageData.url
      this.currentPlugin.qrList[this.setImagesIndex].filePath = imageData.path
    },
    onConfirmLink(linkData){
      // console.log('linkData',linkData)
      this.currentPlugin.qrList[this.setImagesIndex].qrTitle = linkData.title
      this.currentPlugin.qrList[this.setImagesIndex].qrScene = linkData.qr_scene
    },
    // 添加项目
    onPluginAddItem(){
      const theSchema = pluginSchema.qrPhoto;
      this.currentPlugin.qrList.push(_.cloneDeep( theSchema.qrList[0] ))
    },
    // 删除项目
    onPluginDelItem(index){
      let that = this;
      this.currentPlugin.qrList.splice(index,1);
      if( this.currentPlugin.qrList.length<=0){

        that.$message({
          message: '至少设置一项',
          type: 'warning'
        });

        //全部删除了就添加个默认项目
        setTimeout(function(){
          that.onPluginAddItem();
          
        },100);
      }

    }
   
  }
}

</script>


<style >

</style>
