<template>
<van-field :label="schema.label" :required="schema.required === true">
  <template slot="input">
    <van-rate v-model="schema.value" :icon="schema.icon" :void-icon="schema.icon + '-o'" :count="schema.count" :allow-half="schema.allowHalf" :void-color="schema.voidColor" :color="schema.color"  :disabled="schema.disabled" :readonly="schema.readonly"/>
  </template>
</van-field>
</template>

<script>
// import { Notify } from 'vant';

export default {
  name: 'rate',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    // onScan(){
    //   Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    // }
  }
}
</script>

<style>

</style>