export default {
    name: 'qrWifi',
    title: '扫码连WiFi',
    iconSize:43,
    qrList: [{
        icon: 'https://v.2weima.com/static/images/icon/wifi.png',
        filePath:'',
        text: '扫码连WiFi',
        label: '不夜城WiFi，一键连接',
        qrTitle: '',
        qrScene: ''
    }]
}