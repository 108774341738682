export default {
    name: 'mp3Play',
    title: '音频播放',
    iconSize:43,
    fileList: [{
        icon: 'https://v.2weima.com/static/images/icon/music.png',
        filePath:'',
        text: '圆形面积公式',
        label: '圆形面积公式灵活运用，难点解说',
        attach: {
            id:0,
            name:'',
            path:'',
            size_format:''
        }
    }]
}