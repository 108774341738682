export default {
    name: 'image',
    title: '竖排图',
    images: [{
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/swipe-2.png',
        alt: '',
        filePath:'',
        handle: 'none',
        message: '',
        link: '',
        path: '',
        //小程序页面
        appId: '' //小程序appId
    }],
    paddingTB: 0,
    paddingLR: 0,
}