<template>

<div class="preview" >
    <!-- <div class="phone-bar"></div> -->
    
    <div class="phone-main" id="de-simulator">

        <!-- <nut-divider dashed>状态</nut-divider>
        <div class="block-main">
            <div class="block-title">
                状态
                <div class="block-desc">此状态用于上报维修跟进，请仔细检测填写</div>
            </div>
        </div>
         -->
        <template v-if="pluginList.length <=0">
            <div class="block-main">
                <div class="block-title" style="background:#fbf2cf">
                    空白页面
                    <div class="block-desc">请在左侧选择组件添加到页面</div>
                </div>
            </div>
        </template>
        
        <template v-else>
            <draggable v-model="pluginList" item-key="index">
                <div v-for="( element, pluginIndex) in pluginList" :key="'plugin-'+pluginIndex" :id="'plugin-'+pluginIndex"  :class="'plugin-main plugin-'+element.name + (pluginIndex== currentPluginIndex ?' plugin-active':'')" :style="(pluginIndex==0 && element.name=='navBar') ? 'z-index:100' : ''">

                    <div class="plugin-del_btn">
                      <!-- 一个页面只能添加一个的组件不能copy -->
                        <template v-if="!['submit','formStatus','table'].includes(element.name)">
                          <el-popconfirm 
                            title="确认复制此组件吗？"
                            confirm-button-text="确认"
                            cancel-button-text="取消"
                            icon-color="red"
                            @confirm="onCopyPlugin(pluginIndex)"
                          >
                              <template #reference>
                                  <a href="javascript:;" title="复制"><i class="el-icon-document-copy" style="font-size:20px;color:#409EFF; background-color:#ffffff50; margin-right:5px"></i></a>
                              </template>
                          </el-popconfirm>
                        </template>

                        <el-popconfirm 
                          title="确认删除此组件吗？"
                          confirm-button-text="确认"
                          cancel-button-text="取消"
                          icon-color="red"
                          @confirm="onDelPlugin(pluginIndex)"
                        >
                            <template #reference>
                                <a href="javascript:;" title="删除"><i class="el-icon-circle-close" style="font-size:20px;color:#F56C6C;background-color:#ffffff50; "></i></a>
                            </template>
                        </el-popconfirm>

                    </div>

                    <div @click="onSelectPlugin(pluginIndex)" >
                        <template v-if="RenderPlugins[element.name]">
                            <!-- 动态加载组件 -->
                            <component :is="RenderPlugins[element.name]" :schema="element" :pluginIndex="pluginIndex"></component>
                        </template>
                        <template v-else>
                            <div class="block-title">
                                组件：{{element.name}}
                                <div class="block-desc">请适配此组件，否则无法正常显示</div>
                            </div>
                        </template>
                        
                    </div>
                    
                </div>

            </draggable>

        </template>

    
            

    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable';
import _ from 'lodash'
// import utils from '../utils/utils'
import RenderPlugins from './plugins/index';
// emit ： onSelectPlugin
export default {
  name: 'Simulator',
  props: {
    // msg: String
  },
  components: {
    draggable,
  },
  data:function() {
    return {
     RenderPlugins
    }
  },
  computed:{
    ...mapState(["currentPluginIndex","lastAddPluginCount"]),
    // 需要拖动进行 set
    pluginList:{
        get: function () {
            return this.$store.state.pluginList
        },
        set: function (newValue) {
            this.$store.commit('setPluginList',newValue)
        }
    }
  },
  watch:{
      lastAddPluginCount:function(){
        //   console.log('aaaaa')
        this.scrollToBottom()
      }
  },
  methods:{
    scrollToBottom:function() {
      this.$nextTick(() => {
        var mainContainer = this.$el.querySelector("#de-simulator");
        var idContainer = this.$el.querySelector('#plugin-'+ this.currentPluginIndex);
        setTimeout(function(){
          mainContainer.scrollTop = idContainer.offsetTop;
        },300);
      });
    },
    onSelectPlugin(index){
        this.$store.commit('setCurrentPluginIndex',index)
        this.$emit('onSelectPlugin',index)
    },
    onDelPlugin(index){
        this.$store.commit('removePlugin',index)
    },
    onCopyPlugin(index){
        this.$store.commit('copyPlugin',index)
    },
  }
}
</script>

<style>
/* 模拟手机 */
.preview{
  width:375px;
  height:812px;
  margin: 15px auto;
  border:3px solid #99a9bf;
  border-radius: 10px;
  background:#fff;
}

.phone-bar{
  background: url('../assets/phone-bar375.png');
  border-radius: 10px 10px 0 0;
  height:83px;
  width:375px;
  border-bottom:1px solid #e5e5e5;
}
.phone-main{
  height:812px;
  overflow-y: scroll;
  border-radius: 10px;
  position: relative;
  background: #f7f8fa;
  -ms-scroll-chaining: chained;/* ie */
  -ms-overflow-style: none;
  scrollbar-width: none;/* ff */
}
/* chrome */
.phone-main::-webkit-scrollbar { width:0px; } 



/* .phone-main .nut-form .nut-cell-group__warp{
    margin:0 !important;
    border-radius: 0 !important;
} */


.plugin-main{
	border:1px solid #f5f5f5;
	position: relative;
    cursor:move;
    margin-bottom: -1px;
    /* min-height: 15px; */
}
.plugin-main:last-child{ margin-bottom: 0;}
.plugin-main:first-child{ border-radius: 10px 10px 0 0;}




.plugin-del_btn{
	position: absolute;
	right: 2px;
	top: 2px;
	display: none;
	z-index: 100;
}
.plugin-active{
	border:1px dashed #F56C6C;
    z-index: 1;
}
.plugin-main:hover{
	border:1px dashed #409EFF;
    z-index: 1;
}

.plugin-main:hover .plugin-del_btn{
	display: block;
}
/* .plugin-active .plugin-del_btn{
} */

 .form-title{
    font-size: 16px; 
    color:var(--van-blue);
    padding:10px;
    font-weight: bold;
    border:1px solid #f1f1f1;

}
.form-title:hover{
	border:1px dashed #409EFF;
    z-index: 1;
}

.form-desc{
    font-size: 14px; 
    color: var(--van-gray-6);
    font-weight: 400; 
    margin-top:5px;
}
.block-title{
  margin: 0;
  padding: 16px;
  color:#323233;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background: #e4e7ea;

}
.block-title--required:before {
  margin-right: 2px;
  color: #ee0a24;
  content: "*";
}
.block-desc{
  font-size: 14px;
  color: #969799;
  font-weight: 400;
  margin-top:5px;
}



/* ueditor表格默认样式 */
.table{border-collapse:collapse}.table-bordered td,.table-bordered th{border:1px solid #ddd}
.table-statis{
	color: #666;
}
.table-statis-success{
	color: #07c160;
}
.table-statis-warning{
	color: #ee0a24;
}



/* 参考 nutui table */
.van-table {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 14px;
}
.van-table__main {
  display: table;
  width: 100%;
  border-collapse: collapse;
  overflow-x: hidden;
}
.van-table__main__body {
  display: table-row-group;
}
.van-table__main__head__tr, .van-table__main__body__tr {
  display: table-row;
}
.van-table__main--striped .van-table__main__body__tr:nth-child(odd) {
  background-color: #fff;
}
.van-table__main__head__tr--alignleft, .van-table__main__head__tr--align, .van-table__main__body__tr--alignleft, .van-table__main__body__tr--align {
  text-align: left;
}
.van-table__main__head__tr--border, .van-table__main__body__tr--border {
  border: 1px solid #dadada;
}
.van-table__main__head__tr__th, .van-table__main__body__tr__th, .van-table__main__head__tr__td, .van-table__main__body__tr__td {
  display: table-cell;
  padding: 10px;
}
.van-table__main--striped .van-table__main__body__tr:nth-child(even) {
  background-color: var(--van-gray-1);
}

.van-table .van-field{
padding: 5px !important;
border: 1px solid #999999 !important;
}
.van-table .van-table__summary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  padding: 10px;
}
.van-table .van-table__summary__text{
color: #909ca4;
margin-left: 10px;
}
</style>
