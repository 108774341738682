export default {
    name: 'buttonGroup',
    title: '按钮组',
    columnNum: 3,
    gutter: 8,
    bgColor: '#E3FBE5',
    paddingTB: 8,
    paddingLR: 8,
    square:'square',// square or round
    grids: [{
        text: '按钮一',
        plain: true,
        icon: '',
        color: '#000000',
        handle: 'none',
        message: '',
        link: '',
        path: '',//小程序页面
        appId: '' //小程序appId
    },
    {
        text: '按钮二',
        plain: true,
        icon: '',
        color: '#000000',
        handle: 'none',
        message: '',
        link: '',
        path: '',//小程序页面
        appId: '' //小程序appId
    },
    {
        text: '按钮三',
        plain: true,
        icon: '',
        color: '#000000',
        handle: 'none',
        message: '',
        link: '',
        path: '',//小程序页面
        appId: '' //小程序appId
    }]
}