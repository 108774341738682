export default {
    name: 'title',
    title: '组件标题',
    text: '标题信息',
    textAlign: 'left',
    color: '#000000',
    descColor: '#777777',
    fontSize:14,
    bgColor: '#D6E8F9',
    paddingTB: 10,
    paddingLR: 10,
}