<template>
  
  <el-tabs type="border-card" v-model="$store.state.rightTabName">
    <el-tab-pane label="组件属性" name="plugin">
      <template v-if="!currentPlugin">
        <div class="help">先添加组件到页面，再设置</div>
      </template>
      <template v-else>

          <p style="font-size: 14px"><i class="el-icon-caret-right"></i> {{currentPlugin.title}}</p>
      
          <template v-if="RightSettings[currentPlugin.name]">
            <!-- 动态加载组件 -->
            <component :is="RightSettings[currentPlugin.name]" ></component>
          </template>
          <template v-else>
            <div class="help">请完善设置：{{currentPlugin.name}}</div> 
          </template>

        </template>
    </el-tab-pane>
    <el-tab-pane label="页面设置" name="form">
        <diy-setting-base/>
    </el-tab-pane>
  </el-tabs>

</template>

<script>
import { mapGetters } from 'vuex'
import DiySettingBase from './diySettings/base.vue'
import RightSettings from './rightSettings/index';
export default {
  name: 'AsideRight',
  props: {
    // msg: String
  },
  components: {
    DiySettingBase,
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function() {
    return {
     RightSettings
    }
  },
  
}


</script>


<style>

.set-options{
  border:1px dashed #DCDFE6;
  padding:10px;
  margin-left:2px;
  border-radius: 4px;
}

.cover-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.cover-uploader .el-upload:hover {
  border-color: #409EFF;
}

.cover-uploader .cover {
  max-width: 100%;
  height: 128px;
  display: block;

}
.cover-uploader .cover-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 256px;
    height: 128px;
    line-height: 128px;
    text-align: center;
}


/* collapse */
.mover{
  cursor: move;
  font-size: 18px;
  margin-right: 10px;
  color:#409EFF;
}
.el-collapse-item__header{
  background-color: #e5e9f2 !important;
  padding-left:5px;
}
.el-collapse-item{
  border:1px solid #e5e9f2;
}
.el-collapse-item__content{
  padding: 15px;
}
</style>
