export default {
    name: 'selectPicker',
    title: '下拉选择',
    formName:'default',
    uniqid:'',
    label:'选择学历',
    placeholder:'点击选择学历',
    required: false,
    value:'',
    defaultIndex:0,
    columns:['研究生及以上', '本科', '大专', '高中', '中专及其他'],
}