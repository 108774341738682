<template>
  <div>
    <el-form label-position="right" label-width="90px">

      <el-form-item label="列数">
        <el-input-number size="small" v-model="currentPlugin.columnNum" :min="1" :max="4"></el-input-number> 列
      </el-form-item>

      <el-form-item label="格子间距">
        <el-input-number size="small" v-model="currentPlugin.gutter" :min="0" :max="30"></el-input-number> PX
      </el-form-item>

      <el-form-item label="背景色" v-if="!currentPlugin.transparent">
        <el-color-picker v-model="currentPlugin.bgColor" color-format="hex" size="small"></el-color-picker>
      </el-form-item>

      <el-form-item label="上下边距">
        <el-input-number size="small" v-model="currentPlugin.paddingTB" :min="0" :max="300"></el-input-number> PX
      </el-form-item>

      <el-form-item label="左右边距">
        <el-input-number size="small" v-model="currentPlugin.paddingLR" :min="0" :max="300"></el-input-number> PX
      </el-form-item>

      <el-form-item label="形状">
        <el-radio-group v-model="currentPlugin.square" size="small">
          <el-radio-button label="square">方形</el-radio-button>
          <el-radio-button label="round">圆角</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-divider>内容设置</el-divider>

      <el-collapse v-model="activeName" accordion>

        <draggable v-model="currentPlugin.grids" handle=".mover">
          <el-collapse-item v-for="(item, idx) in currentPlugin.grids" :key="idx" :name="idx">
            <template slot="title">
              <div :style="{ flexGrow: 1 }">
                <span class="mover" title="拖动改变顺序"><i class="header-icon el-icon-rank"></i></span> {{ item.text }}
                <span :style="{ float: 'right', marginRight: '10px' }">
                  <el-link type="danger" @click.stop="onPluginDelItem(idx)" :underline="false"><i
                      class="el-icon-circle-close" :style="{ fontSize: '18px' }"></i></el-link>
                </span>
              </div>
            </template>

            <el-form-item label="标题">
              <el-input v-model="item.text" placeholder="标题" clearable />
            </el-form-item>

            <el-form-item label="按钮色">
              <el-color-picker v-model="item.color" color-format="hex" size="small"></el-color-picker>
            </el-form-item>

            <el-form-item label="按钮图标">
              <van-icon :name="item.icon" size="20" />
              <el-button size="mini" style="margin-left:10px;" @click="onSelectIcon(idx)">选择</el-button>
              <el-button v-if="item.icon != ''" size="mini" style="margin-left:10px;" @click="onDeleteIcon(idx)"
                type="danger">删除</el-button>
            </el-form-item>

            <el-form-item label="点击时">
              <el-button size="mini" style="margin-left:10px;" @click="onHandleSetting(idx)"
                icon="el-icon-s-tools">设置动作</el-button>
            </el-form-item>

          </el-collapse-item>
        </draggable>

      </el-collapse>

      <div class=" mt-10"><el-button @click="onPluginAddItem" type="success" size="small" icon="el-icon-plus"
          round>添加项目</el-button></div>
      <el-divider>提示</el-divider>
      <div class="help mt-10">点击时：默认（为无动作）</div>

    </el-form>
    <icons-dialog :visible.sync="visible" @change="onConfirmIcon" />
    <!-- 链接选择 -->
    <!-- <action-link-dialog :visible.sync="visibleLink" source="diy" :showAll="true" @confirm="onConfirmLink"/> -->
    <!-- 动作设置 -->
    <handle-setting-dialog v-if="visibleHandle" :visible.sync="visibleHandle" :schema="currentSchema" source="diy"
      :showAll="true" @confirm="onConfirmHandle" />
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable';
import IconsDialog from '../../IconsDialog'
// import ActionLinkDialog from '../../ActionLinkDialog'
import HandleSettingDialog from '../../HandleSettingDialog'
import pluginSchema from '../../../pluginSchema/index'
import _ from 'lodash'


export default {
  name: 'buttonGroup',
  props: {},
  components: {
    draggable,
    HandleSettingDialog,
    IconsDialog
  },
  computed: {
    ...mapGetters(['currentPlugin']),
    currentSchema: function () {
      return this.currentPlugin.grids[this.setHandleIndex];
    },
  },
  data: function () {
    return {
      activeName: 0,
      visible: false,
      visibleHandle: false,
      setHandleIndex: 0,
      setIconIndex: 0
    }
  },
  mounted: function () {
    if (!this.currentPlugin.iconSize) {
      this.currentPlugin.iconSize = 43
    }
  },
  methods: {

    onSelectIcon(index) {
      this.visible = true;
      this.setIconIndex = index;
    },
    onDeleteIcon(index) {
      this.currentPlugin.grids[index].icon = '';
    },
    onConfirmIcon(payload) {
      // console.log('payload',payload)
      this.currentPlugin.grids[this.setIconIndex].icon = payload;
    },


    onHandleSetting(index) {
      // console.log('onUploadImage')
      this.visibleHandle = true;
      this.setHandleIndex = index;
    },
    onConfirmHandle(payload) {

      this.currentSchema.handle = payload.handle;
      this.currentSchema.link = payload.link;
      this.currentSchema.path = payload.path;
      this.currentSchema.appId = payload.appId;
      this.currentSchema.jumpType = payload.jumpType;
      this.currentSchema.shortLink = payload.shortLink;

      if (!_.isEmpty(payload.message)) {
        this.currentSchema.message = payload.message;
      }
      if (!_.isEmpty(payload.handle_call)) {
        this.currentSchema.handle_call = payload.handle_call;
      }
      if (!_.isEmpty(payload.handle_map)) {
        this.currentSchema.handle_map = payload.handle_map;
      }
      if (!_.isEmpty(payload.handle_copy)) {
        this.currentSchema.handle_copy = payload.handle_copy;
      }
      if (!_.isEmpty(payload.handle_wifi)) {
        this.currentSchema.handle_wifi = payload.handle_wifi;
      }

    },

    // 添加项目
    onPluginAddItem() {
      const theSchema = pluginSchema.buttonGroup;
      this.currentPlugin.grids.push(_.cloneDeep(theSchema.grids[0]))
    },
    // 删除项目
    onPluginDelItem(index) {
      let that = this;
      this.currentPlugin.grids.splice(index, 1);
      if (this.currentPlugin.grids.length <= 0) {

        that.$message({
          message: '至少设置一项',
          type: 'warning'
        });

        //全部删除了就添加个默认项目
        setTimeout(function () {
          that.onPluginAddItem();

        }, 100);
      }

    }

  }
}

</script>


<style></style>
