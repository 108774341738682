<template>

  <el-card class="plugin-card" shadow="hover">
    <template #header>
      <div class="card-header">
        <span>组件</span>
        <div style="font-size:13px;margin-right:10px; color:#909399">点击组件添加到页面</div>
      </div>
    </template>
    <div class="plugin-buttons">
      <div v-for="(item, index) in leftMenus" :key="'menus-' + index">
        <el-divider content-position="left" border-style="dashed">{{ item.title }}</el-divider>
        <el-row :gutter="5">
          <el-col :span="6" v-for="menu in item.menus" :key="menu.name">
            <el-button type="default" size="large" @click="pluginClick(menu.name)" style="padding: 10px 5px;" plain>
              <div v-if="menu.isVip">
                <el-badge value="vip" class="item">
                  <div class="icon"><i :class="menu.icon"></i></div>
                </el-badge>
              </div>
              <div v-else-if="menu.isNew">
                <el-badge value="New" class="item" type="primary">
                  <div class="icon"><i :class="menu.icon"></i></div>
                </el-badge>
              </div>
              <div v-else class="icon"><i :class="menu.icon"></i></div>
              {{ menu.label }}
            </el-button>
          </el-col>
        </el-row>
      </div>
      <div class="help">注意：使用表单时，记得添加提交按钮</div>

    </div>

  </el-card>

</template>
<script>
import { Message } from 'element-ui';
import pluginSchema from '../pluginSchema/index'
import _ from 'lodash'
import utils from '../utils/utils'

const leftMenus = [
  {
    title: "通用组件",
    menus: [
      {
        name: "navBar",
        label: "顶栏",
        icon: "el-icon-house",
        isVip: false
      },
      {
        name: "noticeBar",
        label: "公告通知",
        icon: "el-icon-bell",
        isVip: false
      },
      {
        name: "swipe",
        label: "轮播图",
        icon: "el-icon-more",
        isVip: false
      },
      {
        name: "navGrid",
        label: "宫格导航",
        icon: "el-icon-menu",
        isVip: false
      },
      {
        name: "image",
        label: "竖排图",
        icon: "el-icon-picture-outline",
        isVip: false
      },
      {
        name: "imageGrid",
        label: "图片橱窗",
        icon: "el-icon-c-scale-to-original",
        isVip: true
      },
      {
        name: "richText",
        label: "富文本",
        icon: "el-icon-data-analysis",
        isVip: false
      },
      {
        name: "title",
        label: "组件标题",
        icon: "el-icon-s-flag",
        isVip: false
      },
      {
        name: "button",
        label: "跳转按钮",
        icon: "el-icon-link",
        isVip: false
      },
      {
        name: "buttonGroup",
        label: "按钮组",
        icon: "el-icon-c-scale-to-original",
        isVip: false,
        isNew: true
      },
      {
        name: "jumpList",
        label: "跳转列表",
        icon: "el-icon-share",
        isVip: false,
        isNew: false
      },
      {
        name: "kefu",
        label: "客服",
        icon: "el-icon-user",
        isVip: false
      },
      {
        name: "kongbai",
        label: "辅助空白",
        icon: "el-icon-full-screen",
        isVip: false
      },
      {
        name: "divider",
        label: "分割线",
        icon: "el-icon-minus",
        isVip: false
      },
      {
        name: "nuoChe",
        label: "挪车",
        icon: "el-icon-phone-outline",
        isVip: false,
        isNew: false
      },
      {
        name: "mp3Play",
        label: "音频播放",
        icon: "el-icon-video-play",
        isVip: true
      },
      {
        name: "mp4Play",
        label: "视频播放",
        icon: "el-icon-video-camera-solid",
        isVip: true
      },
      {
        name: "easyTable",
        label: "简易表格",
        icon: "el-icon-s-grid",
        isVip: false,
        isNew: true
      },
      {
        name: "collapse",
        label: "折叠面板",
        icon: "el-icon-s-operation",
        isVip: false,
        isNew: true
      }

    ]
  },
  {
    title: "内容关联",
    menus: [
      {
        name: "qrMap",
        label: "导航码",
        icon: "el-icon-location-information",
        isVip: false
      },
      {
        name: "qrPdf",
        label: "PDF码",
        icon: "el-icon-files",
        isVip: false
      },
      {
        name: "qrPhoto",
        label: "相册码",
        icon: "el-icon-picture-outline-round",
        isVip: false
      },
      {
        name: "qrVcard",
        label: "名片码",
        icon: "el-icon-postcard",
        isVip: false
      },
      {
        name: "qrWifi",
        label: "WIFI码",
        icon: "el-icon-s-data",
        isVip: false
      },
      {
        name: "qrMusic",
        label: "音频码",
        icon: "el-icon-microphone",
        isVip: false
      },
      {
        name: "qrVideo",
        label: "小视频码",
        icon: "el-icon-video-camera",
        isVip: false
      },
      {
        name: "table",
        label: "数据表",
        desc: "用于导入表格数据制作一物一码",
        icon: "el-icon-coin",
        isVip: true
      }
    ]
  },
  {
    title: "表单组件",
    menus: [
      {
        name: "field",
        label: "文本框",
        icon: "el-icon-edit-outline",
        isVip: false
      },
      {
        name: "checkboxGroup",
        label: "复选组",
        icon: "el-icon-check",
        isVip: false
      },
      {
        name: "radioGroup",
        label: "单选组",
        icon: "el-icon-circle-check",
        isVip: false
      },
      {
        name: "stepper",
        label: "步进器",
        icon: "el-icon-d-arrow-right",
        isVip: false
      },
      {
        name: "switchOff",
        label: "开关",
        icon: "el-icon-turn-off",
        isVip: false
      },
      {
        name: "rate",
        label: "评分",
        icon: "el-icon-star-off",
        isVip: false
      },
      {
        name: "selectPicker",
        label: "下拉选择",
        icon: "el-icon-d-caret",
        isVip: false
      },
      {
        name: "selectJoin",
        label: "级联选择",
        icon: "el-icon-s-operation",
        isVip: false
      },
      {
        name: "area",
        label: "地区选择",
        icon: "el-icon-guide",
        isVip: false
      },
      {
        name: "datetime",
        label: "日期时间",
        icon: "el-icon-date",
        isVip: false
      },
      {
        name: "tableField",
        label: "表格填写",
        icon: "el-icon-notebook-2",
        isVip: false,
        isNew: false
      },
      {
        name: "checkList",
        label: "检查项目",
        icon: "el-icon-finished",
        isVip: false,
        isNew: false
      },
      {
        name: "imgUploader",
        label: "照片上传",
        icon: "el-icon-camera",
        isVip: true
      },
      {
        name: "location",
        label: "定位",
        desc: "用于获取当前GPS定位",
        icon: "el-icon-place",
        isVip: true
      },
      {
        name: "mapMarked",
        label: "标注",
        desc: "用于在地图中设置标注",
        icon: "el-icon-map-location",
        isVip: true
      },
      {
        name: "formData",
        label: "信息展示",
        desc: "用于展示当前页面表单提交的记录信息",
        icon: "el-icon-tickets",
        isVip: true
      },
      {
        name: "formStatus",
        label: "状态",
        icon: "el-icon-set-up",
        isVip: false,
        isNew: false
      },
      {
        name: "submit",
        label: "提交按钮",
        icon: "el-icon-upload2",
        isVip: false
      }
    ]
  }
]

export default {
  name: 'AsideLeft',
  props: {
    // msg: String
  },
  data: function () {
    return {
      leftMenus
    }
  },
  computed: {
    // 找到VIP组件
    vipPluginName: function () {
      let _vipName = []
      for (let i in this.leftMenus) {
        let tmpArr = this.leftMenus[i].menus.filter((item) => item.isVip).map((item) => item.name)
        _vipName.push(...tmpArr)
      }
      return _vipName
    },
    // 提示信息
    vipPluginDesc: function () {
      let _vipDesc = {}
      for (let i in this.leftMenus) {
        let tmpArr = this.leftMenus[i].menus.filter((item) => item.isVip)

        for (let j in tmpArr) {
          if (tmpArr[j].desc) {
            _vipDesc[tmpArr[j].name] = tmpArr[j].desc;
          }
        }
      }
      return _vipDesc
    }
  },
  methods: {
    pluginClick: function (pluginName) {
      // 必须 cloneDeep , 在checkList组件时发现异常
      let schema = _.cloneDeep(pluginSchema[pluginName]);
      if (!schema) {
        return
      }

      if (!this.checkVipPlugin(schema.name)) {

        return false;
      }

      //只能添加一个table组件
      if (schema.name == 'table') {
        if (!this.checkUnPlugin('table', '抱歉，目前一个页面只能添加一个数据表组件')) {
          return false;
        }
      }

      //只能添加一个table组件
      if (schema.name == 'formStatus') {
        if (!this.checkUnPlugin('formStatus', '抱歉，目前一个页面只能添加一个状态组件')) {
          return false;
        }
      }
      // 非多表单，只能添加一个提交按钮
      let multipleForm = this.$store.state.formSettings.multipleForm
      if (!multipleForm && schema.name == 'submit') {
        if (!this.checkUnPlugin('submit', '抱歉，未开启多表单，只能添加一个提交按钮')) {
          return false;
        }
      }
      // uniqid
      schema.uniqid = utils.getUniqid(8);
      //  console.log('schema',schema)
      this.$store.commit('addPluginToPage', schema)
      // emit('pluginClick',schema)
    },
    checkUnPlugin: function (pluginName, message) {
      let pluginList = this.$store.state.pluginList;
      for (var i in pluginList) {
        if (pluginList[i].name == pluginName) {//已存在
          Message({
            message: message,
            type: 'warning'
          })
          return false;
        }
      }
      return true;
    },
    checkVipPlugin: function (pluginName) {
      let that = this;
      // console.log('_pluginNameArr',pluginName,this.vipPluginName)
      if (this.$store.state.config.isVip) {
        return true;
      }
      //非vip检查
      if (this.$store.state.pluginList.length >= 10) {
        Message({
          message: '非会员：一个页面最多可添加10个组件，升级会员可添加全部组件',
          type: 'warning'
        })
        return false;
      }

      // console.log('that.vipPluginDesc',that.vipPluginDesc)
      if (this.vipPluginName.includes(pluginName)) {
        Message({
          message: that.vipPluginDesc[pluginName] ? that.vipPluginDesc[pluginName] + '，升级会员后使用此组件' : '抱歉，请升级会员后使用此组件',
          type: 'warning'
        })
        return false;
      }

      return true;

    }
  }
}


</script>

<style>
.plugin-card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plugin-card .el-card__header,
.plugin-card .el-card__body {
  padding: 10px;
}

.plugin-buttons .el-button {
  width: 100%;
}

.plugin-buttons .el-col {
  margin: 5px 0
}

.plugin-buttons .icon {
  text-align: center;
  margin-bottom: 5px;
  font-size: 20px;
}

.plugin-buttons .el-button {
  padding: 15px 5px;
}
</style>
