<template>
<div>
  <el-form
    label-position="right"
    label-width="70px"
  >
    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>

    <el-form-item label="最多上传">
      <el-input-number size="small" v-model="currentPlugin.maxCount" :min="1" :max="12" :step="1"></el-input-number> 张
    </el-form-item>

    <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.required" label="必填"  />
        <el-checkbox v-model="currentPlugin.isCamera" label="只能拍照" />
        <!-- <el-checkbox v-model="currentPlugin.disabled" label="禁用" /> -->
    </el-form-item>


    <el-divider>其他</el-divider>

    <el-form-item label="预览图">
      <el-input-number size="small" v-model="currentPlugin.previewSize" :min="50" :max="300" :step="5"></el-input-number> PX
    </el-form-item>

    <el-form-item label="上传图标">
      <van-icon :name="currentPlugin.uploadIcon || 'wap-home-o'" size="20"/>
      <el-button size="mini" style="margin-left:10px;" @click="visible=true">选择</el-button>
    </el-form-item>

    <el-form-item label="上传文案">
      <el-input v-model="currentPlugin.uploadText" placeholder="上传文案"  clearable/>
    </el-form-item>
    

    <template v-if="$store.state.formSettings.multipleForm">
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>

  </el-form>

<icons-dialog v-model="currentPlugin.uploadIcon" :visible.sync="visible"/>
</div>

</template>
<script>
import { mapGetters } from 'vuex'
import IconsDialog from '../../IconsDialog'

export default {
  name: 'imgUploader',
  props: {},
  components: {
    IconsDialog
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
      visible:false
    }
  },
}

</script>


<style scoped>


</style>
