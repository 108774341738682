<template>
  <div>
  
    <el-form
      label-position="right"
      label-width="90px"
    >
      <el-divider>车牌号码</el-divider>
      <el-form-item label="归属地">
        <el-popover
          width="400"
          trigger="click"
          v-model="popProvince"
        >
          
          <el-divider>省</el-divider>
          <el-button-group class="pop-province">
            <el-button 
            v-for="(item,index) in provinceOptions"
            :key="index"
            :type="item== currentPlugin.car.province ? 'primary':'default'" 
            @click="currentPlugin.car.province=item"
            >{{item}}</el-button>
          </el-button-group>

          <el-divider>地区</el-divider>

          <el-button-group class="pop-province">
            <el-button 
            v-for="(item,index) in azOptions"
            :key="index"
            :type="item== currentPlugin.car.az ? 'primary':'default'" 
            @click="currentPlugin.car.az=item;popProvince=false"
            >{{item}}</el-button>
          </el-button-group>




          <el-button slot="reference">归属地：{{currentPlugin.car.province}} {{currentPlugin.car.az}}</el-button>
        </el-popover>
      </el-form-item>


      
      <el-form-item label="车牌号码">
        <el-input v-model="currentPlugin.car.number" placeholder="请输入车牌号码" :maxlength="6" clearable/>
      </el-form-item>
      <el-form-item label="文字色">
        <el-color-picker v-model="currentPlugin.car.color" color-format="hex" size="small"></el-color-picker>
      </el-form-item>
      <el-form-item label="背景色">
        <el-color-picker v-model="currentPlugin.car.bgColor" color-format="hex" size="small"></el-color-picker>
      </el-form-item>

      <el-divider>临停提示</el-divider>

      <el-form-item label="临停提示">
        <el-input v-model="currentPlugin.tips.text" placeholder="请输入临停提示"  clearable/>
      </el-form-item>
      <el-form-item label="提示大小">
        <el-input-number size="small" v-model="currentPlugin.tips.fontSize" :min="12" :max="60"></el-input-number> PX
      </el-form-item>
      <el-form-item label="提示色">
        <el-color-picker v-model="currentPlugin.tips.color" color-format="hex" size="small"></el-color-picker>
      </el-form-item>


      <el-divider>属性</el-divider>

      <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.isTel" label="显示电话"/>
        <el-checkbox v-model="currentPlugin.isWechat" label="显示微信"/>
      </el-form-item>
      
      <div v-if="currentPlugin.isWechat">
        <div v-for="(item, idx) in currentPlugin.wechat" :key="idx">
          <el-form-item label="微信二维码">
            <div class="cover-uploader">
              <div class="el-upload el-upload--text" @click="onUploadImage(idx)">
                <el-image v-if="item.id" :src="item.url" class="cover" fit="contain"></el-image>
                <i v-else class="el-icon-plus cover-uploader-icon"></i>
              </div>
            </div>
          </el-form-item>
        </div>
        
      </div>
      <div v-if="currentPlugin.isTel">
        <el-divider>电话设置</el-divider>
        <el-collapse v-model="activeName" accordion >

          <draggable v-model="currentPlugin.cells"  handle=".mover">
            <el-collapse-item v-for="(item, idx) in currentPlugin.cells" :key="idx" :name="idx">
              <template slot="title">
                <div :style="{flexGrow:1}">
                  <span class="mover" title="拖动改变顺序"><i class="header-icon el-icon-rank"></i></span> 客服{{idx+1}}
                  <span :style="{float:'right',marginRight:'10px'}">
                    <el-link type="danger" @click.stop="onPluginDelItem(idx)" :underline="false"><i class="el-icon-circle-close" :style="{fontSize:'18px'}"></i></el-link>
                  </span>
                </div>
              </template>

              <el-form-item label="标题">
                <el-input v-model="item.text" placeholder="如：售前客服"  clearable/>
              </el-form-item>
              <el-form-item label="电话号码">
                <el-input v-model="item.tel" placeholder="电话号码"  clearable/>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="item.label" placeholder="描述"  clearable/>
              </el-form-item>
              
              
            </el-collapse-item>
          </draggable>

        </el-collapse>

        <div class=" mt-10"><el-button @click="onPluginAddItem" type="success" size="small" icon="el-icon-plus" round>添加电话</el-button></div>

      </div>
 
    </el-form>

    
    <el-divider>提示</el-divider>

    <div class="help mt-10">
      号码隐私保护：暂不支持，原因：以前防抄号有一定作用，现手机号码泄露途径太多，号码保护反而让一部分车主更加恶意停车
    </div>


    <!-- 图片选择 -->
    <image-upload-dialog :visible.sync="visible" source="nuoChe" @confirm="onConfirm"/>
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable';
import ImageUploadDialog from '../../ImageUploadDialog'
import pluginSchema from '../../../pluginSchema/index'
import _ from 'lodash'


export default {
  name: 'nuoChe',
  props: {},
  components: {
    draggable,
    ImageUploadDialog
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
      activeName:0,
      visible:false,
      setImagesIndex:0,
      popProvince:false,
      provinceOptions:["京", "津", "冀", "晋", "蒙", "辽", "吉", "黑", "沪","苏", "浙", "皖", "闽", "赣", "鲁", "豫", "鄂", "湘","粤", "桂", "琼", "渝", "川", "贵", "云", "藏", "陕", "甘", "青", "宁", "新"],
      azOptions:["A","B","C","D","E","F","G","H","J","K","L","M","N","P","Q","R","S","T","U","V","W","X","Y","Z"]
    }
  },
  methods:{
    onUploadImage(index){
      // console.log('onUploadImage')
      this.visible = true;
      this.setImagesIndex = index;
    },
    onConfirm(imageData){
      //  console.log('imageData',imageData)
      this.currentPlugin.wechat[this.setImagesIndex].id = imageData.id
      this.currentPlugin.wechat[this.setImagesIndex].url = imageData.url
      this.currentPlugin.wechat[this.setImagesIndex].alt = imageData.name
      this.currentPlugin.wechat[this.setImagesIndex].filePath = imageData.path
    },
    // 添加项目
    onPluginAddItem(){
      const theSchema = pluginSchema.nuoChe;
      this.currentPlugin.cells.push(_.cloneDeep( theSchema.cells[0] ))
    },
    // 删除项目
    onPluginDelItem(index){
      let that = this;
      this.currentPlugin.cells.splice(index,1);
      if( this.currentPlugin.cells.length<=0){

        that.$message({
          message: '至少设置一项',
          type: 'warning'
        });

        //全部删除了就添加个默认项目
        setTimeout(function(){
          that.onPluginAddItem();
          
        },100);
      }

    }
   
  }
}

</script>


<style >
.pop-province .el-button{
  margin:2px 0;
}
</style>
