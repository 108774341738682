<template>
<div :style="'padding-top:'+schema.paddingTB+'px;'">

  <div v-for="(item, idx) in schema.fileList" :key="idx" style="margin-bottom:10px;">
    <div class="video-cover">
      <img  class="video-image" v-lazy="item.icon"/>
      <div class="video-play" ><van-icon name="play-circle-o"  color="#fff" size="45" /></div>
    </div>
    <div class="video-title">
      <van-icon name="arrow" />{{ item.text }}
    </div>
    <div class="video-desc">{{ item.label }}</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'mp4Play',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>
.video-cover{
  position: relative;
  width: 100%;
  height: 100%;
}
.video-play{
  position:absolute;
  bottom:5px;
  right: 5px;
  background-color: rgba(0,0,0,0.4);
  border-radius: 45px;
  padding:2px;
}
.video-image{
  max-width:100%; width:auto;margin:0 auto; display: block;
}
.video-title{
  font-size: 14px;
  margin-top:5px;
}
.video-desc{
  font-size: 14px;
  padding:5px 0;
  padding-left:15px;
  color:#969799;
}
</style>