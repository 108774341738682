<template>
<div :style="'margin:' + schema.gutter + 'px;margin-bottom:0;'">
  <template v-if="schema.isCollage && schema.grids.length>=3">
    <!-- 拼贴 -->

    <van-row type="flex" :gutter="schema.gutter" justify="center" v-if="schema.grids.length>=4">
      <van-col span="12">
        <img
          class="grid-image"
          v-lazy="schema.grids[0].url"
        />
      </van-col>
      <van-col span="12">
        <van-col span="24" :style="'margin-bottom:'+schema.gutter+'px'">
          <img
            class="grid-image"
            fit="fill"
            v-lazy="schema.grids[1].url"
          />
        </van-col>
        <van-col span="24">
          
          <van-col span="12">
            <img
              class="grid-image"
              fit="fill"
              v-lazy="schema.grids[2].url"
            />
          </van-col>
          <van-col span="12">
            <img
              class="grid-image"
              fit="fill"
              v-lazy="schema.grids[3].url"
            />
          </van-col>

        </van-col>
      </van-col>
    </van-row>

    <van-row type="flex" :gutter="schema.gutter" justify="center" v-if="schema.grids.length==3">
      <van-col span="12">
        <img
          class="grid-image"
          fit="fill"
          v-lazy="schema.grids[0].url"
        />
      </van-col>
      <van-col span="12">
        <van-col span="24" :style="'margin-bottom:'+schema.gutter+'px'">
          <img
            class="grid-image"
            fit="fill"
            v-lazy="schema.grids[1].url"
          />
        </van-col>
        <van-col span="24">
          <img
              class="grid-image"
              fit="fill"
              v-lazy="schema.grids[2].url"
            />
        </van-col>
      </van-col>
    </van-row>

    
  </template>
  <template v-else>
    <!-- 非拼贴 -->
    <van-row :gutter="schema.gutter">
      <van-col :span="(24/schema.columnNum)" v-for="(item, idx) in schema.grids" :key="idx">
          <img
            class="grid-image"
            :style="{marginBottom:schema.gutter+'px'}"
            fit="fill"
            v-lazy="item.url"
          />
      </van-col>
    </van-row> 
  </template>

</div>
</template>

<script>
export default {
  name: 'imageGrid',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>
.grid-image{
  height:100%;
  width:100%;
  display: block;
}
</style>