<template>
<van-cell-group>
  <van-cell 
  v-for="(item, idx) in schema.qrList" :key="idx" 
  :title="item.text" :value="item.rightText" :label="item.label" :icon="item.icon" is-link>
    <div slot="icon" class="qr-type-icon">
      <van-icon :name="item.icon" :size="schema.iconSize || 20" />
    </div>
  </van-cell>
</van-cell-group>
</template>

<script>
export default {
  name: 'qrPhoto',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    
  }
}
</script>

<style>
  .qr-type-icon{
    margin-right:8px;
    margin-top:0px;
  }
</style>