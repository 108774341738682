export default {
    name: 'qrPhoto',
    title: '相册',
    iconSize:43,
    qrList: [{
        icon: 'https://v.2weima.com/static/images/icon/photo.png',
        filePath:'',
        text: '儿童成长照片',
        label: '某儿童3岁生日庆祝照片集',
        qrTitle: '',
        qrScene: ''
    }]
}