export default {
    name: 'navGrid',
    title: '宫格导航',
    columnNum: 3,
    gutter: 0,
    iconSize: 64,
    grids: [{
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/grid-1.png',
        alt: '',
        filePath:'',
        text: '宫格名称',
        handle: 'none',
        message: '',
        link: '',
        path: '',
        //小程序页面
        appId: '' //小程序appId
    },
    {
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/grid-2.png',
        alt: '',
        filePath:'',
        text: '景点',
        handle: 'none',
        message: '',
        link: '',
        path: '',
        //小程序页面
        appId: '' //小程序appId
    },
    {
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/grid-3.png',
        alt: '',
        filePath:'',
        text: '特产',
        handle: 'none',
        message: '',
        link: '',
        path: '',
        //小程序页面
        appId: '' //小程序appId
    },
    ],
}