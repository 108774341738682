<template>
<div>
  <el-form
    label-position="right"
    label-width="90px"
  >

    <el-form-item label="绑定数据表">
      <el-button type="success" icon="el-icon-coin" @click="onSelectTable()" plain>选择数据表</el-button>
    </el-form-item>

    <el-form-item label="显示字段"  v-if="currentPlugin.tableScene !=''" >
      <el-select v-model="tableSelectColumns" @change="selectColumnsChange" multiple placeholder="请选择要显示字段" clearable>
        <el-option
          v-for="(item,idx) in currentPlugin.tableFields"
          :key="'field-'+idx"
          :label="item.label + '（' + item.type + '）'"
          :value="idx">
        </el-option>
      </el-select>
    </el-form-item>

    
   

    <template v-if="currentPlugin.tableScene !=''" >

      <el-divider>显示设置</el-divider>


      <el-form-item label="表名称">
        <el-input v-model="currentPlugin.label" placeholder="绑定表名称"  clearable/>
      </el-form-item>


      <el-form-item label="统计">
        <el-radio-group v-model="currentPlugin.isShowStatis" size="small">
          <el-radio-button label="none">不显示</el-radio-button>
          <el-radio-button label="tongji">显示统计</el-radio-button>
          <el-radio-button label="fangwei">防伪统计</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="显示方式">
        <el-radio-group v-model="currentPlugin.isShowMode" size="small">
          <el-radio-button label="table">表格</el-radio-button>
          <el-radio-button label="accordion">手风琴</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="上下边距">
        <el-input-number size="small" v-model="currentPlugin.paddingTB" :min="0" :max="300"></el-input-number> PX
      </el-form-item>

      <el-form-item label="左右边距">
        <el-input-number size="small" v-model="currentPlugin.paddingLR" :min="0" :max="300"></el-input-number> PX
      </el-form-item>


      <template v-if="currentPlugin.isShowMode =='table'" >
        
        <el-form-item label="左边列宽度">
          <el-input-number size="small" v-model="currentPlugin.leftWidth" :min="10" :max="80"></el-input-number> %
        </el-form-item>

        <el-form-item label="文字色">
          <el-color-picker v-model="currentPlugin.color" color-format="hex" size="small"></el-color-picker>
        </el-form-item>
        <el-form-item label="边框色">
          <el-color-picker v-model="currentPlugin.borderColor" color-format="hex" size="small"></el-color-picker>
        </el-form-item>
      </template>



    </template>
  
   

  </el-form>
  <!-- 链接选择 -->
  <action-link-dialog :visible.sync="visibleLink" source="table" @confirm="onConfirmLink"/>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ActionLinkDialog from '../../ActionLinkDialog'
import _ from 'lodash'


export default {
  name: 'diyTable',
  props: {},
  components: {
    ActionLinkDialog
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
      visibleLink:false,
      tableSelectColumns:[]
    }
  },
  mounted:function(){
    // console.log('mounted-111')
    this.initSelectColumns();
  },
  watch:{
    currentPlugin:{
        handler(new_value,old_value){
          // 相同类型切换不更新编辑器
          if(new_value.uniqid != old_value.uniqid){
            this.initSelectColumns();
          }
        },
        deep: true
    }
  },
  methods:{
    onSelectTable(){
      // console.log('onUploadImage')
      this.visibleLink = true;
    },
    onConfirmLink(linkData){
      // console.log('linkData',linkData)
      this.currentPlugin.label = linkData.title
      this.currentPlugin.tableScene = linkData.ta_scene
      this.currentPlugin.tableFields = linkData.data_json
      this.currentPlugin.fields = []//linkData.data_json
      //  console.log('this.currentPlugin.fields',this.currentPlugin.fields)
    },
    // 检查恢复选中字段信息
    initSelectColumns(){
      let _fieldsUniqidArr = this.currentPlugin.fields.map((item)=>item.column)
      let _tableSelectColumns = [];
      if(!_.isEmpty(this.currentPlugin.fields)){
        this.currentPlugin.tableFields.forEach((item,index)=>{
          if(_.includes(_fieldsUniqidArr,item.column)){
            _tableSelectColumns.push(index)
          }
        })
        this.tableSelectColumns = _tableSelectColumns
      }
    },
    selectColumnsChange(indexES){
      let _fields = []
      indexES.forEach(index => {
       _fields.push(this.currentPlugin.tableFields[index])
      });

      this.currentPlugin.fields = _fields

    //  console.log('this.currentPlugin.fields',this.currentPlugin.fields)
    }
   
  }
}

</script>


<style scoped>


</style>
