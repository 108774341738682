<template>
<div>
  <div :class="'block-title' + (schema.required?' block-title--required':'')">
    {{ schema.label }}
    <div class="block-desc">{{schema.desc}}</div>
    <div class="block-desc" style="font-size:12px; text-align:right">
      <van-icon name="minus" />无状态 <van-icon name="cross" />异常 <van-icon name="success" />正常
    </div>
  </div>

  <van-cell v-for="(item, idx) in schema.items" :key="idx" :title="item.label" :label="item.desc">
    <template #right-icon>
  
      <van-radio-group v-model="item.status"  direction="horizontal">

        <van-radio name="none" icon-size="20" icon-name="check-normal" icon-active-name="check-normal">
          <template #icon="props">
            <van-icon name="minus" class="check-icon_default" v-if="!props.checked" />
            <van-icon name="minus" class="check-icon_minus" v-if="props.checked"/>
          </template>
        </van-radio>
        
        <van-radio name="no" icon-size="20" icon-name="circle-close" icon-active-name="circle-close">
          <template #icon="props">
            <van-icon name="cross" class="check-icon_default" v-if="!props.checked" />
            <van-icon name="cross" class="check-icon_cross" v-if="props.checked"/>
          </template>
        </van-radio>
        
        <van-radio name="yes" icon-size="20" icon-name="success" icon-active-name="success">
          <template #icon="props">
            <van-icon name="success" class="check-icon_default" v-if="!props.checked" />
            <van-icon name="success" class="check-icon_success" v-if="props.checked"/>
          </template>
        </van-radio>
      </van-radio-group>
    </template>
  </van-cell>
</div>
</template>

<script>
// import _ from 'lodash'
export default {
  name: 'checkList',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    
  }
}
</script>

<style>
.check-icon_default{
  color: #969799 !important; 
  background-color: #fff !important; 
  border-color: #969799 !important; 
  line-height: 1.2em !important;
}

.check-icon_minus{
  color: #fff !important; 
  background-color: #ff976a !important; 
  border-color: #ff976a !important; 
  line-height: 1.2em !important;
}


.check-icon_cross{
  color: #fff !important; 
  background-color: #ee0a24 !important; 
  border-color: #ee0a24 !important; 
  line-height: 1.2em !important;
}

.check-icon_success{
  color: #fff !important; 
  background-color: #07c160 !important; 
  border-color: #07c160 !important; 
  line-height: 1.2em !important;
}

</style>