<template>
  <van-grid :column-num="schema.columnNum" :gutter="schema.gutter" >
    <van-grid-item :text="item.text" v-for="(item, idx) in schema.grids" :key="idx">
      <van-icon slot="icon" :name="item.url" :size="schema.iconSize" style="margin-bottom: 5px;" />
    </van-grid-item>
  </van-grid>
</template>

<script>
export default {
  name: 'navGrid',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>


</style>