<template>
  <el-dialog title="选择文件" :visible="visible" width="95%" class="image-dialog" @close="onCancel">

    <el-tabs v-model="tabsValue" @tab-click="onFileSelectTabsChange" type="border-card">
      <el-tab-pane label="我的文件" name="myFile">
        <span slot="label"><i class="el-icon-files"></i> 我的文件</span>

        <el-form :inline="true" :model="searchForm">
          <el-form-item label="关键词">
            <el-input v-model="searchForm.kwd" placeholder="搜索文件名"></el-input>
          </el-form-item>
          <el-form-item label="选择分类">
            <el-cascader
              v-model="searchForm.catIds"
              :options="catTreeData"
              :props="cascaderProps"
              clearable
            ></el-cascader>
              
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="fileSelectLoad('search')">查询</el-button>
          </el-form-item>
        </el-form>


        <el-table
          ref="singleTable"
          :data="listData"
          highlight-current-row
          @current-change="onFileSelect"
          style="width: 100%;margin:10px 0"
          >
           <el-table-column
            prop="id"
            label="选择"
            width="80">
             <template slot-scope="scope">
              <el-radio :label="scope.row.id" v-model="selectId"></el-radio>
            </template>
          </el-table-column>
          
          <el-table-column
            prop="name"
            label="文件名"
          >
          </el-table-column>
          <el-table-column
            prop="size_format"
            label="大小"
            width="120">
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="上传时间"
            width="180">
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="120">
             <template slot-scope="scope">
              <el-tag v-if="scope.row.status === 0" type="primary" disable-transitions>待审核</el-tag>
              <el-tag v-else-if="scope.row.status === 1" type="success" disable-transitions>正常</el-tag>
              <el-tag v-else type="error" disable-transitions>异常 {{ scope.row.status_msg }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button @click.stop="onFileEdit(scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click.stop="onFileDelete(scope.row)"  type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
          
        </el-table>

        <div class="text-center mt-10" v-if="isNext">
          <i v-if="loading" class="el-icon-loading" style="font-size:18px"></i>
          <el-button v-else  @click="fileSelectLoad('more')" type="primary" icon="el-icon-refresh-right" style="width:200px" plain>加载更多...</el-button>
        </div>
        
        
        <!-- <el-row :gutter="10" style="max-height:600px;overflow-y:scroll">
          <el-col :span="4" v-for="(item,index) in listData" :key="item.id" style="margin-bottom:10px;">
            <el-card :body-style="{ padding: '0px' }"  shadow="hover" class="image-card" >
              <img :src="item.url" class="image" @click="onFileSelect(index)">
              <div class="image-name">
                {{item.name}}
              </div>
        
              <i v-if="index == selectIndex" class="el-icon-circle-check check"></i>
              <i class="el-icon-circle-close delete" @click.stop="onFileSelectDelete(index)"></i>
            </el-card>
          </el-col>
          <el-col :span="24">
            <div class="text-center mt-10" v-if="isNext">
              <i v-if="loading" class="el-icon-loading" style="font-size:18px"></i>
              <el-button v-else  @click="fileSelectLoad('more')" type="primary" icon="el-icon-refresh-right" style="width:200px" plain>加载更多...</el-button>
            </div>
          </el-col>
        </el-row> -->


      </el-tab-pane>
      <el-tab-pane label="上传" name="upload">
        <span slot="label"><i class="el-icon-upload"></i> 上传</span>

        <el-form :inline="true">
          <template v-if="!catAdd.isActive">
            <el-form-item label="上传到分类">
              <el-cascader
                v-model="upload.uploadForm.catIds"
                :options="catTreeData"
                :props="cascaderProps"
                @change="onUploadCatChange"
                placeholder="你要上传到那个分类？"
                clearable
              ></el-cascader>
                
            </el-form-item>
            <el-form-item label="">
              <el-button @click="catAdd.isActive=true;">添加新分类</el-button>
            </el-form-item>
          </template>
          <template v-else>

            <el-form-item label="上级分类">
              <el-cascader
                v-model="upload.uploadForm.catIds"
                :options="catTreeData"
                :props="cascaderProps"
                @change="onUploadCatChange"
                placeholder="选择上级分类"
                clearable
              ></el-cascader>
                
            </el-form-item>
            <el-form-item label="新分类名称">
              <el-input v-model="catAdd.catName" placeholder="请输入分类名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onImageCatAdd">确定添加</el-button>
              <el-button type="default" @click="catAdd.isActive=false">取消</el-button>
            </el-form-item>

          </template>
        </el-form>

        <el-upload
          ref="uploadRef"
          class="image-uploader"
          name="file"
          list-type="picture"
          :accept="'.'+ uploadExt"
          :action="upload.action"
          :headers="upload.headers"
          :data="upload.data"
          :with-credentials="true"
          :before-upload="onImageUploadBefore"
          :before-remove="onImageRemoveBefore"
          :on-change="onImageUploadChange"
          drag
          multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip">支持 {{ Math.ceil(fileMaxUploadSize/1024) }} M 内， {{ uploadExt }} 文件，升级会员获取更大权限</div>
          <div class="el-upload__tip" slot="tip">
            <p>若超出文件大小，请先进行压缩，<el-link type="primary" href="https://www.baidu.com/s?wd=pdf%E5%9C%A8%E7%BA%BF%E5%8E%8B%E7%BC%A9" target="_blank">PDF压缩</el-link>，MP3、MP4 <el-link type="primary" href="http://www.pcfreetime.com/formatfactory/CN/index.html" target="_blank">用格式工厂压缩</el-link></p>
            <p>请上传原创文件，有水印图或版权文件将被删除并自行承担责任</p>
          </div>
        </el-upload>

      </el-tab-pane>
    </el-tabs>

    

    

    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </span>
    
  </el-dialog>
</template>
<script>
import utils from "../utils/utils"


//分类处理
let _catTreeData = [], _parentCatData = config.parentCatData;
//转格式适配
_catTreeData = utils.toTree(_parentCatData)
if(_catTreeData.length<=0){
  _catTreeData.push({
      id:0, 
      pid:0, 
      cat_name:"默认分类"
  });
}
// console.log('_catTreeData',_catTreeData)


export default {
  name: 'FileUploadDialog',
  props: {
    visible: {
      type:Boolean,
      default:false
    },
    source:{
      type:String,
      default:""
    }
  },
  computed:{
    uploadExt:function(){
      if(this.source=='music'){
        return 'mp3'
      }else if(this.source=='video'){
        return 'mp4'
      }else if(this.source=='pdf'){
        return 'pdf'
      }
    }
  },
  data:function(){
    return {
      tabsValue:"myFile",
      // selectIndex:null,//选中,目前仅单选
      selectId:0,//选中ID
      selectData:null,//选中信息
      searchForm: {
        kwd: '',
        catIds: []
      },
      catTreeData: _catTreeData,
      cascaderProps:{
        value:"id",
        label:"cat_name",
        children:"_child",
        checkStrictly: true
      },
      pageIndex:0,
      loading:true,
      isNext:true,
      listData:[
        // {
        //   id:12,
        //   url:'http://zbcms.2weima.com/static/images/h5editor/image-placeholder.jpg'
        // }
      ],
      fileMaxUploadSize:config.fileMaxUploadSize,
      upload:{
        action:config.uploadUrl,
        headers:{},
        data:{
          cat_id: 0,
          file_type:"file",
          source: this.source //'diy'
        },
        fileList:[],
        uploadForm: {
          catIds: []
        }
      },
      catAdd:{
        isActive:false,
        catName:''
      }
    }
  },
  watch:{
    visible:function(val){
      // onLoad 打开且listData为空
      if(val && this.listData.length==0){
        //  console.log('onLoad',val)
        this.fileSelectLoad('init')

      }
    }
  },
  methods:{
    onCancel(){
      this.$emit('update:visible', false)
    },
    onConfirm(){
      if(this.selectData ==null || _.isEmpty(this.selectData)){
        //什么都不做
        this.$emit('update:visible', false)
        return ;
      }

      this.$emit('update:visible', false)
      this.$emit('confirm',_.clone(this.selectData))
    },
    //选择图片
    onFileSelect(item){
    //  console.log('onFileSelect',item);

      this.selectData = item;
      this.selectId = item.id;
      // this.selectIndex = index;
    },
    fileSelectRemove(attId,callback){
      var that = this;
 
      if(!attId){
        return;
      }

      // console.log('imageRemove',attId)
      utils.request(config.fileDeletetUrl,{
        id:attId,
      },function(res){
        // console.log(res);
        if(typeof callback =="function"){
          callback(res);
        }
        
      });


  
    },
    onFileDelete(item){
      var that = this;

      // console.log('onFileSelectDelete')
      this.$confirm('您确定要删除这个文件吗?', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      
        that.fileSelectRemove(item.id,function(res){
          if(res.code!=1){
            that.$message({
              type: 'info',
              message: res.msg || "操作错误，请重试"
            });
            return;
          }
          // success
          let _index = that.listData.findIndex((element)=>element.id==item.id)
          if(_index>-1){
            that.listData.splice(_index,1);
          }

        });


      }).catch(() => {
               
      });

    },
    onFileEdit(item){
      var that = this;
      // console.log('item',item)

      this.$prompt('文件名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue:item.name,
        inputValidator:function(value){
          return !_.isEmpty(value)
        },
        inputErrorMessage: '请输入文件名'
      }).then(({ value }) => {

        utils.request(config.fileEditUrl,{
          id:item.id,
          file_name:value
        },function(res){
          
          if(res.code !=1){
            return;
          }
          //success
          let _index = that.listData.findIndex((element)=>element.id==item.id)
          if(_index>-1){
            that.listData[_index].name=value;
          }
          
        });
        
        
      }).catch(() => {
           
      });

    },
    fileSelectLoad(loadType){
      var that = this;

      if(loadType =='init'){
        this.searchForm.kwd = '';
        this.searchForm.catIds = 0;
      }
      if(loadType =='search' || loadType =='init'){
        this.pageIndex = 0;//重新计算
        this.listData = [];
        this.selectIndex = null;
      }

      this.loading = true;

      utils.request(config.fileListUrl,{
        op:"load_list",
        source: this.source,//"diy_" + 
        cat_id: _.last(this.searchForm.catIds) || 0, 
        kwd:this.searchForm.kwd , 
        page_index : this.pageIndex+1
      },function(res){

          // console.log('request call',res);
        
        if(res.code !=1){
          that.onCancel();
        }else{
          that.loading = false;//加载状态
          that.pageIndex +=1;
          that.isNext = res.is_next==1;
          for(var i in res.data){
            that.listData.push(res.data[i]);
          }
        }

      });
    },
    onImageUploadBefore(file){
      // console.log('onImageUploadBefore',file); audio/mpeg

      let isFile = false;
      if(this.uploadExt==='mp3'){
        isFile = file.type === 'audio/mpeg' && utils.getFileExt(file.name) === 'mp3';
      }else if(this.uploadExt==='mp4'){
        isFile = file.type ==='video/mp4' && utils.getFileExt(file.name) === 'mp4';
      }else if(this.uploadExt==='pdf'){
        isFile = file.type ==='application/pdf' && utils.getFileExt(file.name) === 'pdf';
      }
      
   
      if (!isFile) {
        this.$message({
          type: 'error',
          message:"请上传 "+ this.uploadExt +" 格式文件"
        });
      }
      const isLtMax = file.size / 1024  < this.fileMaxUploadSize; //kb
      // console.log('isLtMax',isLtMax,file.size / 1024,this.fileMaxUploadSize)
  
      if (!isLtMax) {
        this.$message({
          type: 'error',
          message:"只能上传 "+ Math.ceil(this.fileMaxUploadSize/1024) +"MB 内的文件，请压缩后再上传"
        });
      }
      return isFile && isLtMax;
      // return false;
    },
    onImageRemoveBefore(file, fileList){
      let that = this;

      // console.log(file.response)
      // 没上传的直接删除
      if(_.isEmpty(file.response) || file.response.code!=1){
        return true;
      }

      // console.log('file.response')

      // 已上传的通知API
      return new Promise((resolve, reject) => {
        // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        
        that.fileSelectRemove(file.response.data.id,function(res){
          
          if(res.code==1){
            resolve(true)// to then
          }else{
            reject(res.msg) // to catch
          }
          
        });
        
      })

    },
    onImageUploadChange(file, fileList){
      // 上传成功时
      this.upload.fileList = fileList
      // console.log('fileList',fileList)

      // 当全部上传完，自动切换到我的文件
      let isAllSuccess = true;
      fileList.forEach(file => {
        if(file.status!= "success"){
          isAllSuccess = false;
        }
      });
      if(isAllSuccess){
        this.tabsValue="myFile"
        this.onFileSelectTabsChange({name:"myFile"})
      }
    },
    onFileSelectTabsChange({name}){
      var that = this;
      if(name=='myFile'){//切换我的文件时 把新上传的放到列表
        // console.log(this.imageSelect.upload.fileList)
        let _upload_fileList = this.upload.fileList;
        if(!_.isEmpty(_upload_fileList)){
          // _upload_fileList.forEach(file => {
          //   if(file.status== "success" && !_.isEmpty(file.response) && file.response.code==1){
          //     that.listData.unshift(file.response.data);
          //   }
          // });

          this.upload.fileList = [];
          //重新加载列表
          this.fileSelectLoad('init')
        }

        // uploadRef 清空已上传的文件列表
        this.$refs.uploadRef.clearFiles()

      }

    },
    // 选择分类处理
    onUploadCatChange(catIds){
      this.upload.data.cat_id = _.last(catIds) || 0
      console.log(this.upload.uploadForm.catIds)
    },
    // 添加分类
    onImageCatAdd(){
      let that = this;
      // console.log(imageSelect.upload.data.cat_id,this.imageSelect.catAdd.catName)

      let _formData = {
        pid:this.upload.data.cat_id,
        cat_name:this.catAdd.catName,
      };
      utils.request(config.catAddUrl,_formData,function(res){
        console.log(res);

        if(res.code==1){
          let _new_id = parseInt(res.new_id)
          let _temp = []
          for(let i in res.data){
            _temp.push(res.data[i])
          }
          that.catTreeData = _temp;
          // console.log('that.catTreeData',that.upload.uploadForm.catIds)
       
          that.upload.uploadForm.catIds.push(_new_id);
          that.upload.data.cat_id = _new_id;
          that.catAdd.isActive =false;
        }
     
        
      });
    }

  }
}
</script>

<style>
  /* .time {
    font-size: 13px;
    color: #999;
  }
   */
  /* .bottom {
    margin-top: 13px;
    line-height: 12px;
  } */
/* 
  .button {
    padding: 0;
    float: right;
  } */

.image-card{
  height:240px;
  padding:10px;
  position:relative;
}
.image-name{
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.image {
  width: auto;
  max-width: 100%;
  height:200px;
  display: block;
  margin:0 auto;
  cursor: pointer;
}

.image-dialog .check{
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 46px;
  color: #67C23A;
}


.image-dialog .delete{
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  color: #999;
  display: none;
  cursor: pointer;
}

.image-dialog .image-card:hover .delete{
  display: block;
} 

.image-dialog .el-tabs--border-card{
  box-shadow:none;
}
.image-dialog .el-dialog__body{
  padding: 0 16px;
}


.image-uploader,.image-uploader .el-upload,.image-uploader .el-upload-dragger{
  width:100%;
}


</style>
