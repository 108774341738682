export default {
    name: 'imgUploader',
    title: '照片上传',
    formName:'default',
    uniqid:'',
    label:'照片',
    required: false,
    isCamera:false,//只能拍照，不能相册选图
    // capture:['album', 'camera'],//使用isCamera来设置，默认两种
    // accept:['image/gif', 'image/jpeg' ,'image/jpg', 'image/png'],//图片格式  固定
    previewSize:80,
    // maxSize:'',//默认 各手机不同，上传前压缩就可以，不应限大小
    maxCount:3,
    // resultType:'dataUrl',//默认  大文件才用 file
    uploadText:'拍照上传',
    // imageFit:'cover', //默认
    uploadIcon:'photograph',
    // multiple:false,//默认 是否多图，通过maxCount判断大于1就是 true
    fileList:[
        // {
        //     url:'',
        //     name:'',
        //     thumb:'',
        //     type:'image',
        //     isImage:true,
        //     isVideo:false,
        //     status: 'uploading',
        //     message: '上传中...',
        // }
    ]
}