<template>
<div>
  <van-field :label="schema.label" :required="schema.required === true">
    <template slot="input">
      <van-radio-group v-model="schema.value" :disabled="schema.disabled" :direction="schema.direction">
        <van-radio :shape="schema.shape" v-for="(item, idx) in schema.groups" :key="idx" :name="item.label" :disabled="item.disabled"  :checked-color="schema.checkedColor">{{item.label}}</van-radio>
      </van-radio-group>
    </template>
  </van-field>
  <van-field v-if="showOther" type="text" label="其他" v-model="schema.other" :placeholder="'请填写'+schema.label"/>
</div>
</template>

<script>
import { Notify } from 'vant';
import _ from 'lodash'

export default {
  name: 'radioGroup',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  computed:{
    showOther:function(){
      return this.schema.value =='其他';
    }
  },
  mounted:function(){
    // 兼容旧版
    if(!this.schema.checkedColor){
      this.schema.checkedColor = '#1989fa'
    }
  },
  methods:{
    onScan(){
      Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    }
  }
}
</script>

<style>
.van-radio {
    margin-top: 4px;
    margin-bottom: 4px;
}
.van-radio-group--vertical .van-radio:last-child{
  margin-top: 0;
  margin-bottom: 0;
}
.van-radio__icon--square .van-icon{
  border-radius:4px !important;
}
</style>