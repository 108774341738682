export default {
    name: 'mp4Play',
    title: '视频播放',
    // paddingTB: 5,
    // paddingLR: 5,
    fileList: [{
        icon: 'https://v.2weima.com/static/images/diy/swipe-2.png',
        filePath:'',
        text: '三角形面积公式',
        label: '三角形面积计算公式的使用，难点解说',
        attach: {
            id:0,
            name:'',
            path:'',
            size_format:''
        }
    }]
}
