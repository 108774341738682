<template>
  <div :style="'padding:' + schema.paddingTB + 'px ' + schema.paddingLR + 'px;background:' + schema.bgColor">
    <van-row :gutter="schema.gutter">
      <van-col v-for="(item, idx) in schema.grids" :key="idx" :span="span"
        :style="'margin-bottom:' + (schema.gutter / 2) + 'px;margin-top:' + (schema.gutter / 2) + 'px; overflow:hidden'">
        <van-button :color="item.color" :plain="item.plain" :icon="item.icon" :text="item.text" native-type="button"
          :square="schema.square != 'round'" :round="schema.square == 'round'" round block></van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: 'buttonGroup',
  props: {
    schema: {
      'type': Object,
      default: {},
      required: true
    },
    pluginIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    span: function () {
      // console.log('schema.columnNum', this.schema?.columnNum)
      return 24 / this.schema?.columnNum;
    }
  },
  methods: {

  }
}
</script>

<style></style>