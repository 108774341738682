<template>
<div :style="'width:100%;height:'+schema.height+'px;background:'+schema.bgColor + colorTransparent(schema.transparent)+';'"></div>
</template>

<script>
export default {
  name: 'kongbai',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    colorTransparent:function(value){
      value = parseInt(value);
      if(isNaN(value)){
         value = '';
      }
      else if(value>=100){
        value = '';
      }else if(value<10){
        value = 0+''+value;
      }
      return value;
    }
  }
}
</script>

<style>

</style>