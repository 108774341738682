<template>
  <el-form
    label-position="right"
    label-width="70px"
  >
    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>
    <el-form-item label="默认值">
      <el-input v-model="currentPlugin.text" placeholder="请输入默认值" clearable/>
    </el-form-item>

    <el-form-item label="输入类型">
        <el-select v-model="currentPlugin.type" placeholder="请选择类型">
            <el-option label="单行文本" value="text" />
            <el-option label="多行文本" value="textarea" />
            <el-option label="邮箱地址" value="email" />
            <el-option label="整数" value="digit" />
            <el-option label="数字(支持小数)" value="number" />
            <el-option label="电话号码" value="tel" />
            <el-option label="密码" value="password" />
        </el-select>
    </el-form-item>

    <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.required" label="必填"  />
        <el-checkbox v-model="currentPlugin.readonly" label="只读" />
        <el-checkbox v-model="currentPlugin.disabled" label="禁用" />
    </el-form-item>

    <el-form-item label="授权获取"  v-if="currentPlugin.type=='tel'">
      <el-checkbox v-model="currentPlugin.isWxPhone" label="填表人微信手机号,授权自动填写"  />
      
      <el-tooltip
        class="box-item"
        effect="dark"
        content="填表人微信手机号,仅小程序可用"
        placement="top-start"
      >
        <i class="el-icon-question"></i>
      </el-tooltip>
    </el-form-item>


    <el-form-item label="支持扫码">
      <el-checkbox v-model="currentPlugin.isScan" label="扫码自动录入"  />
      
      <el-tooltip
        class="box-item"
        effect="dark"
        content="支持条码和二维码,仅小程序可用"
        placement="top-start"
      >
        <i class="el-icon-question"></i>
      </el-tooltip>
    </el-form-item>
    <template v-if="currentPlugin.isScan">
      <el-form-item>
        <el-checkbox v-model="currentPlugin.isSubstr" label="截取："  />
        <el-input-number v-model="currentPlugin.subStart" size="small" style="width:100px" :min="1" /> - <el-input-number v-model="currentPlugin.subEnd" size="small" style="width:100px" :min="0" /> 位 
        <el-tooltip
          class="box-item"
          effect="dark"
          content="例如结果：12345678，截取2-5位，即从第2位开始截取5个字符 = 23456"
          placement="top-start"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="图标色">
        <el-color-picker v-model="currentPlugin.scanColor" color-format="hex" size="small"></el-color-picker>
      </el-form-item>
    </template>

    <template v-if="$store.state.formSettings.multipleForm">
      <el-divider>其他</el-divider>
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>

  </el-form>



</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'field',
  props: {},
  components: {
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
}

</script>


<style scoped>


</style>
