<template>
<div>
    <div :class="'block-title' + (schema.required?' block-title--required':'')">
      {{ schema.label }}
      <div class="block-desc">{{schema.desc}}</div>
    </div>
    
  <div class="form-status status-green" v-if="schema.working.status =='normal'">正常</div>
  <div class="form-status status-red" v-else-if="schema.working.status =='abnormal'">异常</div>
  <div class="form-status status-orange" v-else>无</div>

  <div class="status-tips" :style="'font-size:'+ schema.tips.fontSize +'px;'">
    <span v-if="showOther">{{ schema.working.other }}</span>
    <span v-else>{{ schema.working.label }}</span>
  </div>
  <van-cell-group>
    <van-cell title="切换状态" icon="exchange" is-link/>
  </van-cell-group>
  <van-field v-if="showOther" type="text" label="其他" v-model="schema.working.other" :placeholder="'请填写'+schema.label"/>
</div>
</template>

<script>
export default {
  name: 'formStatus',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  computed:{
    showOther:function(){
      return this.schema.working.label =='其他';
    }
  },
  methods:{

  }
}
</script>

<style>
.form-status{
  color: white;
  background-color:#07c160; 
  border-radius: 6px;
  text-align: center;
  padding: 8px 0px;
  width:50%;
  margin:15px auto 10px;
  font-size:20px;
  letter-spacing:5px;
  text-transform:uppercase;
}

.status-red{
  background-color:#ee0a24; 
} 
.status-orange{
  background-color:#ed6a0c; 
} 
.status-green{
  background-color:#07c160; 
} 
.status-blue{
  background-color:#1989fa; 
} 



.status-tips{
  margin-bottom: 10px;
  text-align: center;
  font-size: 30px;
}
</style>