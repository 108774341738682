export default {
    name: 'mapMarked',
    title: '标注',
    formName:'default',
    uniqid:'',
    label:'标注',
    placeholder:'点击标注位置',
    required: false,
    value:'',
    marked:{}
}