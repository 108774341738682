<template>
  <el-dialog  :visible="visible" width="95%" class="image-dialog" @close="onCancel">

    <el-tabs v-model="tabsValue" @tab-click="onImageSelectTabsChange" type="card" style="margin-top: 10px;">
      <el-tab-pane label="Diy页面" name="diy" v-if="source=='diy' || showAll"></el-tab-pane>
      <el-tab-pane label="PDF文件" name="pdf" v-if="source=='pdf' || showAll"></el-tab-pane>
      <el-tab-pane label="相册" name="photo" v-if="source=='photo' || showAll"></el-tab-pane>
      <el-tab-pane label="导航" name="map" v-if="source=='map' || showAll"></el-tab-pane>
      <el-tab-pane label="名片" name="vcard" v-if="source=='vcard' || showAll"></el-tab-pane>
      <el-tab-pane label="WIFI" name="wifi" v-if="source=='wifi' || showAll"></el-tab-pane>
      <el-tab-pane label="音频" name="music" v-if="source=='music' || showAll"></el-tab-pane>
      <el-tab-pane label="小视频" name="video" v-if="source=='video' || showAll"></el-tab-pane>
      <el-tab-pane label="开场秀H5" name="kcx" v-if="source=='kcx' || showAll"></el-tab-pane>
      <el-tab-pane label="数据表" name="table" v-if="source=='table'"></el-tab-pane>
    </el-tabs>

    <el-form :inline="true" :model="searchForm">
      <el-form-item label="关键词">
        <el-input v-model="searchForm.kwd" placeholder="搜索文件名"></el-input>
      </el-form-item>
      <el-form-item label="选择分组">
        <el-select v-model="searchForm.cat_id" placeholder="请选择">
          <el-option
            v-for="item in catData"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="imageSelectLoad('search')">查询</el-button>
      </el-form-item>
    </el-form>
    
    <el-table
      ref="singleTable"
      :data="listData"
      highlight-current-row
      @current-change="onImageSelect"
      style="width: 100%;margin:10px 0"
    >
      <el-table-column
        prop="id"
        label="选择"
        width="80">
          <template slot-scope="scope">
          <el-radio :label="scope.row.id" v-model="selectId"></el-radio>
        </template>
      </el-table-column>
      <el-table-column
        property="title"
        label="标题">
        <template slot-scope="scope">
          <el-link :underline="false" :href="scope.row.qrview" type="primary" target="_blank">{{ scope.row.title }}</el-link>
        </template>
        
      </el-table-column>
      <el-table-column
        property="status"
        label="状态">
        <template slot-scope="scope">
          <el-tag size="medium" type="info" v-if="scope.row.status==0">待审核</el-tag>
          <el-tag size="medium" type="success" v-else-if="scope.row.status==1">正常</el-tag>
          <el-tag size="medium" type="danger" v-else-if="scope.row.status==2">不通过</el-tag>
          <p v-if="scope.row.status==2">{{scope.row.status_msg}}</p>
        </template>
        
      </el-table-column>
      <el-table-column
        property="create_time"
        label="生成时间">
      </el-table-column>
    </el-table>

    <div class="text-center mt-10" v-if="isNext[tabsValue]!==false ? true : false">
      <i v-if="loading" class="el-icon-loading" style="font-size:18px"></i>
      <el-button v-else  @click="imageSelectLoad('more')" type="primary" icon="el-icon-refresh-right" style="width:200px" plain>加载更多...</el-button>
    </div>
    

    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </span>
    
  </el-dialog>
</template>
<script>
import utils from "../utils/utils"


export default {
  name: 'ActionLinkDialog',
  props: {
    visible: {
      type:Boolean,
      default:false
    },
    source:{
      type:String,
      default:""
    },
    showAll: {
      type:Boolean,
      default:false
    }
  },
  data:function(){
    return {
      tabsValue: this.source || "diy",
      selectData:null,//选中
      selectId:0,
      searchForm: {
        kwd: '',
        cat_id: 0
      },
      catData: [],
      pageIndex:0,
      loading:true,
      isNext:{
        diy:true,
      },
      listData:[
        // {
        //   id:12,
        //   url:'http://zbcms.2weima.com/static/images/h5editor/image-placeholder.jpg'
        // }
      ]
    }
  },
  watch:{
    visible:function(val){
      // onLoad 打开且listData为空
      if(val && this.listData.length==0){
        console.log('onLoad',val)
        this.imageSelectLoad('init')

      }
    }
  },
  methods:{
    onCancel(){
      this.$emit('update:visible', false)
    },
    onConfirm(){
      if(this.selectData ==null || _.isEmpty(this.selectData)){
        //什么都不做
        this.$emit('update:visible', false)
        return ;
      }

      this.$emit('update:visible', false)
      this.$emit('confirm',_.clone(this.selectData))
    },
    //选择图片
    onImageSelect(item){
      // console.log('onImageSelect',item);
      this.selectData = item;
      this.selectId = item.id;
    },
    imageSelectLoad(loadType){
      var that = this;

      if(loadType =='search' ||　loadType =='init'){
        this.pageIndex = 0;//重新计算
        this.listData = [];
        this.selectData = null;
      }

      this.loading = true;
      let requestUrl =this.source=='table' ? config.selectTableUrl : config.selectPageUrl 
      utils.request(requestUrl,{
        op:"load_list",
        source: "diy",
        qr_type: this.tabsValue,
        cat_id: this.searchForm.cat_id, 
        kwd:this.searchForm.kwd , 
        page_index : this.pageIndex+1
      },function(res){

        // console.log('request call',res);
        
        if(res.code !=1){
          that.onCancel();
        }else{
          that.loading = false;//加载状态
          that.pageIndex +=1;
          that.isNext[that.tabsValue] = res.is_next==1;
          for(let i in res.data){
            that.listData.push(res.data[i]);
          }

          if(res.group_list !=""){
            let _catData = []
            //转格式适配
            _catData.push({
                id:0, 
                pid:0, 
                label:"默认分组"
            });
            for(let i in res.group_list){
              _catData.push({
                id:i, 
                pid:0, 
                label:res.group_list[i]
              });
            }

            that.catData = _catData;
          }

        }

      });
    },
    onImageSelectTabsChange({name}){
      let that = this;
      this.tabsValue = name
      // console.log('name',name)
      this.imageSelectLoad('init')
      

    }

  }
}
</script>

<style>
.el-table__body tr.current-row>td.el-table__cell {
    background-color: #a0cfff !important;
}
</style>
