export default {
    name: 'nuoChe',
    title: '挪车信息',
    isWechat: false,
    isTel: true,
    car:{
        province:'京',
        az:'A',
        number:'12345',
        color: '#ffffff',
        bgColor: '#4b5cc4',
    },
    tips:{
        text:'临时停靠，请多关照',
        color: '#000000',
        fontSize:30,
    },
    wechat: [{
        id: 0,
        url: 'https://v.2weima.com/static/images/diy/kefu.png',
        alt: '',
        filePath:'',
    }],
    cells: [{
        text: '挪车电话',
        tel: '15807630051',
        label: '点击拔打，马上滚开'
    }]
}