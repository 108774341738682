export default{
    name: 'noticeBar',
    title: '公告通知',
    text: '二维工坊DIY编辑器设计属于自己的页面二维工坊DIY编辑器设计属于自己的页面二维工坊DIY编辑器设计属于自己的页面',
    color: '#1989fa',
    bgColor: '#ecf9ff',
    scrollable: false,
    wrapable: false,
    speed: 50,
    handle: 'none',
    message: '',
    link: '',
    path: '',
    //小程序页面
    appId: '' //小程序appId
}