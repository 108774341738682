export default {
    name: 'qrVcard',
    title: '电子名片',
    iconSize:43,
    qrList: [{
        icon: 'https://v.2weima.com/static/images/icon/vcard.png',
        filePath:'',
        text: '陈经理',
        label: '商务合作、广告合作;咨询电话',
        qrTitle: '',
        qrScene: ''
    }]
}