export default {
    name: 'jumpList',
    title: '跳转列表',
    grids: [{
        text: '企业简介',
        label: '快速了解我们公司',
        handle: 'none',
        message: '',
        link: '',
        path: '',//小程序页面
        appId: '' //小程序appId
    }]
}