export default {
    name: 'area',
    title: '地区选择',
    formName:'default',
    uniqid:'',
    label:'地址',
    placeholder:'', //广东省/广州市/天河区
    required: false,
    value:'',//最后一个的code 440106
    // areaList:{}
}