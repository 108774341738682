export default {
    name: 'submit',
    title: '提交按钮',
    uniqid:'',
    label:'提交表单',
    formName:'default',//多个提交按钮处理
    notRepeat:false,
    submitPwd:'',//提交密码
    textAlign: 'center',
    color: '#67C23A',
    bgColor: '#f0f9eb',
    paddingTB: 10,
    paddingLR: 10,
    plain:true,
    square:'round',// square or round
    icon:'success',
    size:'large',
    handle: 'message',
    message: '提交成功',
    link: '',
    path: '',//小程序页面
    appId: '' //小程序appId
}