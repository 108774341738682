<template>
<div>


  
  <div class="help text-center m-10" v-if="schema.fields.length <=0">
      请配置要的显示组件
  </div>
  <div class="m-b-10" v-else-if="schema.isShowMode=='list'">
    <div class="block-title" v-if="schema.label !=''">
      {{schema.label}}
    </div>
    <van-steps direction="vertical" :active="0" active-icon="arrow-up">
      <!-- 数据列表 -->
      <van-step v-for="num in [1,2]" :key="num">
        <!-- 组件 -->
        <div v-for="(item, idx) in schema.fields" :key="idx" >
            <div v-if="item.name =='imgUploader'">
              <div class="form-data_li">{{item.label}}：</div>
              <van-uploader v-model="formDataFileList" :show-upload="false" :deletable="false" :lazy-load="true" />
            </div>
            <div class="form-data_li" v-else-if="item.name =='switch'" >
            {{item.label}}：<van-icon name="success" color="#07c160" v-if="item.value==1"/> <van-icon name="cross" color="#ee0a24" v-if="item.value!=1"/>
            </div>
            <div class="form-data_li" v-else-if="item.name =='rate'" >
            {{item.label}}： <van-rate v-model="item.value" :icon="item.icon" :void-icon="item.icon + '-o'" :count="item.count" :allow-half="item.allowHalf" :void-color="item.voidColor" :color="item.color" readonly/>
            </div>
            <div class="form-data_li" v-else-if="formDataFieldCheck(item.name)">
              {{item.label}}：{{item.title}}
            </div>
        </div>
        <div v-if="schema.isShowTime">提交时间：2021-05-20 05:20</div>
      </van-step>
      
    </van-steps>
    <div class="block-title text-center">
      <van-button  type="info" size="small" icon="share" >查看更多{{schema.label}}，共N条</van-button>
    </div>
    
  </div>
  <div class="m-b-10" v-else-if="schema.isShowMode=='pwd'">
    <div class="block-title" v-if="schema.label !=''">
      {{schema.label}}
    </div>
    <div class="block-title text-center">
      <van-button type="info" size="small" icon="bars" >查看{{schema.label}}，共N条</van-button>
    </div>
  </div>

</div>
</template>

<script>
// import { Notify } from 'vant';
import utils from "../../../utils/utils"

export default {
  name: 'formData',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  data:function(){
    return {
      formDataFileList:[//信息展示 test data
        { url: '/static/images/demo-placeholder.jpg' }, // Uploader 根据文件后缀来判断是否为图片文件
        { url: '/static/images/demo-placeholder.jpg', isImage: true },// 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
      ],
    }
  },
  methods:{
    formDataFieldCheck(pluginName){
      return utils.formDataFieldCheck(pluginName)
    }
    // onScan(){
    //   Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    // }
  }
}
</script>

<style>

</style>