export default {
    name: 'checkboxGroup',
    title: '复选框组',
    formName:'default',
    uniqid:'',
    label:'喜欢的水果',
    direction:'vertical',
    shape:'square',
    disabled:false,
    required: false,
    min:0,
    max:0,
    values:[],
    other:'',//其他值,
    checkedColor:'#1989fa',
    groups:[
        {
            label:'苹果',
            disabled:false,
            checked:true,
            // checkedColor:'#1989fa'
        },
        {
            label:'香蕉',
            disabled:false,
            checked:false,
            // checkedColor:'#1989fa'
        }
    ]
}