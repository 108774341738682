export default {
    name: 'selectJoin',
    title: '级联选择',
    formName:'default',
    uniqid:'',
    label:'选择接种点',
    placeholder:'点击选择社区',
    required: false,
    values:[], //旧版 value defaultIndex
    cascader:[//旧版 columns
        {
            value: '荔湾区',
            children:[
                {
                    value:'多宝街社区'
                },
                {
                    value:'逢源街社区'
                },
                {
                    value:'昌华街社区'
                }
            ]
        },
        {
            value: '越秀区',
            children:[
                {
                    value:'洪桥街社区'
                },
                {
                    value:'梅花村街社区'
                },
                {
                    value:'登峰街社区'
                }
            ]
        }
        
    ]
}
// 旧版
// {
//     name: 'selectJoin',
//     title: '级联选择',
//     formName:'default',
//     uniqid:'',
//     label:'选择课程',
//     placeholder:'点击选择课程',
//     required: false,
//     value:'',
//     defaultIndex:[],
//     columns:[//暂时只提供两级
//         {
//             text: '计算机',
//             children:'软件开发,硬件,网络运维,平面设计'
//         },
//         {
//             text: '语言',
//             children:'中文,英文,日文,泰文'
//         },
//         {
//             text: '其它',
//             children:'美术绘画,音乐,舞蹈,健身'
//         }
//     ],
// }