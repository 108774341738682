<template>
  <div>
    <el-dialog :visible="visible" width="95%" class="image-dialog" @close="onCancel">


      <div style="margin-top: 10px;">
        <iframe id="iframe" frameBorder="0" scrolling="no" style="width: 100%; height: 800px; max-height: 100%"
        :src="mapurl"></iframe>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </span>



    </el-dialog>


  </div>
</template>
<script>




let mapLocation = {};

// 选择地图位置
export default {
  name: 'HandleMapDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    let QmapKey = 'BMWBZ-GXD6W-WQYRC-R7ZEC-SONP7-E7FXO';
    return {
      mapurl: `//apis.map.qq.com/tools/locpicker?search=1&type=1&key=${QmapKey}&referer=2weima`,
      // formData: {}
    }
  },
  // watch: {
  //   visible: function (val) {
  //     console.log('formData', this.formData)
  //   }
  // },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      mapLocation = {};
      // 初始化  visible
      window.onmessage = function (event) {

        // console.log('event', event)
        let loc = event.data;
        mapLocation = { ...loc }

        // setLocation(loc);
        // cityname: "清远市"
        // latlng: {纬度 lat: 23.69795, 经度 lng: 113.06269}
        // module: "locationPicker"
        // poiaddress: "广东省清远市清城区附城东区东城"
        // poiname: "清远市清城区人民政府"

        // console.log('你使用的组件是' + loc.module + ',刚选择了' + loc.poiname + ',它位于' + loc.poiaddress + ',它的经纬度是：' + loc.latlng.lat + ',' + loc.latlng.lng + ',所属城市为:' + loc.cityname)
      }

    },
    onCancel() {
      this.$emit('update:visible', false)
    },
    onConfirm() {

      //   console.log('formData',mapLocation)
      //  return;

      this.$emit('update:visible', false)
      this.$emit('confirm', _.clone(mapLocation))
    }
  }
}
</script>
