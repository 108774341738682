<template>
<div>
  <div :class="'block-title' + (schema.required?' block-title--required':'')">
      {{ schema.label }}
      <div class="block-desc">{{schema.desc}}</div>
  </div>

  <div class="van-table">
    <div class="van-table__main van-table__main--striped">
      <div class="van-table__main__body">
        <div class="van-table__main__body__tr" v-for="(item, idx) in schema.items" :key="idx">
          <span class="van-table__main__body__tr__td van-table__main__head__tr--border van-table__main__head__tr--align" v-if="item.author=='master'">{{ item.label }}</span>
          <span class="van-table__main__body__tr__td van-table__main__head__tr--border van-table__main__head__tr--align" v-else-if="item.author=='guest'">
            <van-field v-model="item.label" @blur="()=>{onBlurCheck(item.label,idx)}" placeholder="请填写项目名称.." type="text"  />
          </span>
          <span class="van-table__main__body__tr__td van-table__main__head__tr--border van-table__main__head__tr--align">
            <van-field v-model="item.value" placeholder="请填写值.."  :type="item.type" clearable/>
          </span>
        </div>
      </div>

    </div>
    <div class="van-table__summary" v-if="schema.isCanAdd" >
      <van-button type="primary" icon="plus" size="small" @click="onAddItem" plain>添加项目</van-button>
        <span class="van-table__summary__text">名称留空自动删除</span>
    </div>
  </div>
</div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'tableField',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    onAddItem (){
      let items = this.schema.items
      let length = this.schema.items.length
      // console.log('length',length)
      items.push({
          label:'项目' + (length +1),
          value:"",
          author:"guest"
      })

      items = _.uniqBy(items, 'label'); // 去重
      this.schema.items = items
    },
    // 检查空输入和重复
    onBlurCheck(value,index){
      // 删除空项目
      if(value==""){
        this.schema.items.splice(index,1)
      }else{
        // 去重
        let items = this.schema.items
        items = _.uniqBy(items, 'label'); 
        this.schema.items = items
      }
    }
  }
}
</script>

<style>

</style>