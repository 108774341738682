<template>
  <el-form
    label-position="right"
    label-width="90px"
  >
    <el-form-item label="分隔文本">
      <el-input v-model="currentPlugin.text" placeholder="请输入分隔文本"  clearable/>
    </el-form-item>

    <el-form-item label="文本位置" v-if="currentPlugin.text !=''">
      <el-radio-group v-model="currentPlugin.position" size="small">
        <el-radio-button label="left">居左</el-radio-button>
        <el-radio-button label="center">居中</el-radio-button>
        <el-radio-button label="right">居右</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="属性">
      <el-checkbox v-model="currentPlugin.dashed" label="虚线"/>
    </el-form-item>

    <el-form-item label="线条色">
      <el-color-picker v-model="currentPlugin.borderColor" color-format="hex" size="small"></el-color-picker>
    </el-form-item>
    <el-form-item label="字体色">
      <el-color-picker v-model="currentPlugin.color" color-format="hex" size="small"></el-color-picker>
    </el-form-item>

  </el-form>

</template>
<script>
import { mapGetters } from 'vuex'


export default {
  name: 'divider',
  props: {},
  components: {
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
    }
  },
  methods:{
    // onConfirm(iconName){
    //   console.log('iconName',iconName)
    // }
   
  }
}

</script>


<style scoped>


</style>
