<template>
  <el-form
    label-position="right"
    label-width="90px"
  >
    <el-form-item label="标题">
      <el-input v-model="currentPlugin.text" placeholder="请输入标题"  clearable/>
    </el-form-item>

    <el-form-item label="描述">
      <el-input v-model="currentPlugin.desc" placeholder="请输入描述"  clearable/>
    </el-form-item>

    <el-form-item label="对齐方式">
      <el-radio-group v-model="currentPlugin.textAlign" size="small">
        <el-radio-button label="left">居左</el-radio-button>
        <el-radio-button label="center">居中</el-radio-button>
        <el-radio-button label="right">居右</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="上下边距">
      <el-input-number size="small" v-model="currentPlugin.paddingTB" :min="0" :max="300"></el-input-number> PX
    </el-form-item>

    <el-form-item label="左右边距">
      <el-input-number size="small" v-model="currentPlugin.paddingLR" :min="0" :max="300"></el-input-number> PX
    </el-form-item>

    <el-form-item label="字体大小">
      <el-input-number size="small" v-model="currentPlugin.fontSize" :min="12" :max="30"></el-input-number> PX
    </el-form-item>

    <el-divider>其他</el-divider>


    <el-form-item label="标题色">
      <el-color-picker v-model="currentPlugin.color" color-format="hex" size="small"></el-color-picker>
    </el-form-item>
    <el-form-item label="描述色">
      <el-color-picker v-model="currentPlugin.descColor" color-format="hex" size="small"></el-color-picker>
    </el-form-item>
    <el-form-item label="背景色" v-if="!currentPlugin.transparent">
      <el-color-picker v-model="currentPlugin.bgColor" color-format="hex" size="small"></el-color-picker>
    </el-form-item>
  
   

  </el-form>

</template>
<script>
import { mapGetters } from 'vuex'


export default {
  name: 'diyTitle',
  props: {},
  components: {
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
    }
  },
  methods:{
    // onConfirm(iconName){
    //   console.log('iconName',iconName)
    // }
   
  }
}

</script>


<style scoped>


</style>
