import navBar from "./modules/navBar"
import noticeBar from "./modules/noticeBar"
import swipe from "./modules/swipe"
import navGrid from "./modules/navGrid"
import image from "./modules/image"
import imageGrid from "./modules/imageGrid"
import richText from "./modules/richText"
import title from "./modules/title"
import button from "./modules/button"
import kefu from "./modules/kefu"
import kongbai from "./modules/kongbai"
import divider from "./modules/divider"

import qrPdf from "./modules/qrPdf"
import qrPhoto from "./modules/qrPhoto"
import qrMap from "./modules/qrMap"
import qrVcard from "./modules/qrVcard"
import qrWifi from "./modules/qrWifi"
import qrMusic from "./modules/qrMusic"
import qrVideo from "./modules/qrVideo"
import table from "./modules/table"

import field from "./modules/field"
import radioGroup from "./modules/radioGroup"
import checkboxGroup from "./modules/checkboxGroup"
import stepper from "./modules/stepper"
import switchOff from "./modules/switchOff"
import imgUploader from "./modules/imgUploader"
import selectPicker from "./modules/selectPicker"
import selectJoin from "./modules/selectJoin"
import location from "./modules/location"
import mapMarked from "./modules/mapMarked"
import area from "./modules/area"
import rate from "./modules/rate"
import datetime from "./modules/datetime"
import submit from "./modules/submit"
import formData from "./modules/formData"
import tableField from "./modules/tableField"
import checkList from "./modules/checkList"
import nuoChe from "./modules/nuoChe"
import formStatus from "./modules/formStatus"
import mp3Play from "./modules/mp3Play"
import mp4Play from "./modules/mp4Play"
import jumpList from "./modules/jumpList"
import collapse from "./modules/collapse"
import easyTable from "./modules/easyTable"
import buttonGroup from "./modules/buttonGroup"

// import textarea from "./modules/textarea"
// import address from "./modules/address"
// import signature from "./modules/signature"
// import datePicker from "./modules/datePicker"
// import timePicker from "./modules/timePicker"
// import cascader from "./modules/cascader"


export default{
    navBar,
    noticeBar,
    swipe,
    navGrid,
    image,
    imageGrid,
    richText,
    title,
    button,
    kefu,
    kongbai,
    divider,

    qrPdf,
    qrPhoto,
    qrMap,
    qrVcard,
    qrWifi,
    qrMusic,
    qrVideo,
    table,

    field,
    checkboxGroup,
    radioGroup,
    stepper,
    switchOff,
    imgUploader,
    selectPicker,
    selectJoin,
    location,
    mapMarked,
    area,
    rate,
    datetime,
    submit,
    formData,
    tableField,
    checkList,
    nuoChe,
    formStatus,
    mp3Play,
    mp4Play,
    jumpList,
    collapse,
    easyTable,
    buttonGroup
    // textarea,
    // address,
    // signature,
    // datePicker,
    // timePicker,
    // cascader
}