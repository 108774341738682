import Vue from 'vue'
import { Message } from 'element-ui';
// import { Loading } from 'element-ui';
import { MessageBox } from 'element-ui';
import _ from 'lodash'

const utils = {
  getUniqid:function(length) {
    length = length || 8;
    var str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i) 
      result += str[Math.floor(Math.random() * str.length)];
    return result;
  },
  getFileExt:function(fileName){
    return fileName.substring(fileName.lastIndexOf('.') + 1)
  },
  isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
  },
  // 多级联动数据转tree
  tmpTreeId:10000,
  cascader2tree: (columns)=>{
    let _arr = []
    columns.forEach(element => {
      let _children = [];
      if(element.children){
        _children = utils.cascader2tree(element.children)
      }
      if(!_.isEmpty(_children)){
        _arr.push({
          id: utils.tmpTreeId ++,
          label: element.value,
          children: _children
        })
      }else{
        _arr.push({
          id: utils.tmpTreeId ++,
          label: element.value,
          // children: _children
        })
      }
    });
    return _arr 
  },
  tree2cascader:(columns)=>{
    let _arr = []
    columns.forEach(element => {
      let _children = [];
      if(element.children){
        _children = utils.tree2cascader(element.children)
      }
      if(!_.isEmpty(element.label)){
        // 没有的不要加children
        if(!_.isEmpty(_children)){
          _arr.push({
            value: element.label,
            children: _children
          })
        }else{
          _arr.push({
            value: element.label,
            // children: _children
          })
        }
        
      }
      
    });
    return _arr 
  },
  // 分类数据转多级 id: 18, pid: 0, cat_name: '测试'
  toTree:(data = [], config = {}) =>{
    const { id = "id", pid = "pid" } = config;
    // 浅拷贝精髓所在
    const newData =_.cloneDeep(data);// JSON.parse(JSON.stringify(data));
    // 存放结果
    const result = [];
    // 存放平铺数据
    const allNodeMap = {};
    // 不是Array不处理
    if (!Array.isArray(newData)) {
      return result;
    }
    // 清除原有的children，以及平铺数据
    newData.forEach((item) => {
      delete item._child;
      allNodeMap[item[id]] = item;
    });
    // 核心代码
    newData.forEach((item) => {
      const parent = allNodeMap[item[pid]];
      if(parent){
        (parent._child || (parent._child=[])).push(item)
      }else{
        result.push(item)
      }
    });
  
    return result;
  },
  //网络请求
  request:(url,formData,callback)=>{

    
    let _loadingMessage = Message({ showClose: true, message: 'Loading...', type: 'info',duration:2000 });

    formData = formData || {};
    if(!formData['source']){
      formData['source'] = 'diy';
    }

    Vue.http.post(url,formData,{emulateJSON:true}).then(function (response) {
      let res = response.data;

      // console.log('response',res)

      
      //出错时弹出提示
      if(res.code==0){
    
        if(!_.isEmpty( res.login_url)){


          MessageBox.confirm('亲，登陆后再操作，方便您后期管理', '登陆提示', {
            confirmButtonText: '登陆',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
              //网页跳转link
              var a = document.createElement('a');
              a.href = res.login_url;
              var target_href = document.createAttribute("target");
              target_href.nodeValue = "_blank";
              a.setAttributeNode(target_href);
              a.click();
          }).catch(() => {
               
          });

          
        }else{
          MessageBox.alert(res.msg, '提示',{
            type: 'warning',
            dangerouslyUseHTMLString: true,
            closeOnClickModal:true,
          })
        }
      }else if(res.code==1){
        //成功并有msg时自动弹出
        if(!_.isEmpty(res.msg) && res.msg !='success'){
          MessageBox.alert(res.msg, '提示',{
            type: 'success',
            dangerouslyUseHTMLString: true,
            closeOnClickModal:true,
          })

        }

      }

      //无论是否成功都会回调
      if(typeof callback =="function"){
        callback(res);
      }

      _loadingMessage.close();


    }).catch(function (error) {
      console.log(error);
      MessageBox.alert('请求发生错误，请稍后再试...', '错误提示')
    });


  },
  formDataFieldCheck:function(pluginName){
    return _.includes([
      'field',
      'switchOff',
      'checkboxGroup',
      'radioGroup',
      'stepper',
      'rate',
      'selectPicker',
      'selectJoin',
      'location',
      'mapMarked',
      'area',
      'datetime',
      'imgUploader',
      'tableField',
      'checkList',
    ],pluginName)
  },
  //把旧版数组升级到新版
  updatePluginData:function(pluginArray){

    for(let index in pluginArray){
      let pluginName = pluginArray[index].name;

      //把 switch 升级为 switchOff
      if(pluginName =="switch"){
        pluginArray[index].name = "switchOff";
      }else if(pluginName =="selectJoin"){
        pluginArray[index] = this.updateSelectJoinData(pluginArray[index])
       
      }

      // form组件升级
      if(_.includes([
        'field',
        'switch',
        'checkboxGroup',
        'radioGroup',
        'stepper',
        'rate',
        'selectPicker',
        'selectJoin',
        'location',
        'mapMarked',
        'area',
        'datetime',
        'imgUploader',
        'formData',
        'submit'
      ],pluginName)){
        // console.log('pluginArray[index].formName',index,pluginArray[index])
        if(!pluginArray[index].formName){
          pluginArray[index].formName = "default";
        }
      }
    }
    
    return pluginArray;
    
  },
  // 把 selectJoin 数据升级
  updateSelectJoinData(currentPlugin){
    // console.log('updateCheck')
    // 把columns格式转为cascader
    if(currentPlugin.columns){
      let _cascader = [];
      currentPlugin.columns.forEach(element => {

        let _children = [];
        if(element.children && element.children !=''){
          element.children.split(',').forEach(item => {
            _children.push({value:item})
          });
        }
        _cascader.push({
          value:element.text,
          children:_children
        })
      });
      delete currentPlugin.columns;
      currentPlugin.cascader =_cascader
     

    }

    return currentPlugin;
  }
      



}

export default utils