<template>
  <div>
    <el-form label-position="right" label-width="90px">


      <el-form-item label="表格数据">
        <el-button type="success" icon="el-icon-coin" @click="onEditTable()" plain>编辑数据</el-button>
      </el-form-item>



      <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.is_hidden" label="隐藏表头/编号" />
      </el-form-item>
      <el-form-item label="样式">
        <el-radio-group v-model="currentPlugin.tableSize" size="small">
          <el-radio-button label="table-xs">超小</el-radio-button>
          <el-radio-button label="table-sm">小尺寸</el-radio-button>
          <el-radio-button label="table-md">常规</el-radio-button>
          <el-radio-button label="table-lg">大号</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="上下边距">
        <el-input-number size="small" v-model="currentPlugin.paddingTB" :min="0" :max="300"></el-input-number> PX
      </el-form-item>

      <el-form-item label="左右边距">
        <el-input-number size="small" v-model="currentPlugin.paddingLR" :min="0" :max="300"></el-input-number> PX
      </el-form-item>

      <el-divider>其他</el-divider>

      <el-form-item label="字体色">
        <el-color-picker v-model="currentPlugin.color" color-format="hex" size="small"></el-color-picker>
      </el-form-item>
      <el-form-item label="背景色" v-if="!currentPlugin.transparent">
        <el-color-picker v-model="currentPlugin.bgColor" color-format="hex" size="small"></el-color-picker>
      </el-form-item>


    </el-form>

    <!-- 编辑表格 -->
    <table-editor-dialog :visible.sync="visible" :tableData="currentPlugin.dataSource" @confirm="onConfirm" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import TableEditorDialog from '../../TableEditorDialog'


export default {
  name: 'easyTable',
  props: {},
  components: {
    TableEditorDialog
  },
  computed: {
    ...mapGetters(['currentPlugin'])
  },
  data: function () {
    return {
      visible: false,
    }
  },
  methods: {
    onConfirm(tableData) {
      console.log('tableData', tableData)
      this.currentPlugin.dataSource = [...tableData]
    },
    onEditTable() {
      this.visible = true;
    },

  }
}

</script>


<style scoped></style>
