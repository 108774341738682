<template>
  <div>
    <el-form label-position="right" label-width="90px">


      <el-form-item label="显示方式">
        <el-radio-group v-model="currentPlugin.type" size="small">
          <el-radio-button label="sidebar">侧边导航</el-radio-button>
          <el-radio-button label="accordion">手风琴</el-radio-button>
        </el-radio-group>
      </el-form-item>


      <el-divider>内容设置</el-divider>
      <!-- {{ JSON.stringify(currentPlugin.groups[editableTabsValue].grids) }} -->


      <div style="margin-bottom: 20px; text-align: right">
        <el-button size="small" icon="el-icon-plus" @click="addTab">
          添加分组
        </el-button>
      </div>

      <el-tabs type="card" v-model="editableTabsValue" closable @tab-remove="removeTab">
        <el-tab-pane v-for="(groupItem, groupIdx) in currentPlugin.groups" :key="groupIdx + editableTabsValue"
          :label="groupItem.text" :name="groupIdx + ''">


          <el-form-item label="分组名称">
            <el-input v-model="groupItem.text" placeholder="分组名称" />
          </el-form-item>

          <el-collapse v-model="activeName" accordion>

            <draggable v-model="groupItem.grids" handle=".mover">
              <el-collapse-item v-for="(item, idx) in groupItem.grids" :key="idx" :name="idx">
                <template slot="title">
                  <div :style="{ flexGrow: 1 }">
                    <span class="mover" title="拖动改变顺序"><i class="header-icon el-icon-rank"></i></span> {{ item.text }}
                    <span :style="{ float: 'right', marginRight: '10px' }">
                      <el-link type="danger" @click.stop="onPluginDelItem(idx)" :underline="false"><i
                          class="el-icon-circle-close" :style="{ fontSize: '18px' }"></i></el-link>
                    </span>
                  </div>
                </template>


                <el-form-item label="标题">
                  <el-input v-model="item.text" placeholder="标题" clearable />
                </el-form-item>

                <el-form-item label="描述">
                  <el-input v-model="item.label" placeholder="描述信息" clearable />
                </el-form-item>

                <el-form-item label="点击时">
                  <el-button size="mini" style="margin-left:10px;" @click="onHandleSetting(idx)"
                    icon="el-icon-s-tools">设置动作</el-button>

                  <!-- <el-radio-group v-model="item.handle" size="small">
                    <el-radio-button label="none">默认</el-radio-button>
                    <el-radio-button label="link">跳转</el-radio-button>
                    <el-radio-button label="message">消息</el-radio-button>
                  </el-radio-group> -->
                </el-form-item>

                <!-- <div class="set-options" v-if="item.handle == 'link' || item.handle == 'message'">
                  <el-form-item label="弹出消息" v-if="item.handle == 'message'">
                    <el-input v-model="item.message" placeholder="如：请联系客服制作同款页面" />
                  </el-form-item>
                  <template v-if="item.handle == 'link'">
                    <p class="help">H5打开：跳转网址</p>
                    <el-form-item label="网址">
                      <el-input v-model="item.link" placeholder="如：https://www.2weima.com">
                        <el-button slot="append" @click="onHandleSetting(idx)">选择</el-button>
                      </el-input>
                    </el-form-item>
                    <p class="help">小程序打开：跳转页面，支持第三方小程序</p>
                    <el-form-item label="小程序页面">
                      <el-input v-model="item.path" placeholder="如：pages/index/index?foo=bar">
                        <el-button slot="append" @click="onHandleSetting(idx)">选择</el-button>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="小程序appId">
                      <el-input v-model="item.appId" placeholder="如：wx81bdf0b17a44370d" />
                    </el-form-item>
                  </template>
                </div> -->



              </el-collapse-item>
            </draggable>

          </el-collapse>

          <div class=" mt-10"><el-button @click="onPluginAddItem" type="success" size="small" icon="el-icon-plus"
              round>添加项目</el-button></div>
          <el-divider>提示</el-divider>
          <div class="help mt-10">点击时：默认（为无动作）</div>


        </el-tab-pane>

      </el-tabs>




    </el-form>
    <!-- 链接选择 -->
    <!-- <action-link-dialog :visible.sync="visibleLink" source="diy" :showAll="true" @confirm="onConfirmLink" /> -->
    <!-- 动作设置 -->
    <handle-setting-dialog v-if="visibleHandle" :visible.sync="visibleHandle" :schema="currentSchema" source="diy" :showAll="true" @confirm="onConfirmHandle" />
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable';
// import ActionLinkDialog from '../../ActionLinkDialog'
import HandleSettingDialog from '../../HandleSettingDialog'
import pluginSchema from '../../../pluginSchema/index'
import _ from 'lodash'


export default {
  name: 'jumpList',
  props: {},
  components: {
    draggable,
    HandleSettingDialog
  },
  computed: {
    ...mapGetters(['currentPlugin']),
    currentSchema: function () {
      return this.currentPlugin.groups[this.editableTabsValue]?.grids[this.setLinkIndex] || {};
    },
  },
  data: function () {
    return {
      editableTabsValue: 0,
      activeName: 0,
      visible: false,
      visibleHandle: false,
      setLinkIndex: 0
    }
  },
  // mounted: function () {
  //   if (!this.currentPlugin.iconSize) {
  //     this.currentPlugin.iconSize = 43
  //   }
  // },

  methods: {

    // onActionLink(index) {
    //   this.visibleLink = true;
    //   this.setLinkIndex = index;
    // },

    // onConfirmLink(linkData) {
    //   // console.log('linkData',linkData)
    //   this.currentPlugin.groups[this.editableTabsValue].grids[this.setLinkIndex].link = linkData.qrview;
    //   this.currentPlugin.groups[this.editableTabsValue].grids[this.setLinkIndex].path = linkData.weapp_path;
    //   this.currentPlugin.groups[this.editableTabsValue].grids[this.setLinkIndex].appId = linkData.weapp_id;
    // },
    onHandleSetting(index) {
      // console.log('onUploadImage')
      this.visibleHandle = true;
      this.setLinkIndex = index;
    },
    onConfirmHandle(payload) {
      // console.log('onConfirmHandle payload,', payload)
      // this.currentPlugin = { ...payload }

      // let itemHandle = this.currentPlugin.groups[this.editableTabsValue].grids[this.setLinkIndex]

      this.currentSchema.handle = payload.handle;
      this.currentSchema.link = payload.link;
      this.currentSchema.path = payload.path;
      this.currentSchema.appId = payload.appId;
      this.currentSchema.jumpType = payload.jumpType;
      this.currentSchema.shortLink = payload.shortLink;

      if (!_.isEmpty(payload.message)) {
        this.currentSchema.message = payload.message;
      }
      if (!_.isEmpty(payload.handle_call)) {
        this.currentSchema.handle_call = payload.handle_call;
      }
      if (!_.isEmpty(payload.handle_map)) {
        this.currentSchema.handle_map = payload.handle_map;
      }
      if (!_.isEmpty(payload.handle_copy)) {
        this.currentSchema.handle_copy = payload.handle_copy;
      }
      if (!_.isEmpty(payload.handle_wifi)) {
        this.currentSchema.handle_wifi = payload.handle_wifi;
      }

      // console.log('this.currentPlugin.groups[this.editableTabsValue].grids[this.setLinkIndex]',this.currentPlugin.groups[this.editableTabsValue].grids[this.setLinkIndex])
    },
    // 添加项目
    onPluginAddItem() {

      let activeName = parseInt(this.editableTabsValue, 0);

      const theSchema = pluginSchema.collapse.groups[0];

      this.currentPlugin.groups[activeName].grids.push(_.cloneDeep(theSchema.grids[0]))
    },
    // 删除项目
    onPluginDelItem(index) {
      let that = this;
      this.currentPlugin.groups[this.editableTabsValue].grids.splice(index, 1);
      if (this.currentPlugin.groups[this.editableTabsValue].grids.length <= 0) {

        that.$message({
          message: '至少设置一项',
          type: 'warning'
        });

        //全部删除了就添加个默认项目
        setTimeout(function () {
          that.onPluginAddItem();

        }, 100);
      }

    },
    addTab() {
      // console.log('targetName', targetName)
      // return;
      const theSchema = pluginSchema.collapse.groups[0];
      this.currentPlugin.groups.push(_.cloneDeep({ ...theSchema, text: theSchema.text + (this.currentPlugin.groups.length + 1) }))

    },
    removeTab(targetName) {

      if(this.currentPlugin.groups.length<=1){
        this.$message({
          message: '至少保留一组',
          type: 'warning'
        });
        return ;
      }

      // console.log('removeTab targetName', targetName)

      let removeName = parseInt(targetName, 0);
      let activeName = parseInt(this.editableTabsValue, 0);
      this.currentPlugin.groups.splice(removeName, 1);
      console.log('this.currentPlugin.groups', this.currentPlugin.groups)

      let newActiveName = 0;
      if (activeName >= removeName) {
        newActiveName = removeName - 1;
      }
      if (activeName < 0) {
        newActiveName = 0;
      }
      this.editableTabsValue = newActiveName + '';



      // console.log('this.editableTabsValue', this.editableTabsValue)
    }

  }
}

</script>


<style></style>
