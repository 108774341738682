export default {
    name: 'qrMusic',
    title: '音频',
    iconSize:43,
    qrList: [{
        icon: 'music-o',
        filePath:'',
        text: '第一课',
        label: '音频教材，难点解说',
        qrTitle: '',
        qrScene: ''
    }]
}