<template>
<van-field
  readonly
  clickable
  name="datetimePicker"
  :value="schema.value"
  :label="schema.label"
  placeholder="点击选择"
  :required="schema.required === true" 
/>
</template>

<script>
// import { Notify } from 'vant';

export default {
  name: 'datetime',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{
    // onScan(){
    //   Notify({ type: 'warning', message: '点击了扫码',duration: 800 });
    // }
  }
}
</script>

<style>

</style>