export default {
    name: 'location',
    title: '定位',
    formName:'default',
    uniqid:'',
    label:'定位',
    placeholder:'点击获取定位',
    required: false,
    value:'',
    location:{}
}