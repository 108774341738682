export default {
    name: 'tableField',
    title: '表格填写',
    formName:'default',
    uniqid:'',
    label:'领取登记',
    desc:'防疫物资领取登记',
    required: false,
    isCanAdd:false, //允许填写人增加项目，author = guest
    items:[
        {
            label:'医用口罩/个',
            value:"1550",
            author:"master",
            type:"text" // text or number digit
        },
        {
            label:'医用消毒酒精/瓶',
            value:"",
            author:"master",
            type:"text"
        },
        {
            label:'一次性手套/双',
            value:"",
            author:"master",
            type:"text"
        }
    ]
}