<template>
  <el-form
    label-position="right"
    label-width="70px"
  >
    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>
    
    <el-form-item label="选项">
      <div class="set-options">
        <el-checkbox-group v-model="currentPlugin.values">
          <el-row :gutter="10" v-for="(item, idx) in currentPlugin.groups" :key="idx">
            <el-col :span="2"><el-checkbox :disabled="item.disabled" :label="item.label" title="设置默认选中"><span></span></el-checkbox></el-col>
            <el-col :span="19"><el-input v-model="item.label" size="small" placeholder="请输入选项" :disabled="item.label=='其他'" @blur="(e)=>{onBlurCheck(e.target.value,idx)}"/></el-col>
            <el-col :span="3">
              <el-popconfirm title="确认删除选项吗？"
                confirm-button-text="确认"
                cancel-button-text="取消"
                @confirm="onRemoveItem(idx)"
              >
                <template #reference>
                  <el-button type="default" icon="el-icon-close" size="small" circle />
                </template>
              </el-popconfirm>
            </el-col>
          </el-row>
        </el-checkbox-group>
        <el-row :gutter="20" class="mt-10">
          <el-col :span="12"><el-button icon="el-icon-plus" size="small" @click="onAddItem">添加选项</el-button></el-col>
          <el-col :span="12">
            <el-tooltip
              class="box-item"
              effect="dark"
              content="由填表人填写"
            >
              <el-button icon="el-icon-plus" size="small" @click="onAddItemOther">添加其他项</el-button>
            </el-tooltip>
          </el-col>
        </el-row>
      </div>
      
    </el-form-item>

    <el-divider>其他</el-divider>

    <el-form-item label="至少要选">
      <el-input-number size="small" v-model="currentPlugin.min" :min="0" :max="currentPlugin.groups.length"></el-input-number> 项，0 为不限
    </el-form-item>

    <el-form-item label="最多可选">
      <el-input-number size="small" v-model="currentPlugin.max" :min="0" :max="currentPlugin.groups.length"></el-input-number> 项，0 为不限
    </el-form-item>

    <el-form-item label="排列">
      <el-radio-group v-model="currentPlugin.direction">
        <el-radio-button label="horizontal">横排</el-radio-button>
        <el-radio-button label="vertical">竖排</el-radio-button>
        <!-- <el-radio-button label="cell">整行</el-radio-button> -->
      </el-radio-group>
    </el-form-item>

    <el-form-item label="形状">
      <el-radio-group v-model="currentPlugin.shape" size="small">
        <el-radio-button label="square">方形</el-radio-button>
        <el-radio-button label="round">圆形</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="选中色">
      <el-color-picker v-model="currentPlugin.checkedColor" color-format="hex" size="small"></el-color-picker>
    </el-form-item>

    <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.required" label="必填"  />
        <el-checkbox v-model="currentPlugin.disabled" label="禁用" />
    </el-form-item>


    <template v-if="$store.state.formSettings.multipleForm">
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>

  </el-form>



</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'checkboxGroup',
  props: {},
  components: {
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  mounted:function(){
    // 兼容旧版
    if(!this.currentPlugin.checkedColor){
      this.currentPlugin.checkedColor = '#1989fa'
    }
  },
  methods:{
    onAddItem(){
      let groups = this.currentPlugin.groups
      let length = this.currentPlugin.groups.length
      // console.log('length',length)
      groups.push({
          label:'选项' + (length +1),
          disabled:false,
          // checked:length ==0
      })
      groups = _.uniqBy(groups, 'label'); // 去重
      this.currentPlugin.groups = groups
    },
    onAddItemOther(){
      let groups = this.currentPlugin.groups
      groups.push({
          label:'其他',
          disabled:false,
          // checked:false
      })
      groups = _.uniqBy(groups, 'label'); // 去重
      this.currentPlugin.groups = groups
    },
    // 选中值必须在可选项目中
    checkRemoveValue(){
      let _items = this.currentPlugin.groups.map((item)=>item.label)
      let _values = this.currentPlugin.values
      // 找出已经删除的
      let delItems = []
      _values.forEach(item => {
        // console.log('checkValue',item)
        if(!_.includes(_items, item)){
          delItems.push(item)
        }
      });
      // 移除不存在的项目
      if(delItems.length>0){
        this.currentPlugin.values = _.difference(_values, delItems);
      }
    },
    onRemoveItem(index){
      const _label = this.currentPlugin.groups[index].label
      this.currentPlugin.groups.splice(index,1)

      let length = this.currentPlugin.groups.length
      if(length==0){
        this.onAddItem()
      }
      // 删除检测
      this.checkRemoveValue()
      // console.log('this.currentPlugin.values',this.currentPlugin.values)
    },
    // 检查空输入和重复
    onBlurCheck(value,index){
      // 删除空项目
      if(value==""){
        this.currentPlugin.groups.splice(index,1)
        // 删除检测
        this.checkRemoveValue()
      }else{
        // 去重
        let items = this.currentPlugin.groups
        items = _.uniqBy(items, 'label'); 
        this.currentPlugin.groups = items
      }
    }



  }
}

</script>


<style scoped>


</style>
