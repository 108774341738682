<template>
<div>
  <el-form
    label-position="right"
    label-width="90px"
    :rules="rules"
    :model="formSettings"
  >
    <el-form-item label="标题" prop="title" required>
      <el-input v-model="formSettings.title" placeholder="请输入标题" clearable />
    </el-form-item>
    <el-form-item label="分享描述">
      <el-input v-model="formSettings.desc" placeholder="请输入分享描述" clearable />
    </el-form-item>

    <el-form-item label="图片">
      <div class="cover-uploader ">
        <div class="el-upload el-upload--text" @click="visible=true">
          <el-image :src="formSettings.cover" class="cover" fit="contain"></el-image>
        </div>
      </div>
    </el-form-item>


    <el-form-item label="使用方式">
      <el-radio-group v-model="formSettings.opening" size="small">
        <el-radio-button label="weapp"><i class="el-icon-s-help"></i> 小程序</el-radio-button>
        <el-radio-button label="h5"><i class="el-icon-link"></i> H5网页</el-radio-button>
      </el-radio-group>
      <div class="help">推荐使用小程序方式，<el-link type="warning" href="https://www.2weima.com/home/page/opening.html" target="_blank">区别介绍</el-link></div>
    </el-form-item>

    <el-divider>访问权限</el-divider>

    <el-form-item label="访问密码" >
      <el-input v-model="formSettings.pwd" placeholder="设置密码限制" clearable />
    </el-form-item>

    <el-form-item label="可扫码次数">
      <el-input-number size="small" v-model="formSettings.max_views" :min="0" ></el-input-number> 次，0 为无限制
    </el-form-item>

    <el-form-item label="开放时间">
      <el-date-picker
        v-model="formSettings.start_time"
        type="datetime"
        placeholder="点击选择时间"
        :picker-options="pickerOptions">
      </el-date-picker>
    </el-form-item>

    <el-form-item label="结束时间">
      <el-date-picker
        v-model="formSettings.end_time"
        type="datetime"
        placeholder="点击选择时间"
        :picker-options="pickerOptions">
      </el-date-picker>
    </el-form-item>
    
    <el-divider>其他</el-divider>

    <el-form-item label="多个表单">
        <el-checkbox v-model="formSettings.multipleForm" label="允许通过提交按钮设置表单名称区分"  />
        <div class="help">不建议使用多表单，可通过关联二维码跳转实现</div>
    </el-form-item>


   
  </el-form>

  <!-- 图片选择 -->
  <image-upload-dialog :visible.sync="visible" source="swipe" @confirm="onConfirm"/>
</div>
</template>
<script>
import { mapState } from 'vuex'
import ImageUploadDialog from '../ImageUploadDialog'
export default {
  name: 'AsideRight',
  props: {
    // msg: String
  },
  components: {
    ImageUploadDialog
  },
  computed:{
    ...mapState(["formSettings"]),
  },
  data:function() {
    return {
      visible:false,
      rules:{
        title: [
          { required: true, message: '页面标题不能为空' },
        ],
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '明天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '一月后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', date);
          }
        }]
      }
      

    }
  },
  methods:{
    onConfirm(imageData){
      // console.log('imageData',imageData)
      this.formSettings.cover = imageData.url
    }
  }
  
}

</script>


<style scoped>


</style>
