<template>
<div :style="'padding:'+schema.paddingTB+'px '+schema.paddingLR+'px;background:'+schema.bgColor +';color:'+schema.color +'; fontSize:'+ schema.fontSize +'px; text-align:'+schema.textAlign">
  {{schema.text}}
  <div v-if="schema.desc" :style="'color:'+ schema.descColor + ';'" class="title-desc">{{schema.desc}}</div>
</div>
</template>

<script>
export default {
  name: 'diyTitle',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>
.title-desc{
    font-size: 12px;
    font-weight: 400;
    margin-top:5px;
}
</style>