<template>
  <el-form
    label-position="right"
    label-width="70px"
  >
    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>

    <el-form-item label="默认状态">
      <el-switch
        v-model="currentPlugin.checked"
        :active-color="currentPlugin.activeColor"
        :inactive-color="currentPlugin.inactiveColor"
      />
    </el-form-item>

    <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.required" label="必填"  />
        <!-- <el-checkbox v-model="currentPlugin.disableInput" label="不能输入" /> -->
        <!-- <el-checkbox v-model="currentPlugin.disabled" label="禁用" /> -->
    </el-form-item>


    <el-divider>其他</el-divider>

    <el-form-item label="激活色">
      <el-color-picker v-model="currentPlugin.activeColor" color-format="hex" size="small"></el-color-picker>
    </el-form-item>
    <el-form-item label="关闭色">
      <el-color-picker v-model="currentPlugin.inactiveColor" color-format="hex" size="small"></el-color-picker>
    </el-form-item>

    <template v-if="$store.state.formSettings.multipleForm">
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>

  </el-form>



</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'switchOff',
  props: {},
  components: {
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
}

</script>


<style scoped>


</style>
