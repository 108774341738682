<template>
  <el-form
    label-position="right"
    label-width="70px"
  >
    <el-form-item label="名称">
      <el-input v-model="currentPlugin.label" placeholder="请输入组件名称"  clearable/>
    </el-form-item>
    <el-form-item label="提示信息">
      <el-input v-model="currentPlugin.placeholder" placeholder="输入提示信息" clearable/>
    </el-form-item>

    <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.required" label="必填"  />
    </el-form-item>

    

    <template v-if="$store.state.formSettings.multipleForm">
      <el-divider>其他</el-divider>
      <el-form-item label="所属表单">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>

    <el-divider>提示</el-divider>
    <div class="help mt-10">作用：标注组件可为任何事物在地图拖动选择一个位置</div>

  </el-form>

</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'mapMarked',
  props: {},
  components: {
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
}

</script>


<style scoped>


</style>
