<template>
  <van-notice-bar
    left-icon="volume-o"
    :text="schema.text"
    :background="schema.bgColor"
    :color="schema.color"
    :scrollable="schema.scrollable"
    :wrapable="schema.wrapable"
    :speed="schema.speed"
  />
</template>

<script>

export default {
  name: 'noticeBar',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>

</style>