<template>
  <el-dialog title="选择图片" :visible="visible" width="95%" class="image-dialog" @close="onCancel" >

    <el-tabs v-model="tabsValue" @tab-click="onImageSelectTabsChange" type="border-card">
      <el-tab-pane label="我的图片" name="myImage">
        <span slot="label"><i class="el-icon-picture-outline"></i> 我的图片</span>

        <el-form :inline="true" :model="searchForm">
          <el-form-item label="关键词">
            <el-input v-model="searchForm.kwd" placeholder="搜索文件名"></el-input>
          </el-form-item>
          <el-form-item label="选择分类">
            <el-cascader v-model="searchForm.catIds" :options="catTreeData" :props="cascaderProps"
              clearable></el-cascader>

          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="imageSelectLoad('search')">查询</el-button>
          </el-form-item>
        </el-form>


        <el-row :gutter="10" style="max-height:600px;overflow-y:scroll">
          <el-col v-for="(item, index) in listData" :key="item.id" :xs="12" :sm="12" :md="8" :lg="4" :xl="4" style="margin-bottom:10px;">
            <el-card :body-style="{ padding: '0px' }" shadow="hover" class="image-card">
              <img :src="item.thumb" class="image" @click="onImageSelect(index)">
              <div class="image-name">
                {{ item.name }}
              </div>

              <i v-if="index == selectIndex" class="el-icon-circle-check check"></i>
              <i class="el-icon-circle-close delete" @click.stop="onImageSelectDelete(index)"></i>
            </el-card>
          </el-col>
          <el-col :xs="24">
            <div class="text-center mt-10" v-if="isNext">
              <i v-if="loading" class="el-icon-loading" style="font-size:18px"></i>
              <el-button v-else @click="imageSelectLoad('more')" type="primary" icon="el-icon-refresh-right"
                style="width:200px" plain>加载更多...</el-button>
            </div>
          </el-col>
        </el-row>


      </el-tab-pane>
      <el-tab-pane label="上传" name="upload">
        <span slot="label"><i class="el-icon-upload"></i> 上传</span>

        <el-form :inline="true">
          <template v-if="!catAdd.isActive">
            <el-form-item label="上传到分类">
              <el-cascader v-model="upload.uploadForm.catIds" :options="catTreeData" :props="cascaderProps"
                @change="onUploadCatChange" placeholder="你要上传到那个分类？" clearable></el-cascader>

            </el-form-item>
            <el-form-item label="">
              <el-button @click="catAdd.isActive = true;">添加新分类</el-button>
            </el-form-item>
          </template>
          <template v-else>

            <el-form-item label="上级分类">
              <el-cascader v-model="upload.uploadForm.catIds" :options="catTreeData" :props="cascaderProps"
                @change="onUploadCatChange" placeholder="选择上级分类" clearable></el-cascader>

            </el-form-item>
            <el-form-item label="新分类名称">
              <el-input v-model="catAdd.catName" placeholder="请输入分类名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onImageCatAdd">确定添加</el-button>
              <el-button type="default" @click="catAdd.isActive = false">取消</el-button>
            </el-form-item>

          </template>
        </el-form>

        <el-upload ref="uploadRef" class="image-uploader" name="file" list-type="picture"
          accept=".jpg,.png,.jpeg,.gif,.webp" :action="upload.action" :headers="upload.headers" :data="upload.data"
          :with-credentials="true" :before-upload="onImageUploadBefore" :before-remove="onImageRemoveBefore"
          :on-change="onImageUploadChange" drag multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip">支持 2M 内 JPG / PNG / GIF / WEBP 图片</div>
          <div class="el-upload__tip" slot="tip">
            <p>超出2M自动压缩，您也可先 <el-link type="primary" href="https://tools.2weima.com/yasuo" target="_blank">压缩图片</el-link></p>
            <p>请上传原创或CC0图片，有水印图或版权图片将被删除并自行承担责任</p>
          </div>
        </el-upload>

      </el-tab-pane>
    </el-tabs>





    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </span>

  </el-dialog>
</template>
<script>
import utils from "../utils/utils"
import { compress, compressAccurately } from 'image-conversion';

//分类处理
let _catTreeData = [], _parentCatData = config.parentCatData;
//转格式适配
_catTreeData = utils.toTree(_parentCatData)
if (_catTreeData.length <= 0) {
  _catTreeData.push({
    id: 0,
    pid: 0,
    cat_name: "默认分类"
  });
}
// console.log('_catTreeData',_catTreeData)


export default {
  name: 'ImageUploadDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {
      tabsValue: "myImage",
      selectIndex: null,//选中,目前仅单选
      searchForm: {
        kwd: '',
        catIds: []
      },
      catTreeData: _catTreeData,
      cascaderProps: {
        value: "id",
        label: "cat_name",
        children: "_child",
        checkStrictly: true
      },
      pageIndex: 0,
      loading: true,
      isNext: true,
      listData: [
        // {
        //   id:12,
        //   url:'http://zbcms.2weima.com/static/images/h5editor/image-placeholder.jpg'
        // }
      ],
      upload: {
        action: config.uploadUrl,
        headers: {},
        data: {
          cat_id: 0,
          file_type: "img",
          source: this.source //'diy'
        },
        fileList: [],
        uploadForm: {
          catIds: []
        }
      },
      catAdd: {
        isActive: false,
        catName: ''
      }
    }
  },
  watch: {
    visible: function (val) {
      // onLoad 打开且listData为空
      if (val && this.listData.length == 0) {
        //  console.log('onLoad',val)
        this.imageSelectLoad('init')

      }
    }
  },
  methods: {
    onCancel() {
      this.$emit('update:visible', false)
    },
    onConfirm() {
      if (this.selectIndex == null || _.isEmpty(this.listData[this.selectIndex])) {
        //什么都不做
        this.$emit('update:visible', false)
        return;
      }

      this.$emit('update:visible', false)
      this.$emit('confirm', _.clone(this.listData[this.selectIndex]))
    },
    //选择图片
    onImageSelect(index) {
      // console.log('onImageSelect',index);
      this.selectIndex = index;
    },
    imageSelectRemove(attId, callback) {
      var that = this;

      if (!attId) {
        return;
      }

      // console.log('imageRemove',attId)
      utils.request(config.imgDeletetUrl, {
        ids: [attId],
      }, function (res) {
        // console.log(res);
        if (typeof callback == "function") {
          callback(res);
        }

      });



    },
    onImageSelectDelete(index) {
      var that = this;
      if (this.selectIndex == index) {
        this.selectIndex = null;
      }

      // console.log('onImageSelectDelete')

      this.$confirm('您确定要删除这张图片吗?', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        that.imageSelectRemove(that.listData[index].id, function (res) {
          if (res.code != 1) {
            that.$message({
              type: 'info',
              message: res.msg || "操作错误，请重试"
            });
            return;
          }
          // success
          that.listData.splice(index, 1);

        });


      }).catch(() => {

      });

    },
    imageSelectLoad(loadType) {
      var that = this;

      if (loadType == 'search') {
        this.pageIndex = 0;//重新计算
        this.listData = [];
        this.selectIndex = null;
      }

      this.loading = true;

      utils.request(config.attListUrl, {
        op: "load_list",
        source: this.source,//"diy_" + 
        cat_id: _.last(this.searchForm.catIds) || 0,
        kwd: this.searchForm.kwd,
        page_index: this.pageIndex + 1
      }, function (res) {

        // console.log('request call',res);

        if (res.code != 1) {
          that.onCancel();
        } else {
          that.loading = false;//加载状态
          that.pageIndex += 1;
          that.isNext = res.is_next == 1;
          for (var i in res.data) {
            that.listData.push(res.data[i]);
          }
        }

      });
    },
    onImageUploadBefore(file) {
      // console.log('onImageUploadBefore',file);

      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
      // if (!isJpgOrPng) {
      //   this.$message({
      //     type: 'error',
      //     message: "请上传 JPG/PNG/GIF/WEBP 格式图片"
      //   });
      //   return false;
      // }
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isLt2M) {
      //   this.$message({
      //     type: 'error',
      //     message:"只能上传 2MB 内的图片，请压缩后再上传"
      //   });
      // }
      // return isJpgOrPng && isLt2M;



      // 不限大小，但压缩到2M内上传
      return new Promise(async (resolve, reject) => {


        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
        if (!isJpgOrPng) {
          this.$message({
            type: 'error',
            message: "请上传 JPG/PNG/GIF/WEBP 格式图片"
          });

          reject("请上传 JPG/PNG/GIF/WEBP 格式图片");
          return false;
        }

        // console.log("isJpgOrPng", isJpgOrPng);

        try {
          let res = await compressAccurately(file, {
            size: 800,    //The compressed image size is 100kb
            accuracy: 0.9,//the accuracy of image compression size,range 0.8-0.99,default 0.95;
            //this means if the picture size is set to 1000Kb and the
            //accuracy is 0.9, the image with the compression result
            //of 900Kb-1100Kb is considered acceptable;
            type:  "image/jpeg", // file.type === 'image/png' ? 'image/png' :
            // width: 750,
            // height: 1500,
          });

          // console.log('compressAccurately then', res);
          resolve(res);
        } catch (error) {
          reject("compressAccurately error", error);
        }



      })

    },
    onImageRemoveBefore(file, fileList) {
      let that = this;

      // console.log(file.response)
      // 没上传的直接删除
      if (_.isEmpty(file.response) || file.response.code != 1) {
        return true;
      }

      // console.log('file.response')

      // 已上传的通知API
      return new Promise((resolve, reject) => {
        // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);

        that.imageSelectRemove(file.response.data.id, function (res) {

          if (res.code == 1) {
            resolve(true)// to then
          } else {
            reject(res.msg) // to catch
          }

        });

      })

    },

    onImageUploadChange(file, fileList) {
      // 上传成功时
      this.upload.fileList = fileList
      // console.log('fileList',fileList) 




      // 当全部上传完，自动切换到我的图片
      let isAllSuccess = true;
      fileList.forEach(file => {
        if (file?.response?.code != 1) {
          // file.name
          if(file?.response?.msg){
            this.$message({
              type: 'error',
              duration:0,
              message: file?.response?.msg + "，上传失败文件:" +  file?.name 
            });
          }

          isAllSuccess = false;
        }
      });
      if (isAllSuccess) {
        this.tabsValue = "myImage"
        this.onImageSelectTabsChange({ name: "myImage" })
      }
    },
    onImageSelectTabsChange({ name }) {
      var that = this;
      if (name == 'myImage') {//切换我的图片时 把新上传的放到列表
        // console.log(this.imageSelect.upload.fileList)
        let _upload_fileList = this.upload.fileList;
        if (!_.isEmpty(_upload_fileList)) {
          _upload_fileList.forEach(file => {
            if (file.status == "success" && !_.isEmpty(file.response) && file.response.code == 1) {
              that.listData.unshift(file.response.data);
            }
          });

          this.upload.fileList = [];
        }

        // uploadRef 清空已上传的文件列表
        this.$refs.uploadRef.clearFiles()

      }

    },
    // 选择分类处理
    onUploadCatChange(catIds) {
      this.upload.data.cat_id = _.last(catIds) || 0
      console.log(this.upload.uploadForm.catIds)
    },
    // 添加分类
    onImageCatAdd() {
      let that = this;
      // console.log(imageSelect.upload.data.cat_id,this.imageSelect.catAdd.catName)

      let _formData = {
        pid: this.upload.data.cat_id,
        cat_name: this.catAdd.catName,
      };
      utils.request(config.catAddUrl, _formData, function (res) {
        console.log(res);

        if (res.code == 1) {
          let _new_id = parseInt(res.new_id)
          let _temp = []
          for (let i in res.data) {
            _temp.push(res.data[i])
          }
          that.catTreeData = _temp;
          // console.log('that.catTreeData',that.upload.uploadForm.catIds)

          that.upload.uploadForm.catIds.push(_new_id);
          that.upload.data.cat_id = _new_id;
          that.catAdd.isActive = false;
        }


      });
    }

  }
}
</script>

<style>
/* .time {
    font-size: 13px;
    color: #999;
  }
   */
/* .bottom {
    margin-top: 13px;
    line-height: 12px;
  } */
/* 
  .button {
    padding: 0;
    float: right;
  } */

.image-card {
  height: 240px;
  padding: 10px;
  position: relative;
}

.image-name {
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.image {
  width: auto;
  max-width: 100%;
  min-width: 64px;
  height: 200px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.image-dialog .check {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 46px;
  color: #67C23A;
}


.image-dialog .delete {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  color: #999;
  display: none;
  cursor: pointer;
}

.image-dialog .image-card:hover .delete {
  display: block;
}

.image-dialog .el-tabs--border-card {
  box-shadow: none;
}

.image-dialog .el-dialog__body {
  padding: 0 16px;
}


.image-uploader,
.image-uploader .el-upload,
.image-uploader .el-upload-dragger {
  width: 100%;
}
</style>
