<template>
<div>
  <el-form
    label-position="right"
    label-width="90px"
  >
    <el-form-item label="通知内容">
      <el-input v-model="currentPlugin.text" placeholder="请输入通知内容"  clearable/>
    </el-form-item>

    <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.wrapable" label="多行显示"  />
        <el-checkbox v-model="currentPlugin.scrollable" label="滚动播放" />
    </el-form-item>
  
    <el-form-item label="滚动速率" v-if="currentPlugin.scrollable">
      <el-input-number size="small" v-model="currentPlugin.speed" :min="20" :max="150"></el-input-number>
    </el-form-item>

    <el-divider>其他</el-divider>


    <el-form-item label="字体色">
      <el-color-picker v-model="currentPlugin.color" color-format="hex" size="small"></el-color-picker>
    </el-form-item>
    <el-form-item label="背景色" v-if="!currentPlugin.transparent">
      <el-color-picker v-model="currentPlugin.bgColor" color-format="hex" size="small"></el-color-picker>
    </el-form-item>
  
    <el-form-item label="点击图标">
      <el-button size="mini" style="margin-left:10px;" @click="onHandleSetting" icon="el-icon-s-tools">设置动作</el-button>
    </el-form-item>

  

    <el-divider>提示</el-divider>
    <div class="help mt-10">滚动播放时字体色修改不会马上显示，可先取消滚动</div>

  </el-form>
  <!-- 链接选择 -->
  <!-- <action-link-dialog :visible.sync="visibleLink" source="diy" :showAll="true" @confirm="onConfirmLink"/> -->
  
   <!-- 动作设置 -->
   <handle-setting-dialog v-if="visibleHandle"  :visible.sync="visibleHandle" :schema="currentPlugin" source="diy" :showAll="true" @confirm="onConfirmHandle" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

// import ActionLinkDialog from '../../ActionLinkDialog'

import HandleSettingDialog from '../../HandleSettingDialog'

export default {
  name: 'noticeBar',
  props: {},
  components: {
    HandleSettingDialog,
  },
  computed:{
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
      visibleHandle:false,
    }
  },
  methods:{
    // onActionLink(){
    //   // console.log('onUploadImage')
    //   this.visibleLink = true;
    // },
    // onConfirmLink(linkData){
    //   // console.log('linkData',linkData)
    //   this.currentPlugin.link = linkData.qrview;
    //   this.currentPlugin.path = linkData.weapp_path;
    //   this.currentPlugin.appId = linkData.weapp_id;
    // },
    onHandleSetting() {
      // console.log('onUploadImage')
      this.visibleHandle = true;
    },
    onConfirmHandle(payload) {
      // console.log('onConfirmHandle payload,', payload)
      // this.currentPlugin = { ...payload }

      this.currentPlugin.handle = payload.handle;
      this.currentPlugin.link = payload.link;
      this.currentPlugin.path = payload.path;
      this.currentPlugin.appId = payload.appId;
      this.currentPlugin.jumpType = payload.jumpType;
      this.currentPlugin.shortLink = payload.shortLink;

      if (!_.isEmpty(payload.message)) {
        this.currentPlugin.message = payload.message;
      }
      if (!_.isEmpty(payload.handle_call)) {
        this.currentPlugin.handle_call = payload.handle_call;
      }
      if (!_.isEmpty(payload.handle_map)) {
        this.currentPlugin.handle_map = payload.handle_map;
      }
      if (!_.isEmpty(payload.handle_copy)) {
        this.currentPlugin.handle_copy = payload.handle_copy;
      }
      if (!_.isEmpty(payload.handle_wifi)) {
        this.currentPlugin.handle_wifi = payload.handle_wifi;
      }
    },
   
  }
}

</script>


<style scoped>


</style>
