<template>
<div>
  <div class="car" :style="'background:'+schema.car.bgColor +';box-shadow: 0 0 2px 4px '+schema.car.bgColor +'; color:'+ schema.car.color +';'">
    <span class="car-province">{{ schema.car.province }}</span>
    <span>{{ schema.car.az }}</span>
    <span class="car-dot">•</span>
    <span>{{ schema.car.number }}</span>
  </div>
  <div class="car-tips" :style="'color:'+ schema.tips.color +'; font-size:'+ schema.tips.fontSize +'px;'">
    {{ schema.tips.text }}
  </div>
  <van-cell-group v-if="schema.isWechat && schema.wechat.length>0">
    <van-cell title="车主微信" label="扫码联系我" icon="wechat" is-link/>
  </van-cell-group>

  <van-cell-group v-if="schema.isTel">
    <van-cell 
    v-for="(item, idx) in schema.cells" :key="idx" 
    :title="item.text" :label="item.label" icon="phone" is-link/>
  </van-cell-group>
</div>
</template>

<script>
export default {
  name: 'nuoChe',
  props: {
    schema: {
      'type':Object,
      default:{},
      required:true
    },
    pluginIndex:{
      type:Number,
      default:0
    }
  },
  methods:{

  }
}
</script>

<style>
.car{
  color: white;
  background-color: #4b5cc4; 
  border-radius: 6px;
  border:white 1px solid;
  text-align: center;
  padding: 10px 0px;
  box-shadow: 0 0 2px 4px #4b5cc4;
  width:90%;
  margin:15px auto;
  font-size: 40px;
  letter-spacing:5px;
  text-transform:uppercase;
}
.car-dot{
  font-size: 28px;
}

.car-tips{
  margin-bottom: 15px;
  text-align: center;
  font-size: 30px;
}
</style>