export default {
    name: 'formData',
    title: '表单信息展示',
    label:'检查记录',
    formName:'default',
    uniqid:'',
    isShowMode:'list',// lisr or pwd
    showModePwd:'',//查看密码
    isShowTime:true,
    fields:[
        // {
        //     name: 'area',
        //     title: '地区选择',
        //     uniqid:'',
        //     label:'地址'
        // }
    ],
}