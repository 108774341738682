<template>
  <div>
  <el-form
    label-position="right"
    label-width="90px"
  >
    <el-form-item label="按钮文字">
      <el-input v-model="currentPlugin.label" placeholder="请输入按钮文字"  clearable/>
    </el-form-item>

    <el-form-item label="提交密码">
      <el-input v-model="currentPlugin.submitPwd" placeholder="请输入提交密码"  clearable/>
    </el-form-item>

    <el-form-item label="属性">
        <el-checkbox v-model="currentPlugin.notRepeat" label="防重复提交"/>
        <el-checkbox v-model="currentPlugin.plain" label="边框按钮"/>
    </el-form-item>


    <template v-if="$store.state.formSettings.multipleForm">
      <el-form-item label="表单名称">
        <el-input v-model="currentPlugin.formName" placeholder="所属表单名称.."  />
      </el-form-item>
    </template>


    
    <el-divider>动作</el-divider>

    
    <el-form-item label="提交成功后">
      <el-button size="mini" style="margin-left:10px;" @click="onHandleSetting" icon="el-icon-s-tools">设置动作</el-button>
    </el-form-item>


    <el-divider>其他</el-divider>


    <el-form-item label="按钮图标">
      <van-icon :name="currentPlugin.icon" size="20"/>
      <el-button size="mini" style="margin-left:10px;" @click="visible=true">选择</el-button>
    </el-form-item>

    <el-form-item label="形状">
      <el-radio-group v-model="currentPlugin.square" size="small">
        <el-radio-button label="square">方形</el-radio-button>
        <el-radio-button label="round">圆角</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="大小">
      <el-radio-group v-model="currentPlugin.size" size="small">
        <el-radio-button label="large">大号按钮</el-radio-button>
        <el-radio-button label="normal">常规</el-radio-button>
        <el-radio-button label="small">小型按钮</el-radio-button>
        <el-radio-button label="mini">迷你按钮</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="对齐方式" v-if="currentPlugin.size !='large'">
      <el-radio-group v-model="currentPlugin.textAlign" size="small">
        <el-radio-button label="left">居左</el-radio-button>
        <el-radio-button label="center">居中</el-radio-button>
        <el-radio-button label="right">居右</el-radio-button>
      </el-radio-group>
    </el-form-item>




    <el-form-item label="按钮色">
      <el-color-picker v-model="currentPlugin.color" color-format="hex" size="small"></el-color-picker>
    </el-form-item>
    <el-form-item label="背景色" v-if="!currentPlugin.transparent">
      <el-color-picker v-model="currentPlugin.bgColor" color-format="hex" size="small"></el-color-picker>
    </el-form-item>

    <el-form-item label="上下边距">
      <el-input-number size="small" v-model="currentPlugin.paddingTB" :min="0" :max="300"></el-input-number> PX
    </el-form-item>

    <el-form-item label="左右边距">
      <el-input-number size="small" v-model="currentPlugin.paddingLR" :min="0" :max="300"></el-input-number> PX
    </el-form-item>

    <el-divider>说明</el-divider>
    <div class="help">
      防重复提交：目前简单地按天判断处理，请勿作用于重要权限管理<br/>
      提交密码：设置后每次提交表单都需要输入密码<br/>
      表单名称：当一个页面有多个提交按钮时，可通过设置名称区分提交的数据进行信息展示
    </div>


  </el-form>
  
  <icons-dialog v-model="currentPlugin.icon" :visible.sync="visible"/>
  <!-- 链接选择 -->
  <!-- <action-link-dialog :visible.sync="visibleLink" source="diy" :showAll="true" @confirm="onConfirmLink"/> -->
  
   <!-- 动作设置 -->
   <handle-setting-dialog v-if="visibleHandle"  :visible.sync="visibleHandle" :schema="currentPlugin" source="diy" :showAll="true" @confirm="onConfirmHandle" />
 </div>

</template>
<script>
import { mapGetters,mapState } from 'vuex'
import IconsDialog from '../../IconsDialog'
// import ActionLinkDialog from '../../ActionLinkDialog'
import HandleSettingDialog from '../../HandleSettingDialog'

export default {
  name: 'kefu',
  props: {},
  components: {
    HandleSettingDialog,
    IconsDialog
  },
  computed:{
    ...mapState(['currentPluginIndex']),
    ...mapGetters(['currentPlugin'])
  },
  data:function(){
    return {
      visibleHandle:false,
      visible:false
    }
  },
  methods:{
    onHandleSetting() {
      // console.log('onUploadImage')
      this.visibleHandle = true;
    },
    onConfirmHandle(payload) {
      // console.log('onConfirmHandle payload,', payload)
      // this.currentPlugin = { ...payload }

      this.currentPlugin.handle = payload.handle;
      this.currentPlugin.link = payload.link;
      this.currentPlugin.path = payload.path;
      this.currentPlugin.appId = payload.appId;
      this.currentPlugin.jumpType = payload.jumpType;
      this.currentPlugin.shortLink = payload.shortLink;

      if (!_.isEmpty(payload.message)) {
        this.currentPlugin.message = payload.message;
      }
      if (!_.isEmpty(payload.handle_call)) {
        this.currentPlugin.handle_call = payload.handle_call;
      }
      if (!_.isEmpty(payload.handle_map)) {
        this.currentPlugin.handle_map = payload.handle_map;
      }
      if (!_.isEmpty(payload.handle_copy)) {
        this.currentPlugin.handle_copy = payload.handle_copy;
      }
      if (!_.isEmpty(payload.handle_wifi)) {
        this.currentPlugin.handle_wifi = payload.handle_wifi;
      }
    },
    // onActionLink(){
    //   // console.log('onUploadImage')
    //   this.visibleLink = true;
    // },
    // onConfirmLink(linkData){
    //   // console.log('linkData',linkData)
    //   this.currentPlugin.link = linkData.qrview;
    //   this.currentPlugin.path = linkData.weapp_path;
    //   this.currentPlugin.appId = linkData.weapp_id;
    // },
   
  }
}

</script>


<style scoped>


</style>
